import { connect } from "react-redux";
import Configurations from '../../components/Configurations';
import { createConfigurations, fetchConfigrations } from '../../redux/actions/configurationActions';
 
const mapStateToProps = state => {
  //console.log('state in container', state);
  return {
    createConfig: state.createConfig , 
    fetchConfig : state.fetchConfig
  };
};
 
const mapDispatchToProps = (dispatch) => {
  return {
    _fetchConfig: () => {
      return dispatch(fetchConfigrations());
    },
    _createConfig: (type, data) => {
      return dispatch(createConfigurations(type, data));
    },
  };
};
 
const ConfiguctaionsContainer = connect(mapStateToProps, mapDispatchToProps)(
    Configurations
);
 
export default ConfiguctaionsContainer;