import { wrappedFetch, wrappedGet } from './urlFuncs';
import { ADD_ADMIN_USER, SEARCH_ADMIN_URL, DELETE_ADMIN_USER, UPDATE_ADMIN_USER } from './../inc/constants';
import {API_URL} from './../inc/config';


export const addNewAdmin = (data) => {
  return wrappedFetch(`${API_URL}${ADD_ADMIN_USER}`,'POST', data);
}

export const deleteAdmin = (id) => {
  return wrappedFetch(`${API_URL}${DELETE_ADMIN_USER}${id}`,'DELETE');
}

export const updateAdmin = (id, data) => {
  return wrappedFetch(`${API_URL}${UPDATE_ADMIN_USER}${id}`,'PUT', data);
}

export const searchForUser = (searchInfo) =>{
  let {searchKey,page,limit} = searchInfo
  //console.log(`${API_URL}${SEARCH_ADMIN_URL}?searchkey=${searchKey}&page=${page}&limit=${limit}`,'GET')
  return wrappedGet(`${API_URL}${SEARCH_ADMIN_URL}?searchkey=${searchKey}&page=${page}&limit=${limit}`,'GET');
}