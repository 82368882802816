import { connect } from "react-redux";
import ListNotifications from '../../components/Notifications/ListNotifications';
import { fetchNotifications, deleteNotifications, updateNotification } from '../../redux/actions/notificationActions';
 
const mapStateToProps = state => {
  //console.log('state in container', state);
  return {
    premiumContentList : state.listNotifications,
    deleteContent : state.deleteContent,
    updateContent : state.updateContent ,
    deleteNotification : state.deleteNotification ,
    updateNotification : state.updateNotification
  };
};
 
const mapDispatchToProps = (dispatch) => {
  return {
    _fetchNotifications: (page, skip) => {
      return dispatch(fetchNotifications(page, skip));
    },
    _deleteNotification : (id) => {
      return dispatch(deleteNotifications(id))
    },
    _updateNotification : (id, data) => {
      return dispatch(updateNotification(id, data));
    },
    // _postPremiumContent : (data) => {
    //   return dispatch(postPremiumContent(data));
    // },
  };
};
 
const ListNotificationsContainer = connect(mapStateToProps, mapDispatchToProps)(
  ListNotifications
);
 
export default ListNotificationsContainer;