import React from 'react';
import { Container, Row, Col, Card, CardBody, Button, Form, FormGroup, Label, Input,CustomInput } from 'reactstrap';
import TableComponent from './../Shared/Table/Table';
import AddCategoryModal from './AddCategoryModal';
import PenIcon from 'mdi-react/PencilOutlineIcon';
import DeleteIcon from 'mdi-react/DeleteIcon';
import notification from '../../helpers/notificationHelper';
// import TablePagination from './../Shared/Table/TablePagination';

class AllCategories extends React.Component{

    state={
        headsAg : [],
        modal : false,
        title :'',
        slug : '',
        categories : '',
        total : 0,
        limit : 10,
        page :1,
        modalType : '',
        isActive : false,
        catId : '',
        enTitle : '', 
        frTitle : '', 
        esTitle : ''
    }

    componentDidMount(){
        //console.log('here in all users',this.props)
        this.props._fetchCategories();
        let {categories} = this.props.categories
        // let total = categories.length
        //console.log("=*******************",this.props.categories)
        let headsAg = [
            {headerName: "Sr. No", field: "i", width:"80", sortable:true, filter:'text', 
                cellRendererFramework : this.renderIndex 
            },
            {headerName: "English Title", field: "english_title", suppressSizeToFit: true, sortable:true, filter:'text' },
            {headerName: "French Title", field: "french_title", suppressSizeToFit: true, sortable:true, filter:'text',  },
            {headerName: "Spanish Title", field: "spanish_title", suppressSizeToFit: true, sortable:true, filter:'text' },
            {headerName: "Is Active", 
            field: "isActive", 
            suppressSizeToFit: true, 
            sortable:true, 
            filter:'text' ,
            cellRendererFramework : this.renderIsActiveColumn,
            cellStyle : (params) =>{
              return {textAlign :  'center'}
            }
          },
            {headerName: "Actions", 
            cellRendererFramework : this.renderAction,
            cellStyle : (params) =>{
              return {textAlign :  'center'}
            }
          },
          ]
        this.setState({  headsAg,categories })
    }

    renderAction = (record) => {
      let buttons = <span>
                  <PenIcon className="cursor-pointer ml-2" size={18} onClick={()=>{ this.setData(record.data, 'edit') } }/>
                  {/* <DeleteIcon className="cursor-pointer ml-2" size={18} onClick={()=>{ this.setData(record.data, 'deleteModal') } }/> */}
              </span>;
      return buttons;
    }

    setData = (data, type) => {
        let { _id, english_title, spanish_title, french_title, isActive } = data;
        //console.log('english_title, spanish_title, french_title', english_title, spanish_title, french_title)
        this.setState({
            catId : _id, 
            enTitle : english_title, 
            esTitle : spanish_title, 
            frTitle : french_title, 
            isActive,
            modalType : 'edit'
        }, () => {
            this.toggle(type);
        })
    }
    
    renderIndex=(record) => {
      return record.rowIndex + 1;
    }

    editRecord = (record) => {
        let id = record.id;
        //console.log('record', record)
    }

    getSelectedRows = () => {
        const selectedNodes = this.gridApi.getSelectedNodes()
        const selectedData = selectedNodes.map( node => node.data )
        const selectedDataStringPresentation = selectedData.map( node => node.make + ' ' + node.model).join(', ')
        alert(`Selected nodes: ${selectedDataStringPresentation}`)
    }

    renderGetDeleteColumn = () =>{
      return <div>Delete</div> 
    }

    static getDerivedStateFromProps(props, state){
      //console.log("inside the getDerivedStateFromProps condition======", props.categories.categories && props.categories.categories !== state.categories && !props.categories.isFetching && props.categories.isFetched)
      if(props.categories.categories && props.categories.categories !== state.categories && !props.categories.isFetching && props.categories.isFetched){
          return {
            categories : props.categories.categories,
              total : props.categories.categories.length
          }
      }
  }

  componentDidUpdate(prevProps, prevState){
    //console.log('updated props', this.props);
    let { categoryAdd } = this.props;
    if(categoryAdd && categoryAdd !== prevProps.categoryAdd){
        if(categoryAdd && categoryAdd.catCreated && !categoryAdd.catCreateError ){
          notification('success', categoryAdd.msg);
          this.setState({ modal : false, modalType : '', enTitle : '', frTitle : '', esTitle : '' })
          return this.props._fetchCategories();
        }
        if(categoryAdd && categoryAdd.catCreateError && !categoryAdd.catCreated ){
          notification('error', categoryAdd.msg)
        }
      }
    
  }

    toggle = (type) => {

      // e.preventDefault();
      // let { name } = e.target
      let {enTitle,esTitle,frTitle,modal} = this.state
      if(type == 'add'){
        if(!(enTitle && esTitle && frTitle)){
          return this.setState({modal:modal})
        }else{
          this.setState({modal: !modal})
          //console.log(modal)
          return this.props._addTtitleCategory({
            enTitle : enTitle,
            frTitle : frTitle,
            esTitle : esTitle
          })
        }
      }
      this.setState((prevStates) => {
        let data = {
          modal : !prevStates.modal,
          modalType : type,
        }
        if(prevStates.modal === true){
          data = {
            modal : !prevStates.modal,
            modalType : type,
            enTitle : '',
            frTitle : '',
            esTitle : ''
          }
        }
        return(data) 
      })
    }

 

    onChangeHandler= (name, value) => {
      //console.log('name, value', name, value);
      let { isActive } = this.state;
      if(name === 'isActive') return this.setState({
        isActive : !isActive
      })
      this.setState({ [name] : value })
    }

  //   onPageChange = (page) => {
  //     let { limit } = this.state;
  //     // console.clear()
  //     console.log("check for the page change=============",page,limit)
  //     this.props._fetchCategories();
  // }


    renderIsActiveColumn = (data) =>{
      //console.log("check for the data======================",data)
      return <CustomInput type="switch" id={Math.random()*10000} checked={data.data.isActive} />
    }

    updateCat = () => {
      let { catId, enTitle, frTitle, esTitle, isActive } = this.state;
      let data = {
        spanish_title : esTitle,
        english_title : enTitle,
        french_title : frTitle,
        isActive, 
        catId
      }
      this.props._editCategory(data);

    } 

    render(){
        //console.log('this.props allusers', this.props);
        let { headsAg, modal,categories, esTitle, enTitle, frTitle, isActive, modalType, catId } = this.state;
        //console.log('modalType', modalType);
        let { categories : { isFetched } } = this.props;
        return (
            <Container>
                <Row>
                    <Col md={12} >
                        <Card>
                            <CardBody>
                                <h3 className='page-title bb-1 border-gray pb-2'>Categories</h3>
                                <Row className="mt-2">
                                    <Col md={12} className="text-right">
                                      <Button color="primary" onClick={this.toggle}>Add +</Button>
                                    </Col>
                                    <Col md={12} style={{ height: '90vh', width: '600px' }} className="ag-theme-material">
                                        <TableComponent 
                                            rowsData={ categories } 
                                            headsAg={headsAg}
                                            editRecord={this.editRecord} 
                                            rowSelectable={'multiple'} 
                                            rowsPerPage={20}
                                            isFetched = {isFetched} 
                                            {...this.props} 
                                            noRecordsText = {'No Categories Found!'}
                                            fetchingRecordsText = {'Fetching Categories...'}
                                        />
                                        <Col md={12} className="text-right mt-2">
                                        {/* <TablePagination onPageChange={this.onPageChange} {...this.state}/> */}
                                    </Col>
                                    </Col>
                                    <Col md={12}>
                                      <AddCategoryModal
                                        toggle={this.toggle}
                                        customClass = {'test'}
                                        cancelButtonText = {'Cancel'}
                                        cancelAction = {(e)=>this.toggle(e)}
                                        confirmAction={(e)=>this.toggle(e)}
                                        confirmButtonText={'Add'}
                                        modal={modal}
                                        modalTitle={'Add New Category'}
                                      >
                                    <Form name = "add" >
                                            <FormGroup row >
                                                <Label htmlFor="enTitle" sm={2}>English Title</Label>
                                                <Col sm={10}>
                                                  <Input type="text" name="enTitle" id="enTitle" value={ enTitle }  onChange={(e) => {this.onChangeHandler(e.target.name,e.target.value)}} required placeholder="Enter English Title" />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Label htmlFor="esTitle" sm={2}>Spanish Title</Label>
                                                <Col sm={10}>
                                                  <Input type="text" name="esTitle" id="esTitle" value={ esTitle } onChange={(e) => {this.onChangeHandler(e.target.name,e.target.value)}} required placeholder="Enter Spanish Title" />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Label htmlFor="frTitle" sm={2}>French Title</Label>
                                                <Col sm={10}>
                                                  <Input type="text" name="frTitle" id="frTitle" value={ frTitle } onChange={(e) => {this.onChangeHandler(e.target.name,e.target.value)}} required placeholder="Enter French Title" />
                                                </Col>
                                            </FormGroup>
                                            {modalType ==='edit' && (
                                              <FormGroup row>
                                                  <Label htmlFor="frTitle" sm={2}>isActive</Label>
                                                  <Col sm={10}>
                                                  <CustomInput type="switch" name="isActive" id={catId} onClick={ (e) => {this.onChangeHandler(e.target.name,e.target.value)} } checked={ isActive } />
                                                  </Col>
                                              </FormGroup>
                                            )}

                                                <hr />
                                                <div style={{textAlign:'end'}}>
                                                  {
                                                    modalType === 'edit' ? (
                                                      <>
                                                        <Button color="primary" type="button" name = 'udpate' onClick={(e)=>this.updateCat(e)} >Update </Button>{' '}
                                                      </>
                                                    ) : (
                                                      <>
                                                        <Button color="primary" type="button" name = 'add' onClick={(e)=>this.toggle('add')}  >Add </Button>{' '}
                                                      </>
                                                  )} 
                                                  <Button color="secondary"  name = 'cancel' onClick={(e)=>this.toggle()}> Cancel</Button>
                                                </div>
                                    </Form>
                                      </AddCategoryModal>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        )
    }
}

export default AllCategories