import { connect } from "react-redux";
import Notifications from '../../components/Notifications/Notifications';
import { createNotifications } from '../../redux/actions/notificationActions';
import { getAllUsers, searchUser } from '../../redux/actions/usersActions';
 
const mapStateToProps = state => {
  //console.log('state in container', state);
  return {
    users: state.appUsersList,
    notifications: state.notifications 
  };
};
 
const mapDispatchToProps = (dispatch) => {
  return {
    _createNotifications: (data) => {
      return dispatch(createNotifications(data));
    },
    fetchUsers: (page, skip) => {
      return dispatch(getAllUsers(page, skip));
    },
    _searchUser : (searchInfo, active) => {
      return dispatch(searchUser(searchInfo));
    }
  };
};
 
const NotificationsContainer = connect(mapStateToProps, mapDispatchToProps)(
    Notifications
);
 
export default NotificationsContainer;