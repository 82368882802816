import React from 'react';
import {Container, Row, Col, FormGroup, Form, Button , Modal, ModalHeader, ModalBody, Table} from 'reactstrap';
import moment from 'moment';
import { SPOKEN_LANGUAGE_OBJECT, SPOKEN_LANGUAG_ARRAY } from './../../inc/constants';
const FaqSeq = (props) => {

    // render(){
        let { modal, toggle , modalTitle, CustomClass, data, message, date, time, status, language, onChangeHandler, onUpdatehandler} = props;
        //console.log('data',data);
        return(
            <Form>
                <Modal isOpen={modal} toggle={toggle} className={CustomClass}>
                    <ModalHeader toggle={toggle}>{modalTitle}</ModalHeader>
                    <ModalBody>
                        <Form name = "add" onSubmit={onUpdatehandler}>
                            <Row>
                                <Col>
                                    <Table >
                                        <thead>
                                            <tr>
                                                <th>Title</th>
                                                <th>Seq</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        { data && data.map((d) => {
                                            return(
                                                <tr key={d._id}>
                                                    <th scope="row">{ d.title } </th>
                                                    <td><input type='number' value={d.seqNum} onChange={ (e) => { onChangeHandler(d._id, e.target.value ) } } name="seqNum" /></td>
                                                </tr>
                                            )// alert(time)
                                        }) }
                                        </tbody>
                                        </Table>
                                    </Col>
                                
                            </Row>
                            <div style={{textAlign:'end'}}>
                                <Button color="primary" type="submit" name = 'update' onClick={ (e) => { onUpdatehandler(e) } } >Update</Button>{' '}
                                <Button color="secondary"  name = 'cancel' onClick={(e) => toggle()}> Cancel</Button>
                            </div>
                        </Form>
                    </ModalBody>
                </Modal>
            </Form>
        )
}

export default FaqSeq