import { wrappedGet, wrappedFetch } from './urlFuncs';
import { GET_FINDER_MEETINGS, GET_FINDER_MEETINGS_DETAILS, UPDATE_FINDER_MEETINGS_DETAILS, UPLOAD_FINDER_MEETINGS , TRANSLATE_FINDER_MEETINGS, TRANSLATE_SINGLE_MEETING} from './../inc/constants';
import {FINDER_URL} from './../inc/config';

export const listFinderMeetings = (page, limit, search) => {
    return wrappedGet(`${FINDER_URL}${GET_FINDER_MEETINGS}?page=${page}&limit=${limit}&search=${search}`,'GET')
}

export const getMeetingInAnyLanguage = (meetingId, language) => {
    return wrappedGet(`${FINDER_URL}${GET_FINDER_MEETINGS_DETAILS}?meetingId=${meetingId}&language=${language}`,'GET')
}

export const updateFinderMeeting = (data) => {
    return wrappedFetch(`${FINDER_URL}${UPDATE_FINDER_MEETINGS_DETAILS}`,'POST',data)
}

export const uploadFinderMeetings = (data) => {
    return wrappedFetch(`${FINDER_URL}${UPLOAD_FINDER_MEETINGS}`,'POST',data)
}

export const translateFinderMeetings = (data) => {
    return wrappedFetch(`${FINDER_URL}${TRANSLATE_FINDER_MEETINGS}`,'POST',data)
}

export const translateSingleMeet = (data) => {
    return wrappedFetch(`${FINDER_URL}${TRANSLATE_SINGLE_MEETING}`,'POST',data)
}
