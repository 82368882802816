import * as Actions from './actionTypes';
import * as userApis from './../../api/userApis';
import * as adminAPis from './../../api/adminApis';
import axios from 'axios';


  export function getAllUsersRequest() {
    return {
      type: Actions.ADMINLISTREQUEST,
      payload:{}
    };
  }

  export function getAllUsersSuccess(data) {

    return {
      type: Actions.ADMINLISTSUCCESS,
      payload : data,
    };
  }

export function getAllUsersError(message) {
    return {
      type: Actions.ADMINLISTERROR,
      payload : { message },
    };
  }

  export function addAdminRequest() {
    return {
      type: Actions.ADDADMINREQUEST,
      payload:{}
    };
  }
  
  export function addAdminSuccess(data) {
    return {
      type: Actions.ADDADMINSUCCESS,
      payload : {
        message : data.message
      },
    };
  }
  
  export function addAdminError(message) {
      return {
        type: Actions.ADDADMINERROR,
        payload : {
          message
        },
      };
    }

    export function addTitleCategory(data){
      return{
        type : Actions.ADDCATEGORYTITLE,
        payload : data
      }
    }


    export function getSearchRequest() {
      return {
        type: Actions.SEARCHREQUEST,
        payload:{}
      };
    }
    
    export function getSearchSuccess(data) {
      return {
        type: Actions.SEARCHSUCCESS,
        payload : data,
      };
    }
    
    export function getSearchError(message) {
        return {
          type: Actions.SEARCHERROR,
          payload : { message },
        };
      }
    export function deleteAdminRequest() {
      return {
        type: Actions.DELETEADMINREQUEST,
        payload:{}
      };
    }
    
    export function deleteAdminSuccess(data) {
      return {
        type: Actions.DELETEADMINSUCCESS,
        payload : {
          message : data.message
        },
      };
    }
    
  export function deleteAdminError(message) {
      return {
        type: Actions.DELETEADMINERROR,
        payload : {
          message
        },
      };
    }
  export function updateAdminRequest() {
    return {
      type: Actions.UPDATEADMINREQUEST,
      payload:{}
    };
  }
  
  export function updateAdminSuccess(data) {
    return {
      type: Actions.UPDATEADMINSUCCESS,
      payload : {
        message : data.message
      },
    };
  }
  
  export function updateAdminError(message) {
      return {
        type: Actions.UPDATEADMINERROR,
        payload : {
          message
        },
      };
    }
export const getAllUsers = (page, skip) =>{
    return (dispatch) => {
        dispatch(getAllUsersRequest());
        userApis.getAllAdminUsers(page, skip).then((res)=>{
            console.log('responseData', res)
            if(res.status === 200 )return dispatch(getAllUsersSuccess(res.data));
            if(res.status === 401 )return dispatch(getAllUsersError(res.data.message));
            if(res.status === 409 )return dispatch(getAllUsersError(res.data.message));
        }).catch((err)=>{
          console.error('err', err)
          return dispatch(getAllUsersError(err.msg))
        })
    }
}

export const addAdmin = (data) =>{
  return (dispatch) => {
      dispatch(addAdminRequest());
      adminAPis.addNewAdmin(data).then((res)=>{
        console.log('res', res);
        if(res.status === 200) return dispatch(addAdminSuccess(res.data));
        if(res.status === 400) return dispatch(addAdminError(res.data.message));
        if(res.status === 401) return dispatch(addAdminError(res.data.message));
        if(res.status === 409) return dispatch(addAdminError(res.data.message));
      }).catch((err)=>{
        console.log('err',err)
        return dispatch(addAdminError(err.message));
      })
  }
}



export const searchUser = (seachInfo) =>{
  return (dispatch) => {
      dispatch(getSearchRequest());
      adminAPis.searchForUser(seachInfo).then((res)=>{
        console.log('res', res);
        if(res.status === 200) return dispatch(getSearchSuccess(res.data));
        if(res.status === 400) return dispatch(getSearchError(res.data.message));
        if(res.status === 409) return dispatch(getSearchError(res.data.message));
      }).catch((err)=>{
        console.log('err',err)
        if(!axios.isCancel(err)) return dispatch(getSearchError(err.message));
      })
  }
}

export const deleteAdminUser = (userId) =>{
  return (dispatch) => {
      dispatch(deleteAdminRequest());
      adminAPis.deleteAdmin(userId).then((res)=>{
        console.log('res', res);
        if(res.status === 200) return dispatch(deleteAdminSuccess(res.data));
        if(res.status === 400) return dispatch(deleteAdminError(res.data.message));
        if(res.status === 409) return dispatch(deleteAdminRequest(res.data.message));
        // if(res.status === 401) return dispatch(deleteAdminError(res.data.message));
      }).catch((err)=>{
        console.log('err',err)
        if(!axios.isCancel(err)) return dispatch(getSearchError(err.message));
      })
  }
}


export const updateAdmin = ( userId, data ) =>{
  return (dispatch) => {
      dispatch(updateAdminRequest());
      adminAPis.updateAdmin( userId, data ).then((res)=>{
        console.log('res', res);
        if(res.status === 200) return dispatch(updateAdminSuccess(res.data));
        if(res.status === 400) return dispatch(updateAdminError(res.data.message));
        if(res.status === 409) return dispatch(updateAdminError(res.data.message));
        // if(res.status === 401) return dispatch(deleteAdminError(res.data.message));
      }).catch((err)=>{
        console.log('err',err)
        if(!axios.isCancel(err)) return dispatch(updateAdminError(err.message));
      })
  }
}
