import React from 'react';
import {Container, Row, Col, Card, CardBody, Form, Button, Label, Input, Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';
import moment from 'moment';
import { SPOKEN_LANGUAGE_OBJECT } from './../../inc/constants';
const AddCategory = (props) => {

        let { modal, toggle , modalTitle, CustomClass, description, media } = props;
        return(
            <Form>
                <Modal isOpen={modal} toggle={toggle} className={CustomClass}>
                    <ModalHeader toggle={toggle}>{modalTitle}</ModalHeader>
                    <ModalBody>
                            <Row>
                                <Col>
                                    <p>{description}</p>
                                    {media && (
                                        <img src={media} alt="img" className="img-responsive" />
                                    )}
                                    {/* <p>Date :- {moment(date).format('MM-DD-YY')}</p>
                                    <p>Language :- {SPOKEN_LANGUAGE_OBJECT[language]}</p>
                                    <p>{content}</p> */}
                                </Col>
                            </Row>
                    </ModalBody>
                    
                </Modal>
            </Form>
        )
}

export default AddCategory