import { connect } from "react-redux";
import * as _ from "lodash";
import * as actions from "../../../redux/actions/actionTypes";
import { resetPasswordRequestAction } from '../../../redux/actions/loginActions'
import ForgotPassword from '../../../components/Account/ForgotPassword/ForgotPassword';

export const mapStateToProps = state => {
  //console.log('all states', state)
  return {
    forgotPassword: state.forgotPassword 
  };
};

const mapDispatchToProps = dispatch => {
  return {
    resetPasswordRequest: (email) => {
      return dispatch(resetPasswordRequestAction(email));
    },
  }
};

const ResetPasswordRequestContainer = connect(mapStateToProps, mapDispatchToProps)(
    ForgotPassword
);

export default ResetPasswordRequestContainer;
