import { APPUSERBLOCKERROR, APPUSERBLOCKREQUEST, APPUSERBLOCKSUCCESS, APPUSERDELETEERROR, APPUSERDELETEREQUEST, APPUSERDELETESUCCESS} from './../actions/actionTypes'
 
const initialState = {
    requestDone: false,
    updated: false,
    isFetched: false,
    isFetching : false,
    err : false,
    message:'',
  };
export default function deleteAppUsers(state = initialState, action) {
    //console.log('action in block user', action);
    let { payload } = action
    switch(action.type){
        case APPUSERDELETEREQUEST:
            return { 
                requestDone: false,
                updated: false,
                isFetched: false,
                isFetching : true,
                err : false,
                message:'',
            };
            break;
        case APPUSERDELETESUCCESS:
            //console.log('in switch', payload.data)
        return { 
            requestDone: true,
            updated: true,
            isFetched: true,
            isFetching : false,
            err : false,
            message:payload.message,
        };
        break;

        case APPUSERDELETEERROR:
        return { 
            requestDone: true,
            updated: false,
            isFetching : false,
            isFetched: false,
            err : true,
            message:payload.message,
        };
        break;

        default : 
            return state
            break
    }
}
