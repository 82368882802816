import { connect } from "react-redux";
import AllUsers from '../../components/Admin/AllUsers';
import { getAllUsers, searchUser, deleteAdminUser, updateAdmin } from '../../redux/actions/AdminActions';

const mapStateToProps = state => {
  //console.log('state in container', state);
  return {
    users: state.adminsList, 
    deleteAdmin: state.deleteAdmin, 
    updateAdminS: state.updateAdminS, 
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchUsers: (page, skip) => {
      return dispatch(getAllUsers(page, skip));
    },
    _searchAdmin: (searchInfo) => {
      return dispatch(searchUser(searchInfo));
    },
    _deleteAdminUser : (id) => {
      return dispatch(deleteAdminUser(id));
    },
    _updateAdmin : ( id, data ) => {
      return dispatch(updateAdmin(id, data));
    }
  };
};

const AllUsersContainer = connect(mapStateToProps, mapDispatchToProps)(
    AllUsers
);

export default AllUsersContainer;
