import { connect } from "react-redux";
import * as _ from "lodash";
import AddFAQ from './../../components/FAQ/CreateFaq'
import { addFAQ } from './../../redux/actions/faqActions'

export const mapStateToProps = state => {
  return {
    createFAQ: state.createFAQ 
  };
};

export const mapDispatchToProps = (dispatch) => {
  return {
    _onCreate: (data) => {
      return dispatch(addFAQ(data));
    },
  };
};

const AddFAQContainer = connect(mapStateToProps, mapDispatchToProps)(AddFAQ);

export default AddFAQContainer;
