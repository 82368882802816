import { connect } from "react-redux";
import * as _ from "lodash";
import MyProfile from '../../../components/Account/Profile/MyProfile';
import { updateProfileAction, handleUpdateProfileClear } from '../../../redux/actions/profileActions';

export const mapStateToProps = state => {
  return {
    user : state.login,
    updateProfile: state.updateProfile 
  };
};

export const mapDispatchToProps = dispatch => {
  return {
    onUpdate: (data) => {
      return dispatch(updateProfileAction(data))
    },
    clearStates: () => {
      return dispatch(handleUpdateProfileClear())
    }
  }
};

// const MyProfileContainer = connect(mapStateToProps, mapDispatchToProps)(
//     MyProfile
// );

const MyProfileContainer = connect(mapStateToProps,mapDispatchToProps)(MyProfile);

export default MyProfileContainer;
