import { connect } from "react-redux";
import VirtualTour from '../../components/VirtualTour';
import { createVirtualTour, fetchVirtualTour } from '../../redux/actions/virtualTourActions';
 
const mapStateToProps = state => {
  //console.log('state in container', state);
  return {
    virtualTour: state.virtualTour , 
    fetchTour : state.fetchVirtualTour
  };
};
 
const mapDispatchToProps = (dispatch) => {
  return {
    _fetchVirtualTour: () => {
      return dispatch(fetchVirtualTour());
    },
    _createVirtualTour: (data, language) => {
      return dispatch(createVirtualTour(data,language));
    },
  };
};
 
const VirtualTourContainer = connect(mapStateToProps, mapDispatchToProps)(
    VirtualTour
);
 
export default VirtualTourContainer;