import { 
  CREATEVIRTUALTOURREQUEST, 
  CREATEVIRTUALTOURERROR, 
  CREATEVIRTUALTOURSUCCESS,
  FETCHVIRTUALTOURERROR,
  FETCHVIRTUALTOURREQUEST,
  FETCHVIRTUALTOURSUCCESS 
} from './actionTypes';
import * as virtualTourFuncs from './../../api/account';



export function  createVTRequest() {
    return {
      type: CREATEVIRTUALTOURREQUEST,
      payload:{}
    };
  }
   
  export function createVTSuccess(data) {
    return {
      type: CREATEVIRTUALTOURSUCCESS,
      payload : data,
    };
  }
   
  export function  createVTError(message) {
      return {
        type: CREATEVIRTUALTOURERROR,
        payload : { message },
      };
  }

  export function  fetchVTRequest() {
    return {
      type: FETCHVIRTUALTOURREQUEST,
      payload:{}
    };
  }
   
  export function fetchVTSuccess(data) {
    return {
      type: FETCHVIRTUALTOURSUCCESS,
      payload : data,
    };
  }
   
  export function fetchVTError(message) {
      return {
        type: FETCHVIRTUALTOURERROR,
        payload : { message },
      };
  }

export const createVirtualTour = (data, language) =>{
  return (dispatch) => {
      dispatch(createVTRequest());
      console.log('premium content data', data)
      virtualTourFuncs.createVTour(data, language).then((res)=>{
          console.log('responseData*****************************', res)
          if(res.status === 200) return dispatch(createVTSuccess(res.data));
          if(res.status === 400) return dispatch(createVTError(res.data.message));
          if(res.status === 401) return dispatch(createVTError(res.data.message));
          if(res.status === 409) return dispatch(createVTError(res.data.message));

      }).catch((err)=>{
          console.log('err', err)
          return dispatch(createVTError(err.message));
      })
  }
}


export const fetchVirtualTour = () =>{
  return (dispatch) => {
      dispatch(fetchVTRequest());
      virtualTourFuncs.fetchVirtualTour().then((res)=>{
          console.log('responseData*****************************', res)
          if(res.status === 200) return dispatch(fetchVTSuccess(res.data));
          if(res.status === 400) return dispatch(fetchVTError(res.data.message));
          if(res.status === 401) return dispatch(fetchVTError(res.data.message));
          if(res.status === 409) return dispatch(fetchVTError(res.data.message));

      }).catch((err)=>{
          console.log('err', err)
          return dispatch(fetchVTError(err.message));
      })
  }
}
