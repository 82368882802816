import { connect } from "react-redux";
import * as _ from "lodash";
import * as actions from "../../redux/actions/actionTypes";
import { listAllCategories,addTtitleCategory, editCategory } from '../../redux/actions/categoryActions';
import AllCategory from '../../components/Category/AllCategory.jsx'

const mapStateToProps = state => {
  // console.clear()
  //console.log('state in container', state);
  return {
    categories: state.categories, 
    categoryAdd: state.category 
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    _fetchCategories: () => {
      return dispatch(listAllCategories());
    },
    _addTtitleCategory : (titleData) =>{
      return dispatch(addTtitleCategory(titleData))
    },
    _editCategory : (titleData) =>{
      return dispatch(editCategory(titleData))
    }
  };
};


const AllCategoryContainer = connect(mapStateToProps, mapDispatchToProps)(AllCategory);

export default AllCategoryContainer;
