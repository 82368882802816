// import { ADDADMIN, ERROR, FORGETPASSWORD } from '../actions/ADDADMIN_action';
import { GETALLFAQREQUEST, GETALLFAQSUCCESS, GETALLFAQERROR, GETALLFAQCLEAR } from './../actions/actionTypes'
 
const initialState = {
    requestDone: false,
    isFetched: false,
    isFetching : false,
    err : false,
    message:'',
    data: [],
    total : 0,
};
  
  // receiving response sent by action according to type of action
  export default function getAllFAQ(state = initialState, action) {
      //console.log(action,'action')
      let { payload } = action
    switch (action.type) {
        case GETALLFAQCLEAR:
          return { 
            requestDone: false,
            isFetched: false,
            isFetching : false,
            err : false,
            message:'',
            data: [],
            total :0
           };
          break;
        case GETALLFAQREQUEST:
          return { 
            requestDone: false,
            isFetched: false,
            isFetching : true,
            err : false,
            message:'',
            data: [],
            total : 0
           };
          break;
        case GETALLFAQSUCCESS:
            //console.log('========in switch', payload.categories)
        return { 
            requestDone: true,
            isFetched: true,
            isFetching : false,
            err : false,
            message:'',
            data: payload.data,
            total : payload.count
        };
        break;
  
        case GETALLFAQERROR:
        return { 
            requestDone: true,
            isFetched: false,
            isFetching : false,
            err : true,
            message: payload.message,
            data: [],
            total : 0
        };
        break;
      default:
        return state;
    }
  }
