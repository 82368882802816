// import { ADDADMIN, ERROR, FORGETPASSWORD } from '../actions/ADDADMIN_action';
import { GETALLCATEGORIESSCLEAR, GETALLCATEGORIESSERROR, GETALLCATEGORIESSREQUEST, GETALLCATEGORIESSUCCESS,CREATECATEGORYREQUEST,CREATECATEGORYSUCCESS,CREATECATEGORYERROR } from './../actions/actionTypes'
 
const initialState = {
    requestDone: false,
    isFetched: false,
    isFetching : false,
    err : false,
    message:'',
    categories: [],
    total : 0,
  };
  
  // receiving response sent by action according to type of action
  export default function allCategoriesReduces(state = initialState, action) {
      //console.log(action,'action')
      let { payload } = action
    switch (action.type) {
        case GETALLCATEGORIESSCLEAR:
          return { 
            requestDone: false,
            isFetched: false,
            isFetching : false,
            err : false,
            message:'',
            categories: [],
            total :0
           };
          break;
        case GETALLCATEGORIESSREQUEST:
          return { 
            requestDone: false,
            isFetched: false,
            isFetching : true,
            err : false,
            message:'',
            categories: [],
            total : 0
           };
          break;
        case GETALLCATEGORIESSUCCESS:
            //console.log('========in switch', payload.categories)
        return { 
            requestDone: true,
            isFetched: true,
            isFetching : false,
            err : false,
            message:'',
            categories: payload.data,
            total : (payload.total) ? payload.total : payload.count
        };
        break;
  
        case GETALLCATEGORIESSERROR:
        return { 
            requestDone: true,
            isFetched: false,
            isFetching : false,
            err : true,
            message: payload.message,
            users: [],
            total : 0
        };
        break;
      default:
        return state;
    }
  }
