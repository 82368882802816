import * as Actions from './actionTypes';
import * as ReportFuncs from './../../api/reportsApis';



export function  createReportRequest() {
    return {
      type: Actions.CREATEAPPUSERSREPORTREQUEST,
      payload:{}
    };
  }
   
export function createReportSuccess(data) {
  return {
    type: Actions.CREATEAPPUSERSREPORTSUCCESS,
    payload : data,
  };
}
  
export function  createReportError(message) {
  return {
    type: Actions.CREATEAPPUSERSREPORTERROR,
    payload : { message },
  };
}

export function  fetchReportRequest() {
  return {
    type: Actions.FETCHTCREQUEST,
    payload:{}
  };
}
  
export function fetchReportSuccess(data) {
  return {
    type: Actions.FETCHTCSUCCESS,
    payload : data,
  };
}
  
export function  fetchReportError(message) {
    return {
      type: Actions.FETCHTCERROR,
      payload : { message },
    };
}

// export function  deleteReportRequest() {
//   return {
//       type: Actions.DELETEReportREQUEST,
//       payload:{}
//   };
// }
    
// export function deleteReportSuccess(data) {
//     return {
//         type: Actions.DELETEReportSUCCESS,
//         payload : data,
//     };
// }
    
// export function  deleteReportError(message) {
//       return {
//         type: Actions.DELETEReportERROR,
//         payload : { message },
//     };
// }
  
   
//action creator to fetch user reports
export const generateUserReports = (from, to) =>{
  return (dispatch) => {
    dispatch(createReportRequest());
    console.log('premium Report data', from, to)
    ReportFuncs.generateUserReport(from, to).then((res)=>{
      console.log('responseData*****************************', res)
      if(res.status === 200) return dispatch(createReportSuccess(res.data));
      if(res.status === 400) return dispatch(createReportError(res.data.message));
      if(res.status === 401) return dispatch(createReportError(res.data.message));
      if(res.status === 409) return dispatch(createReportError(res.data.message));
      
    }).catch((err)=>{
      console.log('err', err)
      return dispatch(createReportError(err.message));
    })
  }
}

//action creator to fetch meetings
export const generateMeetingsReports = (from, to) =>{
  return (dispatch) => {
    dispatch(createReportRequest());
    console.log('premium Report data', from, to)
    ReportFuncs.generateMeetingReport(from, to).then((res)=>{
      console.log('responseData*****************************', res)
          if(res.status === 200) return dispatch(createReportSuccess(res.data));
          if(res.status === 400) return dispatch(createReportError(res.data.message));
          if(res.status === 401) return dispatch(createReportError(res.data.message));
          if(res.status === 409) return dispatch(createReportError(res.data.message));
          
        }).catch((err)=>{
          console.log('err', err)
          return dispatch(createReportError(err.message));
        })
      }
    }
    
//action creator to fetch contributions
export const generateContributionsReports = (from, to) =>{
  return (dispatch) => {
      dispatch(createReportRequest());
      console.log('premium Report data', from, to)
      ReportFuncs.generateContributionsReport(from, to).then((res)=>{
          console.log('responseData*****************************', res)
          if(res.status === 200) return dispatch(createReportSuccess(res.data));
          if(res.status === 400) return dispatch(createReportError(res.data.message));
          if(res.status === 401) return dispatch(createReportError(res.data.message));
          if(res.status === 409) return dispatch(createReportError(res.data.message));

      }).catch((err)=>{
          console.log('err', err)
          return dispatch(createReportError(err.message));
      })
  }
}

