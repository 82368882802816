import React from 'react';
import { Container, Row, Col, Card, CardBody, Form, FormGroup, Input, Button } from 'reactstrap';
import TableComponent from './../Shared/Table/Table';
import TablePagination from './../Shared/Table/TablePagination';
import moment from 'moment';
import notify from './../../helpers/notificationHelper';
import EyeIcon from 'mdi-react/EyeIcon';
import PenIcon from 'mdi-react/PencilIcon';
import DeleteIcon from 'mdi-react/DeleteIcon';
import EditAdminUsersModal from './../Shared/EditAdminUsersModal';
import CommonModal from './../Shared/CommonModal';

class AllUsers extends React.Component{
    state={
        headsAg : [],
        page : 1,
        limit : 100,
        users : [],
        selectedRecord : '',
        isBlocked : false,
        total:0,
        search : '',
        modal : false,
        deleteModal : false,
        editModal : false,
        selectedUser : '',
        isUpdating : false,
        firstname : '',
        lastname : '',
        username : '',
        email : '',
        country_code : '',
        phone : '',
        permissions : [],
        password : '',
        role : ""
    }
    
    componentDidMount(){
        
        let { page, limit } = this.state; 
        let { users: { users, total } } = this.props;
        this.props.fetchUsers(page, limit);
        let headsAg = [
            {
                headerName: "Sr.", 
                field: "i", 
                width:"60", 
                sortable:true, 
                filter:'text', 
                cellRendererFramework : this.renderIndex 
            },
            {headerName: "Name", field: "firstname", sortable:true, filter:'text',  },
            {headerName: "Lastname", field: "lastname", sortable:true, filter:'text' },
            {headerName: "Username", field: "username", sortable:true, filter:'text'},
            {headerName: "Email", field: "email.mail", sortable:true, filter:'text'},
            {headerName : "Role", field : "role", sortable:true, filter : "text"},
            {headerName: "Phone", filter:'text',
            cellRendererFramework : this.renderPhoneNumber 
            },
            {
                headerName: "Join Date", field: "createdAt", sortable:true, 
                cellRendererFramework : this.renderDate, 
                filter:'text'
            },
            {
                headerName: "Actions", 
                cellRendererFramework : this.renderAction, 
                sortable:true, 
                filter:'text'
            },
          ]
        this.setState({  headsAg , users, total })
    }

    setData = (data, modal) => {
        let { firstname, lastname, username, email, country_code, phone , password, permissions, role } = data;
        if(!password) password = '';
        //console.log('email in all user list', email)
        this.setState({
            selectedUser : data._id,
            firstname,
            lastname,
            username,
            country_code,
            password ,
            permissions,
            email : email.mail,
            phone : phone.number,
            role : role[0]
        }, () =>{
            if(modal === 'editModal') return this.toggleEditModal();
            if(modal === 'deleteModal') return this.toggleDeleteModal();
        })
    }

    componentDidUpdate(prevProps, prevStates){
        let { users, deleteAdmin, updateAdminS } = this.props;
        let { page, limit } = this.state;
        if( prevProps.deleteAdmin !== deleteAdmin && !deleteAdmin.isFetching && deleteAdmin.isCreated && !deleteAdmin.err  ){
            notify('success' ,deleteAdmin.message);
            this.props.fetchUsers(page, limit);
            this.toggleDeleteModal()
        }
        if( prevProps.deleteAdmin !== deleteAdmin && !deleteAdmin.isFetching && !deleteAdmin.isCreated && deleteAdmin.err  ){
            notify('error' ,deleteAdmin.message);
            this.toggleDeleteModal();
        }
        if(prevProps.users !== users && !users.isFetching && users.err ){
            notify('error','Error Fetching Users!')
        }
        if(updateAdminS !== prevProps.updateAdminS){
            if(updateAdminS.err && !updateAdminS.isCreated) return notify('error', updateAdminS.message ) 
            if(!updateAdminS.err && updateAdminS.isCreated) {
                notify('success', updateAdminS.message );
                return this.setState({
                    editModal : false
                }, () => {
                    this.props.fetchUsers( page, limit );
                })
                // return this.props.history.push('/admin/users');
            } 
        }
    }

    static getDerivedStateFromProps(props, state){
        //console.log('props', props);
        if(props.users.users && props.users.users !== state.users && !props.users.isFetching && props.users.isFetched){
            return {
                users : props.users.users,
                total : props.users.total,
                page : state.page
            }
        }
        
        return null
    }
    


    editRecord = (record) => {
        let id = record.id;
    }

    renderAction = (record) => {
        let buttons = <span>
                    {/* <EyeIcon onClick={()=>{ this.setData(record.data, 'modal') } } size={'18px'} className="cursor-pointer"/> */}
                   { (localStorage.getItem('UserRole') === "superadmin" || (localStorage.getItem('UserRole') === "admin" && record.data.role[0] !== "superadmin" ))  && <PenIcon className="cursor-pointer ml-2" size={18} onClick={()=>{ this.setData(record.data, 'editModal') } }/>}
                   { (localStorage.getItem('UserRole') === "superadmin" || (localStorage.getItem('UserRole') === "admin" && record.data.role[0] !== "superadmin" )) && <DeleteIcon className="cursor-pointer ml-2" size={18} onClick={()=>{ this.setData(record.data, 'deleteModal') } }/>}
                </span>;
        return buttons;
    }

    renderIndex=(record) => {
        let { page, limit } = this.state;
        let index = record.rowIndex + 1 ;
        if(page > 1){
            index  = (page - 1)*limit + record.rowIndex + 1
        }
        return index;
    }

    renderDate = (data) => {
        return moment(data.data.createdAt).format('MM-DD-YYYY')
    }

    renderPhoneNumber =(data) => {
        let phone = '';
        if(data.data.country_code) {
            phone += data.data.country_code.toString()+'-';
        }
        phone += data.data.phone.number.toString()
        return phone
    }


    getSelectedRows = () => {
        const selectedNodes = this.gridApi.getSelectedNodes()
        const selectedData = selectedNodes.map( node => node.data )
        const selectedDataStringPresentation = selectedData.map( node => node.make + ' ' + node.model).join(', ')
    }

    onPageChange = (page) => {
        let { limit } = this.state;
        this.setState({page,limit})
        this.props.fetchUsers(page, limit);
    }

    searchHandler = (e) =>{

        let {value} = e.target
        this.setState({search:value,feching:true},()=>{
            let {search,page,limit} = this.state
            let searchInfo = {
                searchKey : search,
                page,
                limit
            }
            this.setState({isFetching:false})
            search =="" ? this.props.fetchUsers(page, limit):this.props._searchAdmin(searchInfo)
            
        })
    }

    toggleDeleteModal = (e) => {
        this.setState((prevState) => {
            return({
                deleteModal : !prevState.deleteModal
            })
        })
    }

    toggleEditModal = (e) => {
        this.setState((prevState) => {
            return({
                editModal : !prevState.editModal
            })
        })
    }

    onDeleteHandler = () => {
        let { selectedUser } = this.state;
        this.props._deleteAdminUser(selectedUser);
    }   

    onUpdateHandler =() => {
        let {  
                selectedUser,
                firstname,
                lastname,
                username,
                country_code,
                password ,
                permissions,
                email,
                phone ,
                role
            } = this.state;
        let data = {
            firstname,
            lastname,
            username,
            country_code,
            password ,
            permissions,
            email,
            phone,
            role
        }
        //console.log('data', data);
        this.props._updateAdmin(selectedUser, data);
        debugger;
    }

    onChangeHandler = (e) => {
        // console.log('name', e.target.name);
        // console.log('value', e.target.value);
        let { name , value } = e.target;
        let data = {
            [name] : value
        }
        return this.setState(data);
    }

    onAddPermissions = (permission) => {
        //console.log('permissions', permission)
        let { permissions } = this.state;
        let checkIndex = permissions.indexOf(permission.toString());
        //console.log('checkIndex', checkIndex)
        if( checkIndex > -1) permissions.splice(checkIndex, 1)
        else permissions.push(permission.toString());
        return this.setState({
            permissions
        });
    }

    render(){
        let { 
            headsAg, users, total, deleteModal, editModal, selectedRecord, isUpdating,
            firstname, lastname, username, email, country_code, phone, password, permissions, role
        } = this.state;
        // console.clear()
        return (
            <Container>
                <Row>
                    <Col md={12} >
                        <Card>
                            <CardBody>
                                <h3 className='page-title bb-1 border-gray pb-2 mb-2'>Admins</h3>
                                <Row>
                                {
                                    (localStorage.getItem('UserRole') === "superadmin") && 
                                    <Col md={{'offset' : 6,'size' : 3}} className="text-right">
                                        <Button color="primary" href="/admin/users/add" >Add +</Button>
                                    </Col>
                                }

                                    <Col md={{'size' : 3}} className="text-right">
                                        <Form className="text-right">
                                            <FormGroup>
                                                <Input type="search" name="search" placeholder="Search user" onChange={(e)=>this.searchHandler(e)}/>
                                            </FormGroup>
                                        </Form>
                                    </Col>
                                    <Col md={12} style={{ height: '90vh', width: '600px' }} className="ag-theme-material">
                                        <TableComponent 
                                            rowsData={ users } 
                                            headsAg={headsAg}
                                            editRecord={this.editRecord} 
                                            rowSelectable={'multiple'} 
                                            rowsPerPage={15} 
                                            fetchingRecordsText = {'Fetching Users...'}
                                            noRecordsText = {'No Users Found!'}
                                            {...this.props} 
                                        />
                                    </Col>
                                    <Col md={12} className="text-right mt-2">
                                        <TablePagination onPageChange={this.onPageChange} {...this.state}/>
                                    </Col>
                                    <Col>
                                        <CommonModal
                                            toggle={this.toggleDeleteModal}
                                            customClass = {'test'}
                                            cancelButtonText = {'Cancel'}
                                            cancelAction = {this.toggleDeleteModal}
                                            confirmAction={this.onDeleteHandler}
                                            confirmButtonText={'Yes'}
                                            modal={deleteModal}
                                            modalText={'Are you Sure you want to delete this Admin ? '}
                                            modalTitle={'Delete Admin User'}
                                        />
                                    </Col>
                                    <Col>
                                    <EditAdminUsersModal
                                        toggle={this.toggleEditModal}
                                        customClass = {'test'}
                                        cancelButtonText = {'Cancel'}
                                        cancelAction = {this.toggleEditModal}
                                        confirmAction={this.onUpdateHandler}
                                        onChangeHandler={this.onChangeHandler}
                                        onAddPermissions={this.onAddPermissions}
                                        confirmButtonText={'Add'}
                                        modal={editModal}
                                        data={selectedRecord}
                                        func = {'block'}
                                        isFetching = {isUpdating}
                                        firstname = {firstname}
                                        lastname = {lastname}
                                        email = {email}
                                        username = {username}
                                        phone = {phone}
                                        country_code = {country_code}
                                        password = {password}
                                        permissions = {permissions}
                                        role={role}
                                    />
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        )
    }
}

export default AllUsers