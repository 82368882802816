import { connect } from "react-redux";
// import { generateMeetingsReport } from "../../api/reportsApis";
import ReportsExports from '../../components/Reports/Reports';
import { generateContributionsReports, generateUserReports, generateMeetingsReports } from '../../redux/actions/reportsActions';
 
const mapStateToProps = state => {
  //console.log('state in container', state);
  return {
    requestReport : state.requestReport
  };
};
 
const mapDispatchToProps = (dispatch) => {
  return {
    _exportAppUsers : (from, to) => {
      return dispatch(generateUserReports(from, to));
    },
    _exportMeetings : ( from, to ) => {
      return dispatch(generateMeetingsReports( from, to ));
    },
    _exportContributions : ( from, to ) => {
      return dispatch(generateContributionsReports( from, to ));
    }
  };
};
 
const ReportsExportsContainer = connect(mapStateToProps, mapDispatchToProps)(
    ReportsExports
);
 
export default ReportsExportsContainer;