import { wrappedFetch, wrappedGet } from './urlFuncs';
import { LOGIN_URL, CHECK_LOGIN_URL, LOGOUT_URL, RESET_PASSWORD_URL, VIRTUAL_TOUR } from '../inc/constants';
import { API_URL } from '../inc/config'

export const loginUser = (username, password) =>{
  let body = {
      email:username, password
    }
  return wrappedFetch(`${API_URL}${LOGIN_URL}`, 'POST', body);
}

export const checkLogin = () =>{
  return wrappedFetch(`${API_URL}${CHECK_LOGIN_URL}`,'POST',{}); 
}

export const logout = () =>{
  return wrappedFetch(`${API_URL}${LOGOUT_URL}`,'POST',{}); 
}

export const resetPassword = (email) =>{
  let body = { email }
  return wrappedFetch(`${API_URL}${RESET_PASSWORD_URL}`,'POST',body); 
}


export const createVTour = (data, language) =>{
  let body = { tour_link : data, language }
  return wrappedFetch(`${API_URL}${VIRTUAL_TOUR}`,'POST',body); 
}

export const fetchVirtualTour = (page, limit) =>{
  return wrappedGet(`${API_URL}${VIRTUAL_TOUR}`, 'GET');
}
