import React from 'react';
import { Card, CardBody, Container, Row, Col, Label, Input, Form, Button, FormGroup } from 'reactstrap';
import notificaiton from './../../helpers/notificationHelper';

export default class AddeNewBannedEmail extends React.Component {
    state = {
        email : ""
    }

    componentDidUpdate(prevProps, prevStates){
        let { bannedEmail } = this.props;
        if(bannedEmail !== prevProps.bannedEmail){
            if( bannedEmail.isFetched && !bannedEmail.err && !bannedEmail.isFetching){
                notificaiton('success', bannedEmail.message);
                return this.props.history.push('/banned-emails');
            }
            if( !bannedEmail.isFetched && bannedEmail.err && !bannedEmail.isFetching){
                notificaiton('error', bannedEmail.message);
            }
        }
    }

    onSubmitHandler = (e) => {
        e.preventDefault();
        this.props._addNewBannedEmail(this.state)
    }

    onChangeHandler = (e) => {
        let {name, value} = e.target
        let data = {}
        if(name === 'email'){
            data['email'] = value
        }
        return this.setState(data);
    }

    render(){
        let {email} = this.state
        return (
            <Container  >
                <Row>
                    <Col md={12}>
                        {/* <Card>
                            <CardBody className="bg-white ">
                                <h3 className='page-title bb-1 border-gray mb-4'>Banned Email</h3>
                                <Row className="mt-12">
                                    <Col md={12} className="text-right">
                                            <Button color="primary" href="/banned-emails" >List</Button>
                                    </Col>
                                </Row>
                                <Row className="mt-3 bb-1 border-gray ">
                                    <Col md={12} sm={12} className="mb-2 bb-1 border-gray">
                                        <Form  onSubmit ={this.onSubmitHandler}>
                                                <Row form className="mb-3">
                                                    <Col md={{'offset': 0 , 'size' : 6}} className="text-right">
                                                        <FormGroup>
                                                            <Label>Email</Label>
                                                            <Input type="text" onChange={this.onChangeHandler} name="email" value={email}  />
                                                        </FormGroup>

                                                    </Col>
                                                    <Col md={6} sm={6} className="text-right">
                                                        <Button color="primary" type="submit" >Add</Button>
                                                    </Col>
                                                </Row>
                                            </Form>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card> */}
                        <Card>
                            <CardBody className="bg-white">
                                <h3 className="page-title bb-1 border-gray mb-4">Banned Email</h3>
                                <Row className="mt-12">
                                    <Col md={6}>
                                        <Form onSubmit={this.onSubmitHandler}>
                                        <Row form className="mb-3">
                                            <Col md={8}>
                                                <FormGroup>
                                                    <Label>Email</Label>
                                                    <Input
                                                    type="text"
                                                    onChange={this.onChangeHandler}
                                                    name="email"
                                                    value={email}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md={4}>
                                                <Button className="mt-4" color="primary" type="submit">
                                                    Add
                                                </Button>
                                            </Col>
                                        </Row>
                                        </Form>
                                    </Col>
                                    <Col md={6} className="text-right">
                                        <Button className="mt-4" color="primary" href="/banned-emails">
                                        List
                                        </Button>
                                    </Col>
                                </Row>
                            </CardBody>
                    </Card>
                    </Col>
                </Row>
            </Container>            
        )
    }
}