import React from "react";
import { Modal, ModalHeader, ModalBody, CustomInput } from "reactstrap";

const UserInfoAlert = (props) => {
  const {
    toggle,
    toggleData,
    modal,
    id,
    email,
    isBlocked,
    isHost,
    isSubscribed,
    isActive,
    isSearchable,
    autoFocus,
    isTester,
  } = props;

  console.log("CHECK PROPS.EMAIL", props);

  const customInputSwitched = (buttonName, e) => {
    console.log(`we received ${e.target.checked} for ${buttonName}`);
    console.log("customInputSwitched E is: ", e.target);
  };

  return (
    <div style={{ textAlign: "center" }}>
      <Modal
        isOpen={modal}
        autoFocus={autoFocus}
        fade={true}
        toggle={toggle}
        keyboard={false}
        backdrop={false}
      >
        <span>
          <ModalHeader toggle={toggle}>{"User Details"}</ModalHeader>
        </span>
        <ModalBody>
          <span>
            <p> Is Blocked : </p>{" "}
            <CustomInput
              type="switch"
              id={Math.random() * 10000}
              name={"isBlocked"}
              data-id={id}
              data-email={email}
              onChange={toggleData}
              checked={isBlocked}
            />
          </span>
          <span>
            <p> Is Host : </p>{" "}
            <CustomInput
              type="switch"
              id={Math.random() * 10000}
              name={"isHost"}
              data-id={id}
              data-email={email}
              onChange={(e) => toggleData(e)}
              checked={isHost}
            />
          </span>
          <p>
            <i>
              <strong>{`Is Subscribed :- ${
                isSubscribed ? "Yes" : "No"
              }`}</strong>
            </i>
          </p>
          <p>
            <i>
              <strong>{`Is Active :- ${isActive ? "Yes" : "No"}`}</strong>
            </i>
          </p>
          <p>
            <i>
              <strong>{`Is Searchable :- ${
                isSearchable ? "Yes" : "No"
              }`}</strong>
            </i>
          </p>
          <span>
            <p> Is Tester : </p>{" "}
            <CustomInput
              type="switch"
              id={Math.random() * 10000}
              name={"isTester"}
              data-id={id}
              data-email={email}
              onChange={(e) => {
                // customInputSwitched("isTester", e);
                console.log("MYSTERY NEARLY SOLVED: ", e.target);
                toggleData(e);
              }}
              checked={isTester}
            />
          </span>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default UserInfoAlert;
