import React from 'react';
import { Container, Row, Col, Card, CardBody, Form, Button, Label, Input, CustomInput } from 'reactstrap';
import { phoneNumberValidator, countryCodeValidator } from './../../helpers/validationHelpers';
import notificaiton from './../../helpers/notificationHelper';

export default class AddNewAdmin extends React.Component{
    state = {
        firstname : '',
        lastname :'',
        username : '',
        email:'',
        password :'',
        phone : '',
        country_code : '',
        permissions : [],
        role : ""
    }

    componentDidUpdate(prevProps){
        //console.log('this.props', this.props);
        let {admin} = this.props
        if(admin !== prevProps.admin){
            if(admin.err && !admin.isCreated) return notificaiton('error', admin.message ) 
            if(!admin.err && admin.isCreated) {
                notificaiton('success', admin.message )
                return this.props.history.push('/admin/users');
            } 
        }
    }

    onChangeHandler = (e) => {
        let { name, value } = e.target;
        // console.log('name', e.target.name)
        // console.log('value', countryCodeValidator(value));
        if(name === 'phone' && (!phoneNumberValidator(value) || value.length > 12) ) return
        if(name === 'country_code' && !countryCodeValidator(value)) return
        this.setState({
            [name] : value
        })
    }

    onSubmitHandler = (e) => {
        //console.log("submitted");
        e.preventDefault();
        let { firstname, lastname, email, username,  phone, password, country_code, permissions, role } = this.state;
        
        let data = {
            firstname, 
            lastname, 
            email, 
            username,
            phone, 
            password, 
            country_code,
            permissions,
            role
        }
        if( !countryCodeValidator(country_code)) return notificaiton('error', 'Country Code should contain +')
        //if( permissions.length === 0) return notificaiton('error', 'Please Select Atleast One Permission(s)')
        debugger;
        this.props.addNewAdmin(data);
    }

    onAddPermissions = (permission) => {
        let { permissions } = this.state;
        let checkIndex = permissions.indexOf(permission);
        //console.log('checkIndex', checkIndex)
        if( checkIndex > -1) permissions.splice(checkIndex, 1)
        else permissions.push(permission);
        return this.setState({
            permissions
        });
    }

    render(){
        let { username, firstname, lastname, email, password, phone, country_code, permissions, role } = this.state;
        //console.log('permissions', permissions);
        return(
            <Container  >
                <Row>
                    <Col md={12}>
                        <Card>
                            <CardBody className="bg-white ">
                                <h3 className='page-title bb-1 border-gray mb-4'>Add New Admin</h3>
                                <Row className="mt-12">
                                    <Col md={12} className="text-right">
                                            <Button color="primary" href="/admin/users" >List</Button>
                                    </Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col md={12}>
                                        <Form onSubmit ={this.onSubmitHandler}>
                                            
                                            <Row form className="mb-3">
                                                <Col md={6} sm={12}>
                                                <input type="hidden" value="prayer" />
                                                    <Label className="text-muted">Firstname:</Label>
                                                    <Input type="text" name="firstname" value={firstname} 
                                                        onChange={ this.onChangeHandler } required 
                                                        placeholder = "Enter Firstname" 
                                                    />
                                                </Col>
                                                <Col md={6} sm={12}>
                                                    <Label className="text-muted">Lastname:</Label>
                                                    <Input type="text" name="lastname" value ={lastname}
                                                        onChange={ this.onChangeHandler }
                                                        placeholder = "Enter Lastname"
                                                    />
                                                </Col>
                                                
                                            </Row>
                                            <Row form  className="mb-3">
                                                <Col md={6} sm={12}>
                                                    <Label className="text-muted">Username:</Label>
                                                    <Input type="text" name="username" value ={username}
                                                        onChange={ this.onChangeHandler } required
                                                        placeholder = "Enter Username" autoComplete="false"
                                                    />
                                                </Col>
                                                <Col md={2} sm={12}>
                                                    <Label className="text-muted">Country Code:</Label>
                                                    <Input type="text" name="country_code" value ={country_code}
                                                        onChange={ this.onChangeHandler }
                                                        placeholder = "Enter Country Code" 
                                                    />
                                                </Col>
                                                <Col md={4} sm={12}>
                                                    <Label className="text-muted">Phone:</Label>
                                                    <Input type="tel" name="phone" value ={phone}
                                                        onChange={ this.onChangeHandler }
                                                        placeholder = "Enter Phone" 
                                                    />
                                                </Col>
                                            </Row>
                                            <Row form className="mb-3">
                                                <Col md={6} sm={12}>
                                                    <Label className="text-muted">Email:</Label>
                                                    <Input type="email" name="email" value ={email}
                                                        onChange={ this.onChangeHandler } autoComplete='off' required
                                                        placeholder = "Enter Email" 
                                                    />
                                                </Col>
                                                <Col md={6} sm={12}>
                                                    <Label className="text-muted">Password:</Label>
                                                    <Input type="password" name="password" value ={password}
                                                        onChange={ this.onChangeHandler } autoComplete='new-password' required
                                                        placeholder = "Enter Password" 
                                                    />
                                                </Col>
                                                
                                            </Row>
                                            {
                                                <Row form className="mb-3">
                                                     <Col md={12}>
                                                        <Label className="text-muted">Role:</Label>
                                                        <Input type="select" name="role" value={role} id="typeselec1t" onChange={this.onChangeHandler}>
                                                        <option value="" >--select day-</option>
                                                        <option value="superadmin" >Super Admin</option>
                                                        <option value="admin" >Admin</option>
                                                        <option value="view-only" >View Only</option>
                                                    </Input>
                                                    </Col>
                                                </Row>
                                            }
                                            {/* <Row form className="mb-3">
                                                    <Col md={12}>
                                                        <Label className="text-muted">Permissions:</Label>
                                                    </Col>
                                                    { ADMIN_PERMISSIONS.map((permission, i) => {
                                                        return (
                                                            <Col md={3} sm={12} key={i}>
                                                                <CustomInput className="mb-3" type="checkbox" id={i} onClick={ () => { this.onAddPermissions(permission.id) } } label={permission.value} />
                                                            </Col>
                                                        )
                                                    }) }
                                                
                                            </Row> */}
                                            <Row className="text-right">
                                                <Col md={12}  sm={12}>
                                                    <Button color="primary" type="submit">Add Admin</Button>
                                                </Col>
                                                
                                            </Row>
                                        </Form>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        )
    }
}