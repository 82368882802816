import React, { useState, useEffect, useRef } from 'react';
import { Container, Row, Col, Card, CardBody, Button, Form, FormGroup, Label, Input,CustomInput } from 'reactstrap';
import PenIcon from 'mdi-react/PencilOutlineIcon';
import DeleteIcon from 'mdi-react/CancelIcon';
import TableComponent from './../Shared/Table/Table';
import TablePagination from './../Shared/Table/TablePagination';
import notification from '../../helpers/notificationHelper';
import CSVParser from "./../Shared/CSVParser";
import { SPOKEN_LANGUAG_ARRAY } from './../../inc/constants';

const AddCategoryModal = React.lazy(()=> import('./AddCategoryModal') );
const DeleteContentModal = React.lazy(()=> import('./DeleteContentModal') );
const EditContentModel = React.lazy(()=> import('./EditContentModal'))

const AllCategories = (props) => {
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(20);
  const [selected_lang, setSelectedLang] = useState("en");
  const [searchkey, setSearchkey] = useState("");
  const [total, setTotal] = useState(0);
  const [content, setContent] = useState([]);
  const [contentId, setContentId] = useState("");
  const [isFetched, setFetched] = useState(false);
  const [modalAdd, setModalAdd] = useState(false);
  const [modalEdit, setModalEdit] = useState(false);
  const [modalDelete, setModalDelete] = useState(false);
  const [tag, setTag] = useState("")
  const [language, setLanguage] = useState('')
  const [contentAdded, setContentAdded] = useState("");
  const [contentUpdated, setContentUpdated] = useState("");
  const [contentDeleted, setContentDeleted] = useState("");

  useEffect(() => {
    //console.log("page,selected_lang,searchkey", page,selected_lang,searchkey)
    props._fetchContent(page, limit, selected_lang, searchkey);
  }, [page,selected_lang,searchkey])

  useEffect(()=>{
    if(!props.categories.isFetching && props.categories.isFetched && props.categories.data !== content ){
        setContent(props.categories.categories);
        setFetched(props.categories.isFetched);
        setTotal(props.categories.total)
    }
  },[props.categories]) 

  useEffect(()=>{
    if(contentAdded && props.categoryAdd && props.categoryAdd.catCreated && !props.categoryAdd.catCreateError && !props.categoryAdd.creating)
    {
        toggleAddModal();
        props._fetchContent(page, limit, selected_lang, searchkey);
        setTag('')
        setLanguage('')
        notification('success',"Content Added")
    }

    if(contentAdded && props.categoryAdd && !props.categoryAdd.catCreated && props.categoryAdd.catCreateError)
    {
        notification('error',props.categoryAdd.msg)
    }
  },[props.categoryAdd])

  useEffect(()=>{
    if(contentDeleted && props.deleteContent.message && props.deleteContent.isFetched && !props.deleteContent.isFetching && !props.deleteContent.err)
    {
        toggleDeleteModal();
        props._fetchContent(page, limit, selected_lang, searchkey);
        notification('success',props.deleteContent.message)
    }

    if(contentDeleted && props.deleteContent.message && !props.deleteContent.isFetched && props.deleteContent.isFetching && props.deleteContent.err)
    {
        notification('error',props.deleteContent.message)
    }
  },[props.deleteContent])

  useEffect(()=>{
    if(contentUpdated && props.updateContent.isFetched && !props.updateContent.isFetching && !props.updateContent.err)
    {
        toggleEditModal();
        props._fetchContent(page, limit, selected_lang, searchkey);
        notification('success',"Content Updated")
    }

    if(contentUpdated && !props.updateContent.isFetched && props.updateContent.isFetching && props.updateContent.err)
    {
        notification('error',props.updateContent.message)
    }
  },[props.updateContent])
  
  const renderIndex=(record) => {
    let index = record.rowIndex + 1 ;
    if(record.data.page > 1){
        index  = (record.data.page - 1)*limit + record.rowIndex + 1
    }
    return index;
  }

  const renderLanguage = (record) => {
    if(record.data.language === 'en'){
        return 'English'
    }else if(record.data.language === 'fr'){
        return 'French'
    }else{
        return 'Spanish'
    }
  } 

  const renderAction = (record) => {
    let buttons = <span>
      <PenIcon className="cursor-pointer ml-2" size={18} onClick={()=>{ editData(record.data) } } />
      <DeleteIcon className="cursor-pointer ml-2" size={18} onClick={()=>{ deleteData(record.data) }} />
    </span>;
    return buttons;
  }

  const editData = (data) => {
    let { _id, tag, language } = data;
    setContentId(_id)
    setTag(tag)
    setLanguage(language)
    return setModalEdit(true)
  }

  const deleteData = (data) => {
    let { _id } = data;
    setContentId(_id)
    return setModalDelete(true)
  }

  const headsAg = [
    {headerName: "Sr. No", field: "i", width:"80", sortable:true, filter:'text', 
        cellRendererFramework : renderIndex 
    },
    {headerName: "Tag", field: "tag", suppressSizeToFit: true, sortable:true, filter:'text' },
    {headerName: "Language", field: "language",cellRendererFramework : renderLanguage, suppressSizeToFit: true, sortable:true, filter:'text',  },
    {headerName: "Actions", 
    cellRendererFramework : renderAction,
      cellStyle : (params) =>{
        return {textAlign :  'center'}
      }
    },
  ]

  const toggle = () => {
    setModalAdd(true)
  }

  const toggleAddModal = () => {
    return setModalAdd(!modalAdd)
  }

  const toggleEditModal = () => {
    return setModalEdit(!modalEdit)
  }

  const toggleDeleteModal = () => {
    return setModalDelete(!modalDelete)
}

  const  onPageChange = (pagenum) => {
    setPage(pagenum);
  }

  const onSearchHandler = (value) => {
    setSearchkey(value)
  }

  const onLangChangeHandler = (value) => {
    setSelectedLang(value)
  }

  const onChangeHandler = (name, value) => {
    if(name === 'tag') setTag(value)
    if(name === 'language') setLanguage(value)
  }

  const onSubmithandler = (e) => {
    e.preventDefault();
    if(!tag) return notification('error','Tag is Mandatory')
    if(!language && language == '') return notification('error','Language is Mandatory')
    setContentAdded("add")
    return props._addContent({
      tag,
      language
    })
  }

  const onUpdateHandler = (e) => {
    e.preventDefault();
    if(!tag) return notification('error','Tag is Mandatory')
    if(!language && language == '') return notification('error','Language is Mandatory')
    setContentUpdated("updated")
    return props._editContent({
      tag,
      language
    },contentId)
  }

  const confirmAction = (e) => {
    e.preventDefault();
    setContentDeleted("deleted")
    return props._deleteContent(contentId);
  }

  return(
    <Container>
                <Row>
                    <Col md={12} >
                        <Card>
                            <CardBody>
                                <h3 className='page-title bb-1 border-gray pb-2'>Filtered List</h3>
                                {/*<Row className="mt-3">
                                  <Col md={12}>
                                    <Form onSubmit={this.onSubmitHandler}>
                                      <Row form className="mb-3">
                                        <Col md={12} sm={12} className="text-center">*}
                                          {/* <Label className="text-muted">Enter title:</Label> */}
                                          {/*<CSVParser handleUpload={this.handleUpload} />
                                        </Col>
                                        <Col md={12} sm={12} className="text-center">
                                          {/* <Label className="text-muted">Enter title:</Label> */}
                                          {/*<Button
                                            color="primary"
                                            disabled={isFetching ? true : false}
                                            type="button"
                                            onClick={this.onSubmitHandler}
                                          >
                                            {isFetching ? "Saving..." : "Submit"}
                                          </Button>
                                        </Col>
                                      </Row>
                                    </Form>
                                  </Col>
                                </Row>*/}
                                <Row className="mt-2">
                                  <Col md={12} className="text-right">
                                      <Button color="primary" onClick={toggle}>Add +</Button>
                                  </Col>
                                  <Col md={3} className="mb-4"  >
                                      <Input type="select" name="selected_lang" onChange={ (e) => { onLangChangeHandler(e.target.value) } } value={selected_lang} className="sm">
                                        {( SPOKEN_LANGUAG_ARRAY.map((lang) => {
                                              // console.log('lang', languages);
                                              return(
                                                  <option value={lang.value} >{lang.name}</option>                                            
                                              )
                                            })
                                          )}
                                      </Input>
                                    </Col>
                                    <Col md={3} className="mb-4"  >
                                      <Input type="text" name="searchkey" onChange={ (e) => { onSearchHandler(e.target.value) } } value={searchkey} className="sm" />
                                    </Col>
                                    <Col md={12} style={{ height: '100vh', width: '600px' }} className="ag-theme-material">
                                        <TableComponent 
                                              rowsData={ content } 
                                              headsAg={headsAg}
                                              rowSelectable={'multiple'} 
                                              rowsPerPage={15} 
                                              isFetched ={isFetched}
                                              fetchingRecordsText = {'Fetching List...'}
                                              noRecordsText = {'No Content Found'}
                                              {...props} 
                                          />
                                    </Col>
                                    <Col md={12} className="text-right mt-2">
                                        <TablePagination onPageChange={onPageChange}  page={page} limit={limit} total={total}/>
                                    </Col>
                                    <React.Suspense fallback={''}>
                                    <AddCategoryModal 
                                      modal={modalAdd} 
                                      toggle={ toggleAddModal }
                                      modalTitle={ 'Add Content' }
                                      onChangeHandler = {onChangeHandler}
                                      onSubmithandler = {onSubmithandler}
                                    />
                                    <EditContentModel 
                                      modal={modalEdit} 
                                      toggle={ toggleEditModal }
                                      modalTitle={ 'Edit Content' }
                                      onUpdateHandler = {onUpdateHandler}
                                      onChangeHandler = {onChangeHandler}
                                      tag = {tag}
                                      language = {language}
                                    />
                                      <DeleteContentModal 
                                        modal={modalDelete} 
                                        toggle={toggleDeleteModal }
                                        modalTitle={ 'Delete Content ' }
                                        confirmAction = {confirmAction}
                                        CustomClass = { 'test_class'}
                                        modalText = {'Are you sure , you want to delete this content?'}
                                    /> 
                                    </React.Suspense>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
  )
}

export default AllCategories