import { REMOVEBANNEDREQUEST, REMOVEBANNEDSUCCESS, REMOVEBANNEDERROR } from '../actions/actionTypes'
 
const initialState = {
    requestDone: false,
    isFetched: false,
    isFetching : false,
    err : false,
    message:''
  };
  
  // receiving response sent by action according to type of action
  export default function removeBannedEmailReducer(state = initialState, action) {
    let { payload } = action
    switch (action.type) {
        case REMOVEBANNEDREQUEST:
          return { 
            requestDone: false,
            isFetched: false,
            isFetching : true,
            err : false,
            message:''
           };
          break;
        case REMOVEBANNEDSUCCESS:
        return { 
            requestDone: true,
            isFetched: true,
            isFetching : false,
            err : false,
            message:payload.message.message
        };
        break;
  
        case REMOVEBANNEDERROR:
        return { 
            requestDone: true,
            isFetched: false,
            isFetching : false,
            err : true,
            message: payload.message
        };
        break;
        
      default:
        return state;
    }
  }
