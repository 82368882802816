import { wrappedFetch, wrappedGet } from "./urlFuncs";
import {
  LIST_OBJECTIONABLE_CONTENT_URL,
  UPLOAD_OBJECTIONABLE_CONTENT_URL
} from "./../inc/constants";
import { API_URL } from "./../inc/config";

export const listAllContent = (page, limit, lang, searchkey) => {
  let url = `${API_URL}${LIST_OBJECTIONABLE_CONTENT_URL}?page=${page}&limit=${limit}&lang=${lang}&searchkey=${searchkey}`;
  // if (isActive) url = `${url}&active=true`;
  return wrappedGet(`${url}`, "GET");
};

export const craeteNewContent = (data) => {
  return wrappedFetch(`${API_URL}${UPLOAD_OBJECTIONABLE_CONTENT_URL}`, "POST",  data );
};

export const updateContent = (data,id) => {
  return wrappedFetch(`${API_URL}${UPLOAD_OBJECTIONABLE_CONTENT_URL}${id}`, "PUT",  data );
};

export const deleteContent = (id) => {
  return wrappedFetch(`${API_URL}${UPLOAD_OBJECTIONABLE_CONTENT_URL}${id}`, 'DELETE');
}