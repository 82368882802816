import { wrappedFetch, wrappedGet } from './urlFuncs';
import { POST_PREMIUM_CONTENT_URL, TERMS_AND_CONDITIONS_URL } from './../inc/constants';
import { API_URL } from './../inc/config';

export const fetchContentList = (page, limit, fDate, tDate, lang, dateSort) =>{
return wrappedGet(`${API_URL}${POST_PREMIUM_CONTENT_URL}?page=${page}&limit=${limit}&fDate=${fDate}&tDate=${tDate}&lang=${lang}&dateSort=${dateSort}`, 'GET');
}

export const craetePremiumContent = (content) =>{
  let data = {
    data : content
  }
  return wrappedFetch(`${API_URL}${POST_PREMIUM_CONTENT_URL}`, 'POST',data);
}

export const deletePremiumContentApi = (id) =>{
  return wrappedFetch(`${API_URL}${POST_PREMIUM_CONTENT_URL}/${id}`, 'DELETE');
}

export const updatePremiumContentApi = (id, data) =>{
  return wrappedFetch(`${API_URL}${POST_PREMIUM_CONTENT_URL}/${id}`, 'PUT', data);
}

export const fetchPremiumContentIssuesApi = (page,limit,filter) => {
  return wrappedGet(`${API_URL}${POST_PREMIUM_CONTENT_URL}/issue-log?page=${page}&limit=${limit}&filter=${filter}`, 'GET')
}


export const updateTermsAndConditions = (data) =>{
  return wrappedFetch(`${API_URL}${TERMS_AND_CONDITIONS_URL}`, 'POST', data, { 'Content-Type' : 'multipart/form-data' });
}


export const fetchTConditions = (data) =>{
  return wrappedGet(`${API_URL}${TERMS_AND_CONDITIONS_URL}/list`, 'GET');
}


