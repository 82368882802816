import React from 'react';
import { Card, CardBody, Container, Row, Col, Label, Input, Form, Button, FormGroup, CustomInput } from 'reactstrap';
import UsersModal from './../Shared/UsersModal';
import notify from '../../helpers/notificationHelper';
import ListUsersModal from './ListUsersModal';

export default class Notifications extends React.Component {

    state = {
        title : '',
        content : '',
        date : '',
        time : '',
        users : [],
        selected : 0,
        selectedUsers :[],
        modal : false,
        type : 'immediate',
        language : 'en',
        user_type : 'active',
        fetechingUsers : false,
        total : 0,
        selectedUsersDetails : [],
        modalUsers : false
    }
    componentDidMount(){
        this.props.fetchUsers(1, 10);
    }

    static getDerivedStateFromProps(props, state){
        // console.log('props derived states', props);
        // console.log('state derived states', state);
        if(props.users.users && state.users !== props.users.users && !props.users.isFetching){
            return {
                users : props.users.users,
                fetchUsers : true,
                total : props.users.total
            }
        }
        if(props.users.users && props.users.isFetching){
            return {
                fetchUsers : false
            }
        }
        return null
    }

    onClickHandler = (name, value) => {
        let data = {
            user_type : value
        }
        if(value !== 'custom') data.selectedUsers = [];
        this.setState(data)
    }

    toggle = () => {
        this.setState((prevState) => {
            let data = {
                modal : !prevState.modal
            } 
            if(prevState.modal === false) data.selectedRows = this.state.selectedUsers
            return data 
        })
    }
    toggleUserModal = () => {
        //console.log('users mdoal called');
        this.setState((prevState) => {
            return {
                modalUsers : !prevState.modalUsers
            }
        })
    }

    onSubmitHandler = (e) => {
        e.preventDefault();
        let { title, message, date, time, selected, 
            users, selectedUsers, type, language,
            user_type
         } = this.state
        // const dateM = moment(date).utc().format();
        // console.log('dateM', )
        let data= {
            title, 
            message,
            date,
            time,
            selected,
            selectedUsers,
            type,
            language,
            users : selectedUsers,
            user_type

        }
        if(data.user_type === 'custom' && data.users.length === 0) return notify('error', 'Please Select Atleast one user!')
        if(type === '' ) return notify('error', 'Please Select type!')
        if(type === 'scheduled' && (date === '' || time === '') ) return notify('error', 'Date and Time could not be empty with a sceduled type notification!')
        //console.log('final notificationsdata ', data);
        this.props._createNotifications(data);
    }


    handleRowSelect = (user, node) => {
        // console.log('user selected', user);
        // console.log('node', node);
        let { selectedUsers, selectedUsersDetails } = this.state;
        let index = selectedUsers.indexOf(user._id.toString());
        let indexDetails = selectedUsersDetails.indexOf(user.displayname);
        let deletion = false;
        if(index > -1 && !node.selected) {
            selectedUsers.splice(index, 1);
            deletion = true
        }else if(node.selected && index === -1){
            selectedUsers.push(user._id.toString());
        }
        if(indexDetails > -1 && !node.selected) {
            selectedUsersDetails.splice(indexDetails, 1)
        }else if(node.selected && indexDetails === -1){
            selectedUsersDetails.push(user.displayname);
        }
        // console.log('selectedUsers', selectedUsers);
        // console.log('selectedUsers', selectedUsers);
        this.setState({
            selectedUsers,
            selectedUsersDetails,
            selectedRows : selectedUsers
        },()=>{
            // if(deletion) return node.setSelected(false);
            return;
        })
    }


    handleClose = () => {
        this.setState({
            selectedUsers : [],
            selectedUsersDetails : [],
            modal : false
        })
    }

    onChangeHandler = (name, value) => {
        //console.log('name, value', name, value);
        this.setState({
            [name] : value
        })
    }

    componentDidUpdate(prevProps, nextProps){
        let { notifications } = this.props;
        //console.log('notifications',notifications)
        if(notifications !== prevProps.notifications){

            if( !notifications.isFetching && notifications.isFetched && !notifications.error){
                notify('success', notifications.message);
                return this.props.history.push('/notifications')
            }
            if( !notifications.isFetching && !notifications.isFetched && notifications.error){
                notify('error', notifications.message);
            }

        }
        
    }

    render(){
        let { selected, modal, users, selectedUsers, 
            type, language, user_type, title, message, 
            fetchUsers, total, selectedUsersDetails, modalUsers, selectedRows, date, time
        } = this.state;
        let { notifications : { isFetching } } = this.props;
        // console.log('selectedUsers', selectedUsers)
        // console.log('selectedUsersDetails render', selectedUsersDetails)
        return (
            <Container  >
                <Row>
                    <Col md={12}>
                        <Card>
                            <CardBody className="bg-white ">
                                <h3 className='page-title bb-1 border-gray mb-4'>Notifications</h3>
                                <Row className="mt-12">
                                    <Col md={12} className="text-right">
                                            <Button color="primary" href="/notifications" >Back</Button>
                                    </Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col md={12}>
                                        <Form onSubmit ={this.onSubmitHandler}>
                                                <Row form className="mb-3">
                                                    <Col md={12} sm={12}>
                                                        <Label className="text-muted">Enter title:</Label>
                                                        <Input type="text" name="title" 
                                                            onChange = { (e) => { this.onChangeHandler( e.target.name, e.target.value  )}}
                                                            required 
                                                            value = {title}
                                                            disabled = {isFetching}
                                                            placeholder = "Enter Title" 
                                                            />
                                                    </Col>
                                                    <Col md={12} sm={12} className="mt-3">
                                                        <Label className="text-muted">Enter Message:</Label>
                                                        <Input type="textarea" name="message"  required 
                                                            placeholder = "Enter Message" 
                                                            value = {message}
                                                            disabled = {isFetching}
                                                            onChange = { (e) => { this.onChangeHandler( e.target.name, e.target.value  )} } 
                                                        />
                                                    </Col>
                                                    {/* <Col md={6} sm={12} className="mt-3">
                                                        <Label className="text-muted">Type:</Label>
                                                        <Input type="date" name="content"  required 
                                                            placeholder = "Enter Content" 
                                                        />
                                                    </Col> */}
                                                    <Col md={6} sm={12} className="mt-3">
                                                        <Label className="text-muted">Type:</Label>
                                                        <Input type="select" name="type" value={type}  required 
                                                            onChange = { (e) => { this.onChangeHandler( e.target.name, e.target.value  ) } }
                                                            disabled = {isFetching}
                                                            value = {type}
                                                            placeholder = "Enter Content" >
                                                            <option value="">Select Type</option>
                                                            <option value="immediate">Immediate</option>
                                                            <option value="scheduled">Scheduled</option>
                                                        </Input>
                                                    </Col>
                                                    <Col md={12} sm={12} className="mt-3">
                                                        <Label className="text-muted">Language:</Label>
                                                        <Input type="select" name="language" value={language}  required 
                                                            onChange = { (e) => { this.onChangeHandler( e.target.name, e.target.value  ) }}
                                                            disabled = {isFetching}
                                                            value = {language}
                                                            placeholder = "Select Language" >
                                                            <option value="en">English</option>
                                                            <option value="es">Spanish</option>
                                                            <option value="fr">French</option>
                                                        </Input>
                                                    </Col>
                                                    <Col md={6} sm={12} className="mt-3">
                                                        <Label className="text-muted">Enter Date:</Label>
                                                        <Input type="date" name="date" value={date} disabled={ type === 'immediate' } onChange ={ (e) => { this.onChangeHandler( e.target.name, e.target.value  )}} />
                                                    </Col>
                                                    <Col md={6} sm={12} className="mt-3">
                                                        <Label className="text-muted">Enter Time ( UTC ):</Label>
                                                        <Input type="time" name="time"  value={time} disabled={ type === 'immediate' } onChange ={ (e) => { this.onChangeHandler( e.target.name, e.target.value  )}}/>
                                                    </Col>
                                                    <Col md={4} sm={12} className="mt-3">
                                                        <FormGroup check>
                                                            <Label check onClick={()=>{ this.onClickHandler('user_type', 'active') }}>
                                                                <CustomInput type="radio" name="user_type" label="All Users" checked={user_type  === 'active'} />{' '}
                                                                
                                                            </Label>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md={4} sm={12} className="mt-3">
                                                        <FormGroup check>
                                                            <Label check onClick={()=>{ this.onClickHandler('user_type', 'subscribed') }}>
                                                                <CustomInput type="radio" name="user_type" label="All Subscribed Users" checked={user_type  === 'subscribed'}  />{' '}
                                                            </Label>
                                                        </FormGroup >
                                                    </Col>
                                                    <Col md={4} sm={12} className="mt-3">
                                                        <FormGroup check>
                                                            <Label check onClick={()=>{ this.onClickHandler('user_type', 'custom') }}>
                                                                <CustomInput type="radio" name="user_type" label="Select users" checked={user_type === 'custom'}  />{' '}
                                                            </Label>
                                                            {user_type === 'custom' && (
                                                                <>
                                                                    <Button className="ml-2"  color="primary" onClick={ () => { this.toggle() } }>Select</Button>
                                                                    <UsersModal 
                                                                        modal ={modal} 
                                                                        data ={users} 
                                                                        {...this.props} 
                                                                        total = {total}
                                                                        modalTitle = {'Select Users'}
                                                                        confirmAction={this.toggle}
                                                                        isFetched = {fetchUsers}
                                                                        handleClose = {this.handleClose}
                                                                        handleRowSelect={ this.handleRowSelect }
                                                                        selectedRows = {selectedRows}
                                                                    />
                                                               
                                                                    
                                                                </>
                                                            )}
                                              `          </FormGroup >
                                                    </Col>
                                                    <Col md={12} sm={12} className="mt-3">
                                                        <FormGroup check>
                                                            <Button color="primary" type="submit" disabled = {isFetching} className="mt-3 text-right">Submit</Button>                                                                                                                
                                                            <Button color="primary" type="button" disabled = {isFetching} onClick= {() => { this.toggleUserModal() }} className="mt-3 text-right">Selected Users</Button>                                                                                                                
                                              `          </FormGroup >
                                                    </Col>
                                                    <Col>
                                                        <ListUsersModal 
                                                            modal ={modalUsers} 
                                                            data ={selectedUsersDetails} 
                                                            {...this.props} 
                                                            total = {total}
                                                            modalTitle = {'Selected Users'}
                                                            confirmAction={this.toggleUserModal}
                                                            isFetched = {fetchUsers}
                                                            handleClose = {this.toggleUserModal}
                                                        />
                                                    </Col>
                                                </Row>
                                            </Form>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>            
        )
    }
}