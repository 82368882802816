import { wrappedFetch, wrappedGet } from './urlFuncs';
import { FEELINGS_URL, FAQ_API_URL } from './../inc/constants';
import { API_URL } from './../inc/config';


export const craeteNewFaq = (data) =>{
  return wrappedFetch(`${API_URL}${FAQ_API_URL}`, 'POST',data);
}

export const updateFaq = (data) =>{
  return wrappedFetch(`${API_URL}${FAQ_API_URL}`, 'PUT',data);
}

//get allFAQ list
export const getAllFaq = (page, limit) =>{
  return wrappedGet(`${API_URL}${FAQ_API_URL}-admin?page=${page}&limit=${limit}`, 'GET');
}

//get faw details from id list
export const getFaqDetails = (id) =>{
    return wrappedGet(`${API_URL}${FAQ_API_URL}/${id}`, 'GET');
}

export const deleteFaqApi = (id) =>{
  return wrappedFetch(`${API_URL}${FAQ_API_URL}/${id}`, 'DELETE');
}

//get FAQ list for a language
export const getAllLangFaq = (lang) =>{
  return wrappedGet(`${API_URL}${FAQ_API_URL}-admin?lang=${lang}`, 'GET');
}

export const updateFaqSeq = (data) =>{
  return wrappedFetch(`${API_URL}${FAQ_API_URL}/sequence`, 'PUT',{faq : data});
}