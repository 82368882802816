import { connect } from "react-redux";
import { addAdmin } from '../../redux/actions/AdminActions';
import { withRouter } from 'react-router-dom';
import AddeNewAdmin from './../../components/Admin/AddeNewAdmin';
export const mapStateToProps = state => {
  return {
    admin : state.addAdmin 
  };
};

export const mapDispatchToProps = (dispatch) => {
  return {
    addNewAdmin: (data) => {
      return dispatch(addAdmin(data));
    },
  };
};

const AddeNewAdminContainer = connect(mapStateToProps, mapDispatchToProps)(
  AddeNewAdmin
);

export default withRouter(AddeNewAdminContainer);
