import { connect } from "react-redux";
import ListSubscribedContent from '../../components/SubscribedContent/ListSubscribedContent';
import { postPremiumContent, fetchPremiumContent, deletePremiumContent, updatePremiumContent } from '../../redux/actions/premiumContentActiions';
 
const mapStateToProps = state => {
  //console.log('state in container', state);
  return {
    premiumContentList : state.premiumContentList,
    deleteContent : state.deleteContent,
    updateContent : state.updateContent 
  };
};
 
const mapDispatchToProps = (dispatch) => {
  return {
    fetchContent: (page, skip, fDate, tDate, lang, dateSort) => {
      return dispatch(fetchPremiumContent(page, skip, fDate, tDate, lang, dateSort));
    },
    _postPremiumContent : (data) => {
      return dispatch(postPremiumContent(data));
    },
    _deleteContent : (id) => {
      return dispatch(deletePremiumContent(id))
    },
    _updateContent : (id, data) => {
      return dispatch(updatePremiumContent(id, data));
    },
  };
};
 
const ListSubscribedContentContainer = connect(mapStateToProps, mapDispatchToProps)(
    ListSubscribedContent
);
 
export default ListSubscribedContentContainer;