import React, { useState } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

const ModalExample = (props) => {
  const {
    buttonLabel,
    className,
    toggle,
    modal,
    func,
    confirmAction,
    isBlocked,
    modalTitle,
    modalText,
    modalhtmlFor,
  } = props;

  let blockText = isBlocked == false ? "Unblock" : "Block";

  return (
    <div style={{ textAlign: "center" }}>
      {/* <Button color="danger" onClick={toggle}>{buttonLabel}</Button> */}
      <Modal isOpen={modal} fade={false} toggle={toggle}>
        <span>
          <ModalHeader toggle={toggle}>{modalTitle}</ModalHeader>
        </span>
        <ModalBody>{modalText}</ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            name={modalhtmlFor}
            onClick={(e) => {
              // console.log("YES MEANS: ", e.target);
              console.log("confirmAction should return these:", props);
              // const event = new CustomEvent("zohebsGenius", {
              //   target: modalFor,
              // });
              const couldThisWork = <div name={modalhtmlFor} />;
              // confirmAction(couldThisWork);
              confirmAction({ name: modalhtmlFor });
            }}
          >
            Yes{" "}
          </Button>{" "}
          <Button color="secondary" onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default ModalExample;
