import { wrappedFetch, wrappedGet } from './urlFuncs';
import { NOTIFICATIONS } from './../inc/constants';
import { API_URL, NOTIFICATION_URL } from './../inc/config';

export const fetchNotificationsList = (page, limit) =>{
  return wrappedGet(`${API_URL}${NOTIFICATIONS}?page=${page}&limit=${limit}`, 'GET');
}

export const craeteNotifications = (notiifcationData) =>{
  let data = {
    data : notiifcationData
  }
  return wrappedFetch(`${NOTIFICATION_URL}${NOTIFICATIONS}`, 'POST',data);
}


export const removeNotifications = (id) =>{
  return wrappedFetch(`${API_URL}${NOTIFICATIONS}/${id}`, 'DELETE');
}

export const updateNotifications = (id, data) =>{
  return wrappedFetch(`${API_URL}${NOTIFICATIONS}/${id}`, 'PUT', data);
}