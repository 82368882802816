import React, { Component } from 'react';
import { Route, Redirect } from 'react-router-dom';
import UnAuths from './UnAuths'
class UnAuthRoutes extends Component{
    render(){
      let { checked, isAuthenticated } = this.props
      if(!checked){
        return (
            <div className={`load${checked ? '' : ' loaded'}`}>
                <div className='load__icon-wrap'>
                    <svg className='load__icon'>
                    <path fill='#4ce1b6' d='M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z'/>
                    </svg>
                </div>
            </div>  
        )
      }else{
        //console.log('props check', this.props)
        let pathname = this.props.location.state !== undefined ? (this.props.location.state.from.pathname ? this.props.location.state.from.pathname : '/dashboard') : '/dashbaord'
          return (
          <Route
            {...this.props}
            render={() =>{
              //console.log('inner props', this.props)
              return  !isAuthenticated ? (
                <UnAuths {...this.props} />
              ) : (
                <Redirect
                  to={{
                    pathname: pathname,
                    state: { from: this.props.location }
                  }}
                />
              )
            }
            }
          />
          )
        }
    }
}

export default UnAuthRoutes