import { connect } from "react-redux";
import UserTracking from '../../components/UserTracking/UserTracking';
import { userTrackingList  } from '../../redux/actions/userTrackingActions';

const mapStateToProps = state => {
    return {
        userTrackingList : state.userTrackingList
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        _userTrackingList: (page, skip) => {
            return dispatch(userTrackingList(page, skip));
        },
    }
};

const UserTrackingContainer = connect(mapStateToProps, mapDispatchToProps)(
    UserTracking
);
 
export default UserTrackingContainer;