import React from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Form,
  FormGroup,
  Input,
  Label,
  Button,
} from "reactstrap";
import TableComponent from "./../Shared/Table/Table";
import TablePagination from "./../Shared/Table/TablePagination";
import moment from "moment";
import EyeIcon from "mdi-react/EyeIcon";
import PenIcon from "mdi-react/PencilIcon";
import DeleteIcon from "mdi-react/DeleteIcon";
import MeetingDetailsModal from "./../Shared/MeetingDetailsModal";
import MeetingDurationModal from "../Shared/MeetingDurationModal";
import EditMeetingModal from "./../Shared/EditMeetingModal";
import CommonModal from "./../Shared/CommonModal";
import notification from "../../helpers/notificationHelper";
import {
  DAYS_ARRAY,
  UTS_EST_OFFSET,
  MEETING_TYPES,
  MEETING_TYPE_OBJECT,
} from "./../../inc/constants";
import momentT from "moment";

class ListMeetings extends React.Component {
  state = {
    headsAg: [],
    page: 1,
    limit: 50,
    meetings: [],
    selectedRecord: {},
    isBlocked: false,
    total: 0,
    search: "",
    modalTitle: "",
    modalText: "",
    modal: false,
    editModal: false,
    deleteModal: false,
    durationModal: false,
    isFetched: false,
    hosts: "",
    title: "",
    type: "",
    categoriesMeet: [],
    days: [],
    time: "",
    duration: "",
    host: "",
    description: "",
    hosts: "",
    wsoId: "",
    meetingId: "",
    isUpdating: false,
    isDeleting: false,
    est_time: "",
    spoken_language: "",
    selectedLang: "",
    host_filter: "",
    day_filter: "",
    meet_type: "",
    display_date: "",
    meet_date: "",
    meet_type_e: "",
    test_meet: "",
    realdays: [],
    selected_lang: "en",
    spoken_language_array: [],
    spoken_language_full: [],
    weeklyTimeDurations: [],
    monDuration: "",
    tueDuration: "",
    wedDuration: "",
    thuDuration: "",
    friDuration: "",
    satDuration: "",
    sunDuration: "",
    myArrayData: [],
  };

  componentDidMount() {
    let {
      page,
      limit,
      search,
      selectedLang,
      meet_type,
      host_filter,
      day_filter,
    } = this.state;
    let {
      meetings: { meetings, total },
    } = this.props;
    let { categories } = this.props;
    this.props.fetchMeetings(
      page,
      limit,
      search,
      selectedLang,
      meet_type,
      host_filter,
      day_filter
    );
    this.props._fetchHosts();
    this.props._listLanguages();
    if (categories && categories.length <= 0) this.props._fetchCategories();
    let headsAg = [
      {
        headerName: "Sr.",
        field: "i",
        width: "60",
        sortable: true,
        filter: "text",
        cellRendererFramework: this.renderIndex,
      },
      { headerName: "Title", field: "title", sortable: true, filter: "text" },
      {
        headerName: "Language",
        field: "spokenLanguage",
        filter: "text",
        width: "130",
      },
      {
        headerName: "Days",
        sortable: true,
        filter: "text",
        cellRendererFramework: this.renderESTDays,
      },
      {
        headerName: "Time",
        field: "time",
        sortable: true,
        filter: "text",
        width: "130",
        cellRendererFramework: this.renderESTMeetTime,
      },
      {
        headerName: "Duration",
        sortable: true,
        filter: "text",
        width: "130",
        cellRendererFramework: this.renderTime,
      },
      {
        headerName: "Host",
        sortable: true,
        filter: "text",
        cellRendererFramework: this.renderHost,
      },
      {
        headerName: "Meeting Type",
        cellRendererFramework: this.renderMeetType,
      },
      {
        headerName: "Last Change Date",
        cellRendererFramework: this.renderChangeDate,
      },
      // {headerName: "Days", sortable:true, filter:'text', cellRendererFramework : this.renderDays },
      // {headerName: "Time", field: "time", sortable:true, filter:'text',  width:"130" , cellRendererFramework : this.renderMeetTime},
      // {headerName: "Type", field: "type", sortable:true, filter:'text',  width:"130" },
      // {headerName: "Categories", sortable:true, filter:'text', cellRendererFramework : this.renderCats},
      // {headerName: "Status", field: "status", sortable:true, filter:'text'},

      // {headerName: "Created Date", field: "createdAt", sortable:true,
      //     cellRendererFramework : this.renderDate,
      //     filter:'text'
      // },
    ];
    if (
      localStorage.getItem("UserRole") === "superadmin" ||
      localStorage.getItem("UserRole") === "admin"
    ) {
      headsAg.push({
        headerName: "Actions",
        cellRendererFramework: this.renderAction,
        sortable: true,
        filter: "text",
      });
    }
    this.setState({ headsAg, meetings, total });
  }

  static getDerivedStateFromProps(props, state) {
    //console.log("")
    if (
      props.meetings.meetings &&
      props.meetings.meetings !== state.meetings &&
      !props.meetings.isFetching &&
      props.meetings.isFetched !== state.isFetched
    ) {
      return {
        meetings: props.meetings.meetings,
        total: props.meetings.total,
        page: state.page,
        // isFetched : true
      };
    }
    //console.log('new props meetingss', props)
    if (
      props.createMeeting.isFetching &&
      !props.createMeeting.err &&
      props.createMeeting.isFetching !== state.isUpdating
    ) {
      return {
        isUpdating: true,
      };
    }
    if (
      props.deleteMeeting.isFetching &&
      !props.deleteMeeting.err &&
      props.deleteMeeting.isFetching !== state.isDeleting
    ) {
      return {
        isDeleting: true,
      };
    }
    if (props.hosts && props.hosts.hosts && props.hosts.users !== state.hosts) {
      return {
        hosts: props.hosts.hosts,
      };
    }

    return null;
  }

  componentDidUpdate(prevProps, prevState) {
    let {
      page,
      limit,
      search,
      selectedLang,
      meet_type,
      host_filter,
      day_filter,
    } = this.state;
    let { createMeeting, deleteMeeting } = this.props;
    if (
      selectedLang !== prevState.selectedLang ||
      meet_type !== prevState.meet_type ||
      host_filter !== prevState.host_filter ||
      day_filter !== prevState.day_filter
    ) {
      this.props.fetchMeetings(
        page,
        limit,
        search,
        selectedLang,
        meet_type,
        host_filter,
        day_filter
      );
    }
    if (
      !createMeeting.isFetching &&
      !createMeeting.err &&
      createMeeting.isFetching !== this.state.isUpdating
    ) {
      this.setState(
        {
          isUpdating: false,
        },
        () => {
          notification("success", createMeeting.message);
          this.toggleEditModal();
          this.props.fetchMeetings(
            page,
            limit,
            search,
            selectedLang,
            meet_type
          );
        }
      );
    }
    if (
      !createMeeting.isFetching &&
      createMeeting.err &&
      createMeeting.isFetching !== this.state.isUpdating
    ) {
      this.setState(
        {
          isUpdating: false,
        },
        () => {
          notification("error", createMeeting.message);
        }
      );
    }
     // Check if a meeting was deleted
  if (
    !deleteMeeting.isFetching &&
    !deleteMeeting.err &&
    deleteMeeting.isFetching !== this.state.isDeleting
  ) {
    this.setState(
      {
        isDeleting: false,
      },
      () => {
        notification("success", deleteMeeting.message);
        this.toggleDeleteModal();
        
        // Update the component's state to reflect the change
        this.setState(
          {
            page: 1, // or any appropriate value to reset the page
          },
          () => {
            // Fetch meetings with the updated state
            this.props.fetchMeetings(
              page,
              limit,
              search,
              selectedLang,
              meet_type,
              host_filter,
              day_filter
            );
          }
        );
      }
    );
  }
    if (
      !deleteMeeting.isFetching &&
      deleteMeeting.err &&
      deleteMeeting.isFetching !== this.state.isDeleting
    ) {
      this.setState(
        {
          isDeleting: false,
        },
        () => {
          notification("error", createMeeting.message);
        }
      );
    }
  }

  editRecord = (record) => {
    let id = record.id;
  };

  renderIndex = (record) => {
    let { page, limit } = this.state;
    let index = record.rowIndex + 1;

    if (page > 1) {
      index = (page - 1) * limit + record.rowIndex + 1;
    }
    return index;
  };

  renderChangeDate = (record) => {
    return moment(record.data.updatedAt).format("MM-DD-YYYY");
  };

  renderHost = (record) => {
    //console.log('record', record)
    return record.data.al_host_id && record.data.al_host_id.length > 0
      ? record.data.al_host_id[0].email.mail
      : "N/A";
  };

  renderMeetType = (record) => {
    let { meetType } = record.data;
    if (!meetType) meetType = "Al-Anon";
    else meetType = MEETING_TYPE_OBJECT[record.data.meetType];
    return meetType;
  };

  renderDate = (record) => {
    return moment(record.data.createdAt).format("MM-DD-YYYY");
  };

  renderTime = (record) => {
    //console.log('data', record)
    if (
      (record.data.type === "weekly" || record.data.type === "daily") &&
      record.data.weeklyTimeDurations
    ) {
      let buttons = (
        <span>
          <p
            onClick={() => {
              this.setData(record.data, "durationModal");
            }}
            style={{ color: "blue" }}
          >
            view durations
          </p>
        </span>
      );
      return buttons;
    } else {
      return `${record.data.duration} min`;
    }
  };

  renderMeetTime = (record) => {
    let time = moment(record.data.time, "HH:mm").format("HH:mm");
    return time;
  };

  renderESTMeetTime = (record) => {
    let time = moment(record.data.mTime, "HH:mm").format("HH:mm");
    return time;
  };

  renderPhoneNumber = (record) => {
    let phone = "";
    if (record.data.country_code) {
      phone += record.data.country_code.toString() + "-";
    }
    phone += record.data.phone.number.toString();
    return phone;
  };

  getSelectedRows = () => {
    const selectedNodes = this.gridApi.getSelectedNodes();
    const selectedData = selectedNodes.map((node) => node.data);
    selectedData.map((node) => node.make + " " + node.model).join(", ");
  };

  onPageChange = (page) => {
    let { limit, search, selectedLang, meet_type } = this.state;
    this.setState({ page, limit });
    this.props.fetchMeetings(page, limit, search, selectedLang, meet_type);
  };

  renderAction = (record) => {
    let buttons = (
      <span>
        <EyeIcon
          onClick={() => {
            this.setData(record.data, "modal");
          }}
          size={"18px"}
          className="cursor-pointer"
        />
        <PenIcon
          className="cursor-pointer ml-2"
          size={18}
          onClick={() => {
            this.setData(record.data, "editModal");
          }}
        />
        <DeleteIcon
          className="cursor-pointer ml-2"
          size={18}
          onClick={() => {
            this.setData(record.data, "deleteModal");
          }}
        />
      </span>
    );
    return buttons;
  };

  setData = (record, name) => {
    let {
      title,
      type,
      cats,
      days,
      time,
      duration,
      al_host_id,
      description,
      wsoId,
      zoomMeetingId,
      spokenLanguage,
      spokenLanguageFull,
      meetDisplayDate,
      meetStartDate,
      meetType,
      testMeet,
      realdays,
      weeklyTimeDurations,
      monDuration,
      tueDuration,
      wedDuration,
      thuDuration,
      friDuration,
      satDuration,
      sunDuration,
    } = record;
    const utcOffsetToEST = momentT.tz("America/New_York").utcOffset();
    let est_time = moment(time, "HH:mm")
      .add(utcOffsetToEST, "minutes")
      .format("HH:mm");

    let spokenLangArray = spokenLanguage.split(",");
    let spokenLangFullArray = spokenLanguageFull.split(",");
    let spokenLanguageArray = spokenLangArray.map((ele, index) => {
      return {
        code: ele,
        name: spokenLangFullArray[index],
      };
    });
    if ((type === "weekly" || type === "daily") && weeklyTimeDurations) {
      weeklyTimeDurations.map((val) => {
        switch (val.day) {
          case "monday":
            monDuration = val.duration;
            break;
          case "tuesday":
            tueDuration = val.duration;
            break;
          case "wednesday":
            wedDuration = val.duration;
            break;
          case "thursday":
            thuDuration = val.duration;
            break;
          case "friday":
            friDuration = val.duration;
            break;
          case "saturday":
            satDuration = val.duration;
            break;
          case "sunday":
            sunDuration = val.duration;
            break;
        }
      });
    }
    // data[name] = true
    this.setState({
      [name]: true,
      title,
      type,
      days,
      time,
      duration,
      host: al_host_id,
      description,
      categoriesMeet: cats,
      wsoId,
      meetingId: zoomMeetingId,
      est_time: est_time,
      spoken_language: spokenLanguage,
      display_date: moment(meetDisplayDate).format("YYYY-MM-DD"),
      meet_date: meetStartDate
        ? moment(meetStartDate).format("YYYY-MM-DD")
        : "",
      meet_type_e: meetType,
      test_meet: testMeet,
      realdays: realdays,
      spoken_language_array: spokenLanguageArray,
      spoken_language_full: spokenLanguageFull,
      weeklyTimeDurations,
      monDuration,
      tueDuration,
      wedDuration,
      thuDuration,
      friDuration,
      satDuration,
      sunDuration,
    });
  };

  renderCats = (record) => {
    let { cats } = record.data;
    let categories = (
      <div>
        {cats &&
          cats.length > 0 &&
          cats.map((cat, i) => {
            return (
              <span key={i}>{`${i > 0 ? "," : ""} ${cat.english_title}`}</span>
            );
          })}
      </div>
    );
    return categories;
  };

  renderDays = (record) => {
    let { realdays } = record.data;
    let days = realdays;

    let buttons = "";
    if (days.length > 0) {
      buttons = (
        <div>
          <span
            className={
              days.includes("sunday")
                ? `daysCircle daysHighlight`
                : "daysCircle"
            }
          >
            S
          </span>
          <span
            className={
              days.includes("monday")
                ? `daysCircle daysHighlight`
                : "daysCircle"
            }
          >
            M
          </span>
          <span
            className={
              days.includes("tuesday")
                ? `daysCircle daysHighlight`
                : "daysCircle"
            }
          >
            T
          </span>
          <span
            className={
              days.includes("wednesday")
                ? `daysCircle daysHighlight`
                : "daysCircle"
            }
          >
            W
          </span>
          <span
            className={
              days.includes("thursday")
                ? `daysCircle daysHighlight`
                : "daysCircle"
            }
          >
            T
          </span>
          <span
            className={
              days.includes("friday")
                ? `daysCircle daysHighlight`
                : "daysCircle"
            }
          >
            F
          </span>
          <span
            className={
              days.includes("saturday")
                ? `daysCircle daysHighlight`
                : "daysCircle"
            }
          >
            S
          </span>
        </div>
      );
    }

    return buttons;
  };

  renderESTDays = (record) => {
    let { days, meetStartDate, type, weeklyTimeDurations } = record.data;

    // if (type === "weekly" && weeklyTimeDurations) {
    //     let checkedArray = weeklyTimeDurations.map((val) => {
    //         return val.day;
    //     })
    //     let buttons = '';
    //     if (checkedArray.length > 0) {
    //         buttons = <div>
    //             <span className={checkedArray.includes('sunday') ? `daysCircle daysHighlight` : 'daysCircle'} >S</span>
    //             <span className={checkedArray.includes('monday') ? `daysCircle daysHighlight` : 'daysCircle'} >M</span>
    //             <span className={checkedArray.includes('tuesday') ? `daysCircle daysHighlight` : 'daysCircle'} >T</span>
    //             <span className={checkedArray.includes('wednesday') ? `daysCircle daysHighlight` : 'daysCircle'} >W</span>
    //             <span className={checkedArray.includes('thursday') ? `daysCircle daysHighlight` : 'daysCircle'} >T</span>
    //             <span className={checkedArray.includes('friday') ? `daysCircle daysHighlight` : 'daysCircle'} >F</span>
    //             <span className={checkedArray.includes('saturday') ? `daysCircle daysHighlight` : 'daysCircle'} >S</span>
    //         </div>
    //     }
    //     else {
    //         buttons = <div>
    //             <span className={moment(meetStartDate).format('dddd').toLowerCase() === 'sunday' ? `daysCircle daysHighlight` : 'daysCircle'} >S</span>
    //             <span className={moment(meetStartDate).format('dddd').toLowerCase() === 'monday' ? `daysCircle daysHighlight` : 'daysCircle'} >M</span>
    //             <span className={moment(meetStartDate).format('dddd').toLowerCase() === 'tuesday' ? `daysCircle daysHighlight` : 'daysCircle'} >T</span>
    //             <span className={moment(meetStartDate).format('dddd').toLowerCase() === 'wednesday' ? `daysCircle daysHighlight` : 'daysCircle'} >W</span>
    //             <span className={moment(meetStartDate).format('dddd').toLowerCase() === 'thursday' ? `daysCircle daysHighlight` : 'daysCircle'} >T</span>
    //             <span className={moment(meetStartDate).format('dddd').toLowerCase() === 'friday' ? `daysCircle daysHighlight` : 'daysCircle'} >F</span>
    //             <span className={moment(meetStartDate).format('dddd').toLowerCase() === 'saturday' ? `daysCircle daysHighlight` : 'daysCircle'} >S</span>
    //         </div>

    //     }
    //     return buttons;
    //     } else {
    let buttons = "";
    if (days.length > 0) {
      buttons = (
        <div>
          <span
            className={
              days.includes("sunday")
                ? `daysCircle daysHighlight`
                : "daysCircle"
            }
          >
            S
          </span>
          <span
            className={
              days.includes("monday")
                ? `daysCircle daysHighlight`
                : "daysCircle"
            }
          >
            M
          </span>
          <span
            className={
              days.includes("tuesday")
                ? `daysCircle daysHighlight`
                : "daysCircle"
            }
          >
            T
          </span>
          <span
            className={
              days.includes("wednesday")
                ? `daysCircle daysHighlight`
                : "daysCircle"
            }
          >
            W
          </span>
          <span
            className={
              days.includes("thursday")
                ? `daysCircle daysHighlight`
                : "daysCircle"
            }
          >
            T
          </span>
          <span
            className={
              days.includes("friday")
                ? `daysCircle daysHighlight`
                : "daysCircle"
            }
          >
            F
          </span>
          <span
            className={
              days.includes("saturday")
                ? `daysCircle daysHighlight`
                : "daysCircle"
            }
          >
            S
          </span>
        </div>
      );
    } else {
      buttons = (
        <div>
          <span
            className={
              moment(meetStartDate).format("dddd").toLowerCase() === "sunday"
                ? `daysCircle daysHighlight`
                : "daysCircle"
            }
          >
            S
          </span>
          <span
            className={
              moment(meetStartDate).format("dddd").toLowerCase() === "monday"
                ? `daysCircle daysHighlight`
                : "daysCircle"
            }
          >
            M
          </span>
          <span
            className={
              moment(meetStartDate).format("dddd").toLowerCase() === "tuesday"
                ? `daysCircle daysHighlight`
                : "daysCircle"
            }
          >
            T
          </span>
          <span
            className={
              moment(meetStartDate).format("dddd").toLowerCase() === "wednesday"
                ? `daysCircle daysHighlight`
                : "daysCircle"
            }
          >
            W
          </span>
          <span
            className={
              moment(meetStartDate).format("dddd").toLowerCase() === "thursday"
                ? `daysCircle daysHighlight`
                : "daysCircle"
            }
          >
            T
          </span>
          <span
            className={
              moment(meetStartDate).format("dddd").toLowerCase() === "friday"
                ? `daysCircle daysHighlight`
                : "daysCircle"
            }
          >
            F
          </span>
          <span
            className={
              moment(meetStartDate).format("dddd").toLowerCase() === "saturday"
                ? `daysCircle daysHighlight`
                : "daysCircle"
            }
          >
            S
          </span>
        </div>
      );
    }
    return buttons;
    //}
  };

  onClickHandler = () => {
    //console.log('View Meeting!')
  };

  searchHandler = (e) => {
    let { value } = e.target;
    this.setState({ search: value, feching: true }, () => {
      let { search, page, limit, selectedLang, meet_type } = this.state;
      let searchInfo = {
        searchKey: search,
        page,
        limit,
      };
      //console.log('searchg', search)
      search == ""
        ? this.props.fetchMeetings(page, limit, search, selectedLang, meet_type)
        : this.props.fetchMeetings(
            page,
            limit,
            search,
            selectedLang,
            meet_type
          );
      // this.setState({isFetching:false, search : value}, ()=>{
      // })
    });
  };

  toggleModal = (e) => {
    this.setState((prevState) => {
      return {
        modal: !prevState.modal,
      };
    });
  };

  toggleDurationModal = (e) => {
    this.setState((prevState) => {
      return {
        durationModal: !prevState.durationModal,
      };
    });
  };

  toggleEditModal = (e) => {
    this.setState((prevState) => {
      return {
        editModal: !prevState.editModal,
      };
    });
  };
  toggleDeleteModal = (e) => {
    this.setState((prevState) => {
      return {
        deleteModal: !prevState.deleteModal,
      };
    });
  };

  updateEditModal = (e) => {
    this.setState((prevState) => {
      return {
        modal: !prevState.modal,
      };
    });
  };

  onChangeHandler = (e) => {
    let { name, value } = e.target;
    console.log("nam", name);
    let data = {
      [name]: value,
    };
    if (name === "type" && value === "weekly") {
      data = {
        days: [],
        [name]: value,
      };
    } else if (name === "test_meet") {
      data = {
        [name]: e.target.checked,
      };
    } else {
      data = {
        [name]: value,
      };
    }
    //console.log("data", data)
    if (name === "time") {
      let utctime = momentT.tz(value, "hh:mm", "UTC").format("HH:mm");
      console.log("utctime", utctime);
      utctime = moment(utctime, "HH:mm")
        .add(UTS_EST_OFFSET, "minutes")
        .format("HH:mm");
      data["est_time"] = utctime;
    }

    if (name === "selected_lang") {
      //console.log("value", value)
      data["selectedLang"] = value;
    }
    if (name === "host_filter") {
      //console.log("value", value)
      data["host_filter"] = value;
    }
    if (name === "day_filter") {
      data["day_filter"] = value;
    }
    return this.setState(data);
  };

  onDaysChange = (e) => {
    let { days } = this.state;
    let { value } = e.target;
    let index = days.indexOf(value);
    if (index > -1) {
      days.splice(index, 1);
    } else {
      days.push(value);
    }

    this.setState({
      realdays: days,
    });
    return;
  };

  onChangeCategory = (value, name) => {
    //console.log('value', value)
    this.setState({ categoriesMeet: value });
  };

  onChangeLanguage = (value) => {
    //console.log('value', value)
    let languageCodes = value.map((e) => {
      return e.code;
    });
    languageCodes.toString();
    //console.log("languageCodes", languageCodes.toString())
    this.setState({ spoken_language: languageCodes.toString() });
  };

  updateWeeklyTimeDurations = (days, dayName, dayDuration) => {
    if (days) {
      this.setState({ realdays: days });
    }
    if (dayName) {
      // durations.forEach(element => {
      let n = dayName;
      //console.log("n", n)
      let v = dayDuration;
      //console.log("v",v)
      switch (n) {
        case "monDuration":
          this.setState({ monDuration: v });
          break;
        case "tueDuration":
          this.setState({ tueDuration: v });
          break;
        case "wedDuration":
          this.setState({ wedDuration: v });
          break;
        case "thuDuration":
          this.setState({ thuDuration: v });
          break;
        case "friDuration":
          this.setState({ friDuration: v });
          break;
        case "satDuration":
          this.setState({ satDuration: v });
          break;
        case "sunDuration":
          this.setState({ sunDuration: v });
          break;
      }
      //});
    }
    return;
  };

  filterCatTitle = (person, value) => {
    let lastname = person.english_title.toLowerCase();
    let search = value.toLowerCase();

    return lastname.indexOf(search) === 0;
  };

  onUpdateHandler = (e) => {
    e.preventDefault();
    let {
      title,
      type,
      days,
      time,
      duration,
      host,
      description,
      wsoId,
      categoriesMeet,
      meetingId,
      spoken_language,
      display_date,
      meet_date,
      meet_type_e,
      test_meet,
      realdays,
      monDuration,
      tueDuration,
      wedDuration,
      thuDuration,
      friDuration,
      satDuration,
      sunDuration,
    } = this.state;

    let weeklyTimeDurationsCopy = realdays.map((item) => {
      switch (item) {
        case "monday":
          return { day: item, duration: monDuration };
        case "tuesday":
          return { day: item, duration: tueDuration };
        case "wednesday":
          return { day: item, duration: wedDuration };
        case "thursday":
          return { day: item, duration: thuDuration };
        case "friday":
          return { day: item, duration: friDuration };
        case "saturday":
          return { day: item, duration: satDuration };
        case "sunday":
          return { day: item, duration: sunDuration };
      }
    });
    let data = {
      title,
      type,
      categories: categoriesMeet,
      days: realdays,
      time: time,
      duration,
      host: host[0]._id,
      description,
      wsoId,
      meetingId,
      spokenLanguage: spoken_language,
      meetType: meet_type_e,
      meet_date,
      display_date,
      test_meet,
    };
    let data1 = {
      title,
      type,
      categories: categoriesMeet,
      days: realdays,
      time: time,
      // duration,
      host: host[0]._id,
      description,
      wsoId,
      meetingId,
      spokenLanguage: spoken_language,
      meetType: meet_type_e,
      meet_date,
      display_date,
      test_meet,
      weeklyTimeDurations: weeklyTimeDurationsCopy,
    };

    if (type === "weekly" && days.length === 0)
      return notification("error", "Please select aleast one day!");
    if (type === "daily") data.days = DAYS_ARRAY;
    debugger;
    if (type === "weekly" || (type === "daily" && weeklyTimeDurationsCopy)) {
      const checkMonday = weeklyTimeDurationsCopy.some(
        (el) => el.day === "monday"
      );
      const checkTuesday = weeklyTimeDurationsCopy.some(
        (el) => el.day === "tuesday"
      );
      const checkWednesday = weeklyTimeDurationsCopy.some(
        (el) => el.day === "wednesday"
      );
      const checkThrusday = weeklyTimeDurationsCopy.some(
        (el) => el.day === "thursday"
      );
      const checkFriday = weeklyTimeDurationsCopy.some(
        (el) => el.day === "friday"
      );
      const checkSaturday = weeklyTimeDurationsCopy.some(
        (el) => el.day === "saturday"
      );
      const checkSunday = weeklyTimeDurationsCopy.some(
        (el) => el.day === "sunday"
      );
      let updateMeeting = true;
      if (checkMonday && !monDuration) {
        notification("error", "monday duration is empty");
        updateMeeting = false;
      }
      if (checkTuesday && !tueDuration) {
        notification("error", "tuesday duration is empty");
        updateMeeting = false;
      }
      if (checkWednesday && !wedDuration) {
        notification("error", "wednesday duration is empty");
        updateMeeting = false;
      }
      if (checkThrusday && !thuDuration) {
        notification("error", "thursday duration is empty");
        updateMeeting = false;
      }
      if (checkFriday && !friDuration) {
        notification("error", "friday duration is empty");
        updateMeeting = false;
      }
      if (checkSaturday && !satDuration) {
        notification("error", "saturday duration is empty");
        updateMeeting = false;
      }
      if (checkSunday && !sunDuration) {
        notification("error", "sunday duration is empty");
        updateMeeting = false;
      }
      if (updateMeeting) {
        this.props._updateMeeting(data1);
      }
    } else {
      this.props._updateMeeting(data);
    }
  };

  onDeleteHandler = () => {
    debugger;
    console.log(this.state, "stateeee")
    let { meetingId } = this.state;
    this.props._deleteMeeting(meetingId);
    
  };
 
  render() {
    let {
      headsAg,
      meetings,
      isFetched,
      modal,
      modalTitle,
      modalText,
      selectedRecord,
      editModal,
      hosts,
      title,
      type,
      categoriesMeet,
      days,
      time,
      duration,
      host,
      description,
      wsoId,
      isUpdating,
      deleteModal,
      est_time,
      spoken_language,
      meet_type,
      display_date,
      meet_date,
      test_meet,
      meet_type_e,
      realdays,
      selected_lang,
      durationModal,
      weeklyTimeDurations,
      monDuration,
      tueDuration,
      wedDuration,
      thuDuration,
      friDuration,
      satDuration,
      sunDuration,
      host_filter,
      day_filter,
    } = this.state;
    let { categories, languages } = this.props;

    return (
      <Container>
        <Row>
          <Col md={12}>
            <Card>
              <CardBody>
                <h3 className="page-title bb-1 border-gray pb-2 mb-2">
                  Meetings
                </h3>
                <Row>
                  {/* <Row style={{marginTop:10}}> */}

                  <Col md={{ size: 3 }} className="pt-2">
                    <Form className="">
                      <FormGroup row>
                        {/* <Label sm={12}>Language :- </Label> */}
                        <Col sm={10}>
                          <Input
                            type="select"
                            name="selected_lang"
                            id="typeselect"
                            value={selected_lang}
                            onChange={this.onChangeHandler}
                          >
                            <option value={""}>{"All"}</option>
                            {languages &&
                              languages.languages &&
                              languages.languages.length > 0 &&
                              languages.languages.map((l) => {
                                return <option value={l.code}>{l.name}</option>;
                              })}
                          </Input>
                        </Col>
                      </FormGroup>
                    </Form>
                  </Col>
                  <Col md={{ size: 3 }} className="pt-2">
                    <Form className="">
                      {/* <Label>Contribution Date :- </Label> */}
                      <Input
                        type="select"
                        name="meet_type"
                        id="typeselect"
                        value={meet_type}
                        onChange={this.onChangeHandler}
                      >
                        <option value={""}>All Meetings</option>
                        {MEETING_TYPES &&
                          MEETING_TYPES.map((m) => {
                            return <option value={m.type}> {m.name} </option>;
                          })}
                      </Input>
                    </Form>
                  </Col>
                  <Col md={{ size: 3 }} className="text-right pt-2">
                    <Form className="text-right">
                      <FormGroup>
                        <Input
                          type="search"
                          name="search"
                          id="exampleEmail"
                          placeholder="Search meetings"
                          onChange={(e) => this.searchHandler(e)}
                        />
                      </FormGroup>
                    </Form>
                  </Col>
                  <Col md={{ size: 3 }} className="text-right pt-2">
                    <Form className="">
                      <FormGroup row>
                        {/* <Label sm={12}>Language :- </Label> */}
                        <Col sm={10}>
                          <Input
                            type="select"
                            name="host_filter"
                            value={host_filter}
                            id="typeselect"
                            onChange={this.onChangeHandler}
                          >
                            <option value="">--select host-</option>
                            {hosts &&
                              hosts.length > 0 &&
                              hosts.map((host) => {
                                return (
                                  <option key={host._id} value={host._id}>
                                    {host.email.mail}
                                  </option>
                                );
                              })}
                          </Input>
                        </Col>
                      </FormGroup>
                    </Form>
                  </Col>
                  <Col md={{ size: 3 }} className="text-right pt-2">
                    <Form className="">
                      <FormGroup row>
                        {/* <Label sm={12}>Language :- </Label> */}
                        <Col sm={10}>
                          <Input
                            type="select"
                            name="day_filter"
                            value={day_filter}
                            id="typeselec1t"
                            onChange={this.onChangeHandler}
                          >
                            <option value="">--select day-</option>
                            <option value="monday">Monday</option>
                            <option value="tuesday">Tuesday</option>
                            <option value="wednesday">Wednesday</option>
                            <option value="thursday">Thursday</option>
                            <option value="friday">Friday</option>
                            <option value="saturday">Saturday</option>
                            <option value="sunday">Sunday</option>
                          </Input>
                        </Col>
                      </FormGroup>
                    </Form>
                  </Col>
                  {(localStorage.getItem("UserRole") === "superadmin" ||
                    localStorage.getItem("UserRole") === "admin") && (
                    <Col md={3} className="text-left">
                      <Button
                        color="primary"
                        onClick={() => {
                          this.props.history.push("/meetings/add");
                        }}
                      >
                        Add +
                      </Button>
                    </Col>
                  )}
                  <Col
                    md={12}
                    style={{ height: "90vh", width: "600px" }}
                    className="ag-theme-material"
                  >
                    <TableComponent
                      rowsData={meetings}
                      headsAg={headsAg}
                      editRecord={this.editRecord}
                      rowSelectable={"multiple"}
                      rowsPerPage={15}
                      isFetched={isFetched}
                      fetchingRecordsText={"Fetching meetings..."}
                      noRecordsText={"No Meetings Found!"}
                      {...this.props}
                    />
                  </Col>
                  <Col md={12} className="text-right mt-2">
                    <TablePagination
                      onPageChange={this.onPageChange}
                      {...this.state}
                    />
                  </Col>
                </Row>
              </CardBody>
            </Card>
            <CommonModal
              toggle={this.toggleDeleteModal}
              customClass={"test"}
              cancelButtonText={"Cancel"}
              cancelAction={this.toggleModal}
              confirmAction={this.onDeleteHandler}
              confirmButtonText={"Add"}
              modal={deleteModal}
              modalText={"Are you Sure you want to delete this meeting "}
              modalTitle={"DeleteMeeting"}
            />
            <MeetingDetailsModal
              toggle={this.toggleModal}
              customClass={"test"}
              cancelButtonText={"Cancel"}
              cancelAction={this.toggleModal}
              confirmAction={(e) => this.toggle(e)}
              confirmButtonText={"Add"}
              modal={modal}
              data={selectedRecord}
              func={"block"}
              // modalhtmlFor={modalFor}
              modalTitle={modalTitle}
              modalText={modalText}
              title={title}
              type={type}
              category={categoriesMeet}
              days={days}
              time={time}
              duration={duration}
              host={host}
              description={description}
              wsoId={wsoId}
              hosts={hosts}
              est_time={est_time}
              spoken_language={spoken_language}
              display_date={display_date}
              meet_date={meet_date}
              meet_type={meet_type_e}
              weeklyTimeDurations={weeklyTimeDurations}
            />

            <MeetingDurationModal
              toggle={this.toggleDurationModal}
              // customClass = {'test'}
              // cancelButtonText = {'Cancel'}
              // cancelAction = {this.toggleModal}
              // confirmAction={(e)=>this.toggle(e)}
              // confirmButtonText={'Add'}
              modal={durationModal}
              // data={selectedRecord}
              // func = {'block'}
              // // modalhtmlFor={modalFor}
              // modalTitle={modalTitle}
              modalText="hey"
              // // title={title}
              // // type={type}
              // // category={categoriesMeet}
              days={days}
              // time={time}
              duration={duration}
              weeklyTimeDurations={weeklyTimeDurations}
            />

            {editModal && (
              <EditMeetingModal
                toggle={this.toggleEditModal}
                customClass={"test"}
                cancelButtonText={"Cancel"}
                cancelAction={this.toggleEditModal}
                confirmAction={this.onUpdateHandler}
                onChangeHandler={this.onChangeHandler}
                confirmButtonText={"Add"}
                modal={editModal}
                data={selectedRecord}
                func={"block"}
                // modalhtmlFor={modalFor}
                onDaysChange={this.onDaysChange}
                categories={categories}
                onChangeCategory={this.onChangeCategory}
                onChangeLanguage={this.onChangeLanguage}
                filterCatTitle={this.filterCatTitle}
                title={title}
                type={type}
                category={categoriesMeet}
                days={realdays}
                time={time}
                est_time={est_time}
                duration={duration}
                host={host}
                description={description}
                wsoId={wsoId}
                hosts={hosts}
                isFetching={isUpdating}
                spoken_language={spoken_language}
                display_date={display_date}
                meet_date={meet_date}
                meet_type_e={meet_type_e}
                test_meet={test_meet}
                weeklyTimeDurations={weeklyTimeDurations}
                updateWeeklyTimeDurations={this.updateWeeklyTimeDurations}
                monDuration={monDuration}
                tueDuration={tueDuration}
                wedDuration={wedDuration}
                thuDuration={thuDuration}
                friDuration={friDuration}
                satDuration={satDuration}
                sunDuration={sunDuration}
              />
            )}
          </Col>
        </Row>
      </Container>
    );
  }
}

export default ListMeetings;
