import * as Actions from './actionTypes';
import * as feelingFuncs from './../../api/categoryApis';


export function  getAllCategoriesRequest() {
    return {
      type: Actions.GETALLFEELINGSREQUEST,
      payload:{}
    };
  }
   
  export function getAllCategoriesSuccess(data) {
    return {
      type: Actions.GETALLFEELINGSUCCESS,
      payload : {
          feelings : data.data
        },
    };
  }
   
  export function  getAllCategoriesError(message) {
      return {
        type: Actions.GETALLFEELINGSERROR,
        payload : { message },
      };
    }

    export function  createCategoryRequest() {
      return {
        type: Actions.CREATECATEGORYREQUEST,
        payload:{}
      };
    }
     
    export function createCategorySuccess(data) {
      return {
        type: Actions.CREATECATEGORYSUCCESS,
        payload : data,
      };
    }
     
    export function  createCategoryError(message) {
        return {
          type: Actions.CREATECATEGORYERROR,
          payload : { message },
        };
    }
  export function  updatefeelingRequest() {
      return {
        type: Actions.UPDATEFEELINGREQUEST,
        payload:{}
      };
  }
      
  export function updatefeelingSuccess(data) {
      return {
        type: Actions.UPDATEFEELINGSUCCESS,
        payload : data,
      };
  }
      
  export function  updatefeelingError(message) {
      return {
          type: Actions.UPDATEFEELINGERROR,
          payload : { message },
      };
  }

  export function  deletefeelingRequest() {
    return {
      type: Actions.DELETEFEELINGREQUEST,
      payload:{}
    };
}
    
export function deletefeelingSuccess(data) {
    return {
      type: Actions.DELETEFEELINGSUCCESS,
      payload : data,
    };
}
    
export function  deletefeelingError(message) {
    return {
        type: Actions.DELETEFEELINGERROR,
        payload : { message },
    };
}   

export const addFeelings = (data) =>{
    return (dispatch) => {
        dispatch(createCategoryRequest());
        feelingFuncs.craeteNewFeeling(data).then((res)=>{
            console.log('responseData*****************************', res)
        if(res.status === 200) return dispatch(createCategorySuccess(res.data));
        if(res.status === 400) return dispatch(createCategoryError(res.data.message));
        if(res.status === 401) return dispatch(createCategoryError(res.data.message));
        if(res.status === 409) return dispatch(createCategoryError(res.data.message));

        }).catch((err)=>{
            console.log('err', err)
            return dispatch(createCategoryError(err.message));
        })
    }
}

export const listAllFeelings = (page, limit) =>{
    return (dispatch) => {
        dispatch(getAllCategoriesRequest());
        feelingFuncs.getAllFeelings(page, limit).then((res)=>{
        if(res.status === 200) return dispatch(getAllCategoriesSuccess(res.data));
        if(res.status === 400) return dispatch(getAllCategoriesError(res.data.message));
        if(res.status === 401) return dispatch(getAllCategoriesError(res.data.message));
        if(res.status === 409) return dispatch(getAllCategoriesError(res.data.message));
        }).catch((err)=>{
            console.log('err', err)
            return dispatch(getAllCategoriesError(err.message));
        })
    }
}

export const updateFeeling = (id, data) =>{
  return (dispatch) => {
      dispatch(updatefeelingRequest());
      feelingFuncs.updateFeelingData(id,data).then((res)=>{
          console.log('responseData*****************************', res)
      if(res.status === 200) return dispatch(updatefeelingSuccess(res.data));
      if(res.status === 400) return dispatch(updatefeelingError(res.data.message));
      if(res.status === 401) return dispatch(updatefeelingError(res.data.message));
      if(res.status === 409) return dispatch(updatefeelingError(res.data.message));

      }).catch((err)=>{
          console.log('err', err)
          return dispatch(updatefeelingError(err.message));
      })
  }
}


export const removeFeeling = (id) =>{
  return (dispatch) => {
      dispatch(deletefeelingRequest());
      feelingFuncs.removeFeeling(id).then((res)=>{
          console.log('responseData*****************************', res)
      if(res.status === 200) return dispatch(deletefeelingSuccess(res.data));
      if(res.status === 400) return dispatch(deletefeelingError(res.data.message));
      if(res.status === 401) return dispatch(deletefeelingError(res.data.message));
      if(res.status === 409) return dispatch(deletefeelingError(res.data.message));

      }).catch((err)=>{
          console.log('err', err)
          return dispatch(deletefeelingError(err.message));
      })
  }
}