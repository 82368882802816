import { connect } from "react-redux";
import { addAdmin } from '../../redux/actions/AdminActions';
import { listAllCategories } from '../../redux/actions/categoryActions';
import { listhosts, createMeeting } from '../../redux/actions/meetingActions';
import { withRouter } from 'react-router-dom';
import AddeNewMeeting from './../../components/Meetings/AddNewMeeting';

export const mapStateToProps = state => {
  //console.log('states in add meeting', state);
  return {
    meeting : state.createMeeting,
    categories : state.categories.categories,
    hosts : state.hosts 
  };
};

export const mapDispatchToProps = (dispatch) => {
  return {
    addNewAdmin: (data) => {
      return dispatch(addAdmin(data));
    },
    _fetchHosts: (data) => {
      return dispatch(listhosts());
    },
    _fetchCategories : (isActive) => {
      return dispatch(listAllCategories(0,10, isActive))
    },
    _createMeeting : (data) => {
      return dispatch(createMeeting(data))
    }
  };
};

const AddeNewMeetingContainer = connect(mapStateToProps, mapDispatchToProps)(
  AddeNewMeeting
);

export default withRouter(AddeNewMeetingContainer);
