// import { ADDADMIN, ERROR, FORGETPASSWORD } from '../actions/ADDADMIN_action';
import { GETALLFEELINGSCLEAR, GETALLFEELINGSREQUEST, GETALLFEELINGSERROR, GETALLFEELINGSUCCESS,CREATECATEGORYREQUEST,CREATECATEGORYSUCCESS,CREATECATEGORYERROR } from './../actions/actionTypes'
 
const initialState = {
    requestDone: false,
    isFetched: false,
    isFetching : false,
    err : false,
    message:'',
    feelings: [],
    total : 0,
  };
  
  // receiving response sent by action according to type of action
  export default function allFeelingsReducers(state = initialState, action) {
      let { payload } = action
    switch (action.type) {
        case GETALLFEELINGSCLEAR:
          return { 
            requestDone: false,
            isFetched: false,
            isFetching : false,
            err : false,
            message:'',
            feelings: [],
            total :0
           };
          break;
        case GETALLFEELINGSREQUEST:
          return { 
            requestDone: false,
            isFetched: false,
            isFetching : true,
            err : false,
            message:'',
            feelings: [],
            total : 0
           };
          break;
        case GETALLFEELINGSUCCESS:
            console.log('========in switch', payload.feelings)
        return { 
            requestDone: true,
            isFetched: true,
            isFetching : false,
            err : false,
            message:'',
            feelings: payload.feelings,
            total : payload.count
        };
        break;
  
        case GETALLFEELINGSERROR:
        return { 
            requestDone: true,
            isFetched: false,
            isFetching : false,
            err : true,
            message: payload.message,
            feelings: [],
            total : 0
        };
        break;
      default:
        return state;
    }
  }
