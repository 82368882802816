import { connect } from "react-redux";
import Resources from '../../components/Resources/Resources';
import { fetchResources, uploadResource, updateResource, deleteResource } from '../../redux/actions/resourcesAction';

const mapStateToProps = state => {
    console.log('state in container', state);
    return {
        fetchResources : state.fetchResources,
        uploadResources : state.uploadResources,
        updateResource : state.updateResource,
        deleteResource : state.deleteResource
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        _fetchResources: (page, skip, type) => {
            return dispatch(fetchResources(page, skip, type));
        },
        _uploadResource : (data) => {
            return dispatch(uploadResource(data))
        },
        _updateResource : (data) => {
            return dispatch(updateResource(data))
        },
        _deleteResource : (resourceId) => {
            return dispatch(deleteResource(resourceId))
        }
    }
}

const ResourcesContainer = connect(mapStateToProps, mapDispatchToProps)(
    Resources
);

export default ResourcesContainer
