// import { ADDADMIN, ERROR, FORGETPASSWORD } from '../actions/ADDADMIN_action';
import { FETCHDASHBOARDSTATSERROR, FETCHDASHBOARDSTATSREQUEST, FETCHDASHBOARDSTATSSUCCESS } from './../actions/actionTypes'
 
const initialState = {
    requestDone: false,
    isFetched: false,
    isFetching : false,
    err : false,
    message:'',
    data: {},
  };
  
  // receiving response sent by action according to type of action
  export default function dashboardStatsReducers(state = initialState, action) {
      //console.log(action,'action')
      let { payload } = action
    switch (action.type) {
        // case FETCHDASHBOARDSTATSERROR:
        //   return { 
        //     requestDone: false,
        //     isFetched: false,
        //     isFetching : false,
        //     err : false,
        //     message:'',
        //     data: {},
        //    };
        //   break;
        case FETCHDASHBOARDSTATSREQUEST:
          return { 
            requestDone: false,
            isFetched: false,
            isFetching : true,
            err : false,
            message:'',
            data: {},
           };
          break;
        case FETCHDASHBOARDSTATSSUCCESS:
            console.log('========in switch', payload.data)
        return { 
            requestDone: true,
            isFetched: true,
            isFetching : false,
            err : false,
            message:'',
            data: payload.data
        };
        break;
  
        case FETCHDASHBOARDSTATSERROR:
        return { 
            requestDone: true,
            isFetched: false,
            isFetching : false,
            err : true,
            data: {}
        };
        break;
      default:
        return state;
    }
  }
