import { toast } from 'react-toastify';

const notification = (type, msg) => {
    console.log('notification helper called')
    if(type==='success') toast(msg,{
        className: 'green-background',    
    })
    else if(type==='error') toast.error(msg)
    else toast(msg)
}

export default notification

