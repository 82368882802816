import React, { useState } from 'react';
import { Row, Col, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

const MeetingDetailsModal = (props) => {
  const {
    buttonLabel,
    className,
    toggle,
    modal,
    func,
    confirmAction,
    modalTitle,
    modalText,
    modalFor,
    title, days, time, duration, description, createdAt, host, category,est_time,
    spoken_language,weeklyTimeDurations,type
  } = props;

  //console.log('data modal meeting', props);
  return (
    <div  style={{textAlign:'center'}}>
      {/* <Button color="danger" onClick={toggle}>{buttonLabel}</Button> */}
      <Modal isOpen={modal} fade={true} toggle={toggle} keyboard={false} backdrop={false}>
          <span>
            <ModalHeader toggle={toggle}>{'Meeting Details'}</ModalHeader>
          </span>
        <ModalBody>
        {type==="weekly" && weeklyTimeDurations ? 
        <div>
                <h2 className="text-center">{title}</h2>
                {/* <p className="text-center"><i><strong>{`@${est_time} for ${duration} mins`}</strong></i></p> */}
                
                <p><i><strong>{`Hosted by :- ${host && host.length > 0 && (host[0].email.mail)}`}</strong></i></p>
                <p><i><strong>{`Spoken Language :- ${spoken_language && (spoken_language)}`}</strong></i></p>
                <p><i><strong>{`Duration :- `}</strong></i></p>
                <div className="mb-3">
                  
                    <Row>
                        {
                          weeklyTimeDurations.map((val)=>
                            <Col md={8} sm={8}>
                                 <Row>
                                     <Col md={4} sm={4}><h5>{val.day}</h5></Col>
                                     <Col md={8} sm={8}>{`${val.duration} mins`}</Col>
                                 </Row>
                            </Col>
                            
                          )
                          
                        }    
                    </Row>
                  
                </div>
                <div className="mb-3">
                  <div className="mb-2">Categories : <br /></div>
                  <div>
                    {category && category.length > 0 && (
                    category.map((cat, index) => {
                      console.log('cat', cat)
                      return(
                        <React.Fragment key={index}>
                          <span className="category-tags">{cat.english_title}</span>
                          {index > 4 && (<br />)}
                        </React.Fragment>
                      )
                    })
                    )}
                  </div>
                </div>
                <p>Description : <br /> {description}</p>
            </div>
        :
        <div>
                <h2 className="text-center">{title}</h2>
                <p className="text-center"><i><strong>{`@${est_time} for ${duration} mins`}</strong></i></p>
                
                <p><i><strong>{`Hosted by :- ${host && host.length > 0 && (host[0].email.mail)}`}</strong></i></p>
                <p><i><strong>{`Spoken Language :- ${spoken_language && (spoken_language)}`}</strong></i></p>
            <div className="mb-3">Days : <br />
                  {days && days.length > 0 && (
                    <div>
                      <span className={days.includes('sunday') ? `daysCircle daysHighlight` : 'daysCircle'} >S</span>
                      <span className={days.includes('monday') ? `daysCircle daysHighlight` : 'daysCircle'} >M</span>
                      <span className={days.includes('tuesday') ? `daysCircle daysHighlight` : 'daysCircle'} >T</span>
                      <span className={days.includes('wednesday') ? `daysCircle daysHighlight` : 'daysCircle'} >W</span>
                      <span className={days.includes('thursday') ? `daysCircle daysHighlight` : 'daysCircle'} >T</span>
                      <span className={days.includes('friday') ? `daysCircle daysHighlight` : 'daysCircle'} >F</span>
                      <span className={days.includes('saturday') ? `daysCircle daysHighlight` : 'daysCircle'} >S</span>
                    </div>
                  )}
                </div>
                <div className="mb-3">
                  <div className="mb-2">Categories : <br /></div>
                  <div>
                    {category && category.length > 0 && (
                    category.map((cat, index) => {
                      console.log('cat', cat)
                      return(
                        <React.Fragment key={index}>
                          <span className="category-tags">{cat.english_title}</span>
                          {index > 4 && (<br />)}
                        </React.Fragment>
                      )
                    })
                    )}
                  </div>
                </div>
                <p>Description : <br /> {description}</p>
            </div>
        }
            {/*<div>
                <h2 className="text-center">{title}</h2>
                <p className="text-center"><i><strong>{`@${est_time} for ${duration} mins`}</strong></i></p>
                <p><i><strong>{`Hosted by :- ${host && host.length > 0 && (host[0].email.mail)}`}</strong></i></p>
                <p><i><strong>{`Spoken Language :- ${spoken_language && (spoken_language)}`}</strong></i></p>
                <div className="mb-3">Days : <br />
                  {days && days.length > 0 && (
                    <div>
                      <span className={days.includes('sunday') ? `daysCircle daysHighlight` : 'daysCircle'} >S</span>
                      <span className={days.includes('monday') ? `daysCircle daysHighlight` : 'daysCircle'} >M</span>
                      <span className={days.includes('tuesday') ? `daysCircle daysHighlight` : 'daysCircle'} >T</span>
                      <span className={days.includes('wednesday') ? `daysCircle daysHighlight` : 'daysCircle'} >W</span>
                      <span className={days.includes('thursday') ? `daysCircle daysHighlight` : 'daysCircle'} >T</span>
                      <span className={days.includes('friday') ? `daysCircle daysHighlight` : 'daysCircle'} >F</span>
                      <span className={days.includes('saturday') ? `daysCircle daysHighlight` : 'daysCircle'} >S</span>
                    </div>
                  )}
                </div>
                <div className="mb-3">
                  <div className="mb-2">Categories : <br /></div>
                  <div>
                    {category && category.length > 0 && (
                    category.map((cat, index) => {
                      console.log('cat', cat)
                      return(
                        <React.Fragment key={index}>
                          <span className="category-tags">{cat.english_title}</span>
                          {index > 4 && (<br />)}
                        </React.Fragment>
                      )
                    })
                    )}
                  </div>
                </div>
                <p>Description : <br /> {description}</p>
            </div>*/}
        </ModalBody>
        <ModalFooter>
          {/* <Button color="primary" name ={modalFor} onClick={(e)=>confirmAction(e)}>Yes{' '}</Button>{' '} */}
          <Button color="secondary" onClick={toggle}>Close</Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default MeetingDetailsModal;