// import { LOGIN, ERROR, FORGETPASSWORD } from '../actions/login_action';
import { RESETPASSWORDCLEAR, RESETPASSWORDREQUEST, RESETPASSWORDSUCCESS, RESETPASSWORDERROR } from './../actions/actionTypes'

const initialState = {
    requestDone : false,
    isFetching : false,
    isError :false,
    message :'',
  };
  
  // receiving response sent by action according to type of action
  export default function loginReducers(state = initialState, action) {
      //console.log(action,'action')
      let { payload } = action;
    switch (action.type) {
      case RESETPASSWORDSUCCESS:
        return { 
            requestSuccess : true,
            isFetching : false,
            isError :false,
            message :payload.message,
        };
        break;
  
      case RESETPASSWORDERROR:
        return { 
            requestDone : false,
            isFetching : false,
            isError :true,
            message :payload.message,
        };
        break;
  
      case RESETPASSWORDREQUEST:
        return { 
            requestDone : false,
            isFetching : true,
            isError :false,
            message :'',
         };
        break;
      
      case RESETPASSWORDCLEAR:
        return { 
            requestDone : false,
            isFetching : false,
            isError :false,
            message :'',
         };
        break;

     
        break;
      default:
        return state;
    }
  }
  