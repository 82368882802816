import { wrappedFetch, wrappedGet } from './urlFuncs';
import {
    LIST_POST_CATEGORIES_URL,
} from "./../inc/constants";
import { API_URL } from './../inc/config';

export const fetchCategoriesList = (page, limit) =>{
    return wrappedGet(`${API_URL}${LIST_POST_CATEGORIES_URL}?page=${page}&limit=${limit}`, 'GET');
}

export const createCategory = (data) => {
    return wrappedFetch(`${API_URL}${LIST_POST_CATEGORIES_URL}`, 'POST', data);
}

export const updateCategory = (data) => {
    return wrappedFetch(`${API_URL}${LIST_POST_CATEGORIES_URL}`, 'PUT', data);
}

export const makeAllCategoryVisible = () => {
    return wrappedGet(`${API_URL}${LIST_POST_CATEGORIES_URL}visible/all`, 'GET');
}

export const updateCategoryVisibilty = (data) => {
    return wrappedFetch(`${API_URL}${LIST_POST_CATEGORIES_URL}visible/`, 'POST', data);
}