import { connect } from "react-redux";
import * as _ from "lodash";
import * as actions from "../../redux/actions/actionTypes";
import { listAllContent, addContent, editContent, deleteContent } from '../../redux/actions/objenctiobaleContentTypes';
import ObjectionableContent from '../../components/ObjectionableContent/ObjectionableContent';

const mapStateToProps = state => {
  // console.clear()
  //console.log('state in container', state);
  return {
    categories: state.categories, 
    categoryAdd: state.category,
    updateContent : state.updateContent,
    deleteContent : state.deleteContent
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    _fetchContent: (page, limit, lang, searchkey) => {
      return dispatch(listAllContent(page, limit, lang, searchkey));
    },
    _addContent : (data) =>{
      return dispatch(addContent(data))
    },
    _editContent : (data, id) =>{
      return dispatch(editContent(data, id))
    },
    _deleteContent : (id) =>{
      return dispatch(deleteContent(id))
    }
  };
};


const ObjectionableContentContainer = connect(mapStateToProps, mapDispatchToProps)(ObjectionableContent);

export default ObjectionableContentContainer;
