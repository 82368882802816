export const emailValidator = (email) => {
    if (email) {
        let re = /^[^<>()[\]\\,;:\%#^\s@\"$&!@]+@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}))$/;
        let trimmedEmail = email.trim();

        if (!re.test(trimmedEmail) || trimmedEmail.length === 0) {
            return false;
        }
        return true;
    } else {
        return false;
    }
}

export const passwordValidator = (password) => {
    if (password) {
        let re = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,12}$/;
        let trimmedpassword = password.trim();
        if (!re.test(trimmedpassword) || trimmedpassword.length === 0) {
            return false;
        }
        return true;
    } else {
        return false;
    }
}

export const phoneNumberValidator = (phone) => {
    if (phone) {
        let re = /^[0-9]+$/;
        // let trimmedPhone = phone.trim();
        console.log('re.test(phone)', re.test(phone))
        return re.test(phone);
    } else {
        return false;
    }
}

export const countryCodeValidator = (code) => {
    // if (code) {
        let re = /^\+*[0-9]{0,3}$/;
        // let trimmedPhone = phone.trim();
        console.log('re.test(phone)', re.test(code))
        return re.test(code) && !code.length < 4;
}

export const meetingTitleValidator = (data) => {
    // if (code) {
        let re = /^[ A-Za-z0-9_@./#&+-]{0,60}$/;
        console.log('re.test(phone)', re.test(data))
        return re.test(data) && !data.length < 4;
}

