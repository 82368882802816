import * as Actions from './actionTypes';
import * as userApis from './../../api/userApis';
import * as adminAPis from './../../api/adminApis';
 
export function getAllUsersRequest() {
  return {
    type: Actions.APPUSERSLISTREQUEST,
    payload:{}
  };
}
 
export function getAllUsersSuccess(data) {
  return {
    type: Actions.APPUSERSLISTSUCCESS,
    payload : data,
  };
}
 
export function getAllUsersError(message) {
    return {
      type: Actions.APPUSERSLISTERROR,
      payload : { message },
    };
  }
 
 
  export function blockUserRequest() {
    return {
      type: Actions.APPUSERBLOCKREQUEST,
      payload:{}
    };
  }
  
  export function blockUserSuccess(data) {
    return {
      type: Actions.APPUSERBLOCKSUCCESS,
      payload : data,
    };
  }
  
  export function blockUserError(message) {
      return {
        type: Actions.APPUSERBLOCKERROR,
        payload : { message },
      };
  }


  export function searchUserRequest() {
    return {
      type: Actions.APPUSERSEARCHREQUEST,
      payload:{}
    };
  }
    
  export function searchUserSuccess(data) {
    return {
      type: Actions.APPUSERSEARCHSUCCESS,
      payload : data,
    };
  }
  
  export function searchUserError(message) {
      return {
        type: Actions.APPUSERSEARCHERROR,
        payload : { message },
      };
  }


  export function deleteUserRequest() {
    return {
      type: Actions.APPUSERDELETEREQUEST,
      payload:{}
    };
  }
  
  export function deleteUserSuccess(data) {
    return {
      type: Actions.APPUSERDELETESUCCESS,
      payload : data,
    };
  }
  
  export function deleteUserError(message) {
      return {
        type: Actions.APPUSERDELETEERROR,
        payload : { message },
      };
    }
 
 
 
export const getAllUsers = (page, limit, active, subscribed,tester, blocked, sortBy, join_date_sort, sort, sortOrder) =>{
    return (dispatch) => {
        dispatch(getAllUsersRequest());
        userApis.getAllAppUsers(page, limit, active, subscribed,tester, blocked, sortBy, join_date_sort, sort, sortOrder).then((res)=>{
            console.log('responseData', res)
            if(res.status === 200 )return dispatch(getAllUsersSuccess(res.data));
            if(res.status === 409 )return dispatch(getAllUsersSuccess(res.data));
            if(res.status === 401 )return dispatch(getAllUsersError(res.data.message));
        }).catch((err)=>{
          return dispatch(getAllUsersError(err.msg))
            console.log('err', err)
        })
    }
}
 
export const blockUser = (data) =>{
  return (dispatch) => {
      dispatch(blockUserRequest());
      userApis.blockAppUser(data).then((res)=>{
          console.log('responseData', res)
          if(res.status === 200 )return dispatch(blockUserSuccess(res.data));
          if(res.status === 401 )return dispatch(blockUserError(res.data.message));
      }).catch((err)=>{
        return dispatch(blockUserError(err.msg))
          console.log('err', err)
      })
  }
}

export const deleteUser = (data) =>{
  return (dispatch) => {
      dispatch(deleteUserRequest());
      userApis.deleteAppUser(data).then((res)=>{
          console.log('responseData', res)
          if(res.status === 200 )return dispatch(deleteUserSuccess(res.data));
          if(res.status === 401 )return dispatch(deleteUserError(res.data.message));
      }).catch((err)=>{
        return dispatch(deleteUserError(err.msg))
          console.log('err', err)
      })
  }
}

export const makeHostUser = (data) =>{
  return (dispatch) => {
      dispatch(blockUserRequest());
      userApis.hostUser(data).then((res)=>{
          console.log('responseData', res)
          if(res.status === 200 )return dispatch(blockUserSuccess(res.data));
          if(res.status === 401 )return dispatch(blockUserError(res.data.message))
          else return dispatch(blockUserError(res.data.message))
        }).catch((err)=>{
        return dispatch(blockUserError(err.msg))
          console.log('err', err)
      })
  }
}


export const searchUser = (searchInfo, active) =>{
  return (dispatch) => {
      dispatch(searchUserRequest());
      userApis.searchAppUser(searchInfo, active).then((res)=>{
          console.log('responseData==', res)
          if(res.status === 200 )return dispatch(searchUserSuccess(res.data));
          if(res.status === 401 )return dispatch(searchUserError(res.data.message));
      }).catch((err)=>{
        console.log('err', err)
        return dispatch(searchUserError(err.msg))
      })
  }
}


export const makeTester = (data) =>{
  return (dispatch) => {
      dispatch(blockUserRequest());
      userApis.makeUserTester(data).then((res)=>{
          console.log('responseData', res)
          if(res.status === 200 )return dispatch(blockUserSuccess(res.data));
          if(res.status === 401 )return dispatch(blockUserError(res.data.message))
          else return dispatch(blockUserError(res.data.message))
        }).catch((err)=>{
        return dispatch(blockUserError(err.msg))
          console.log('err', err)
      })
  }
}
