import React, { useState, useEffect } from 'react';
import { Container, Card, CardBody, Row, Col } from 'reactstrap';
import TableComponent from './../Shared/Table/Table';
import TablePagination from './../Shared/Table/TablePagination';
import moment from 'moment';

const ViewLogsModal = React.lazy(() => import('./ViewLogsModal'));

const UserTracking = (props) => {

    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(100);
    const [content, setContent] = useState([]);
    const [isFetched, setFetched] = useState(false);
    const [total, setTotal] = useState(10);
    const [modalView, setModalView] = useState(false);
    const [logsData, setLogsData] = useState(true)
    //render functions
    // let renderIndex=(record) => {
    //     let index = record.rowIndex + 1 ;
    //     if(page > 1){
    //         index  = (page - 1)*limit + record.rowIndex + 1
    //     }
    //     return index;
    // }

    let renderStage = (record) => {
        let splits = record.data.stage.split('_')
        let stage = splits[0];

        if(splits[1])
        {
            stage += ' '+splits[1]
        }
        return stage
    }

    const renderAction = (record) => {
        let buttons = <span >
                    <p onClick={()=>{ viewLog(record.data) } } style={{color: "blue"}}>view logs</p>                 
                </span>;
        return buttons;
    }

    //event functions
    let  onPageChange = (pagenum) => {
        setPage(pagenum);
    }

    let editRecord = () =>{}

    const viewLog = (data) => {
        let rows = [];
        for (let index = 0; index < data.dates.length; index++) {
            rows.push(<tr>
                <td>{moment(data.dates[index].date).format('MM-DD-YYYY')}</td>
                <td>{data.dates[index].message}</td>
            </tr>)
        }
        setLogsData(rows)
        setModalView(true)
    }

    const toggleViewModal = () => {
        setModalView(!modalView)
    }
    const renderDate = (record) => {
        return moment(record.data.createdAt).format('MM-DD-YYYY')
    }

    const headsAg = [
        // {headerName: "Id", field: "_id", width:"80", sortable:true, filter:'text', 
        //     cellRendererFramework : renderIndex ,
        //     // checkboxSelection : true
        // },
        {headerName: "Stage", field: "stage", cellStyle: {'text-transform': 'capitalize'},  sortable:true, filter:'text' , cellRendererFramework : renderStage},
        {headerName: "Display Name", field: "displayname", sortable:true, filter:'text' }, 
        {headerName: "Email", field: "email", cellStyle: {'word-wrap': 'break-word'}, autoHeight: true,  sortable:true, filter:'text' },
        {headerName: "Firstname", field: "firstname",  sortable:true, filter:'text' },
        {headerName: "Default Language", field: "defaultLanguage",  sortable:true, filter:'text'},
        {headerName: "Date", field: "createdAt", sortable:true, filter:'text', 
        cellRendererFramework : renderDate , width:"100"
        },
        {
            headerName: "Actions", 
            cellRendererFramework : renderAction, 
            sortable:true, 
            filter:'text'
        },
    ]

    useEffect(()=>{
        console.log("props", props)
        console.log('props in use effect user tracking', props.userTrackingList.data)
        setContent(props.userTrackingList.data);
        if(!props.userTrackingList.isFetching && props.userTrackingList.isFetched && props.userTrackingList.data !== content ){
            setFetched(props.userTrackingList.isFetched);
        }

        console.log('content', content)

    },[props.userTrackingList]) 

    useEffect(() => {
        props._userTrackingList(page, limit);
    }, [page])


    return (
        < Container>
            <Row>
                <Col md={12}>
                    <Card>
                        <CardBody className="bg-white ">
                            <h3 className='page-title bb-1 border-gray mb-4'>Issue Log</h3>
                            <Col md={12} style={{ height: '90vh', width: '1000px' }} className="ag-theme-material">
                            <div style={{height : '100%', width : '100%'}}>   
                            <TableComponent 
                                    rowsData={ content } 
                                    // onCellClicked={ onCellClicked }
                                    headsAg={headsAg}
                                    editRecord={editRecord} 
                                    isFetched ={isFetched}
                                    rowSelectable={'multiple'} 
                                    rowsPerPage={10}
                                    checkboxSelection={true}
                                    // noRecordsText = {norecordstext}
                                    fetchingRecordsText = {'Fetching List...'}
                                    noRecordsText = {'No Records Found!'}
                                    {...props} 

                                />
                                </div>
                            </Col>
                            <Col md={12} className="mt-2">
                                <TablePagination onPageChange={onPageChange} page={page} limit={limit} total={total}/>
                            </Col>
                            <React.Suspense fallback={''}>
                                <ViewLogsModal 
                                    modal={modalView} 
                                    toggle={ toggleViewModal }
                                    modalTitle={ 'Logs' }
                                    CustomClass = {'test_class'}
                                    logsData = {logsData}
                                />
                            </React.Suspense>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Container>
    )
}

export default UserTracking