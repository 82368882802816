import { connect } from "react-redux";
import ListMeetings from '../../components/Meetings/ListMeetings';
import { getAllMeetings, listhosts, updateMeeting, deleteMeeting, listLanguages } from '../../redux/actions/meetingActions';
import { getAllUsers, searchUser } from '../../redux/actions/usersActions';
import { listAllCategories } from '../../redux/actions/categoryActions';

const mapStateToProps = state => {
  //console.log('state in container', state);
  return {
    meetings: state.listMeetings,
    categories : state.categories.categories,
    hosts : state.hosts ,
    createMeeting : state.createMeeting,
    deleteMeeting : state.deleteMeeting,
    languages : state.listLanguages
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchMeetings: (page, skip, search, lang, meet_type, host_filter, day_filter) => {
      return dispatch(getAllMeetings(page, skip, search, lang, meet_type, host_filter, day_filter));
    },
    _searchMeeting: (searchInfo) => {
      return dispatch(searchUser(searchInfo));
    },
    _fetchHosts: (data) => {
      return dispatch(listhosts());
    },
    _fetchCategories : () => {
      return dispatch(listAllCategories())
    },
    _updateMeeting : (data) => {
      return dispatch(updateMeeting(data))
    },
    _deleteMeeting : (meetId) => {
      return dispatch(deleteMeeting(meetId))
    },
    _listLanguages : () => {
      return dispatch(listLanguages())
    },
  };
};

const ListMeetingsContainer = connect(mapStateToProps, mapDispatchToProps)(
    ListMeetings
);

export default ListMeetingsContainer;
