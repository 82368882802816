import React from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Form,
  Button,
  Label,
  Input,
  FormGroup,
} from "reactstrap";
import notificaiton from "./../../helpers/notificationHelper";
import {
  DAYS_ARRAY,
  SPOKEN_LANGUAG_ARRAY_MEETING,
  UTS_EST_OFFSET,
  MEETING_TYPES,
  MEET_TYPES_ARRAY,
  MEETING_TYPE_ONE_TIME,
  MEETING_TYPE_DAILY,
  MEETING_TYPE_WEEKLY,
} from "./../../inc/constants";
import { Multiselect } from "react-widgets";
import moment from "moment";
import momentT from "moment-timezone";
import { meetingTitleValidator } from "./../../helpers/validationHelpers";
import "react-widgets/dist/css/react-widgets.css";
import Select from "react-select";
import "../../App.css";
export default class AddNewMeeting extends React.Component {
  state = {
    title: "",
    type: "",
    category: "",
    days: [],
    time: "",
    duration: "",
    host: "",
    description: "",
    hosts: "",
    wso_id: "",
    isFetching: false,
    est_time: "",
    spoken_language: ["en"],
    meet_type: "",
    display_date: moment().format("YYYY-MM-DD"),
    meet_date: "",
    test_meet: false,
    multiLang: [],
    monDuration: "",
    tueDuration: "",
    wedDuration: "",
    thuDuration: "",
    friDuration: "",
    satDuration: "",
    sunDuration: "",
    weeklyTimeDurations: [],
    myDuration: [],
    enableMon: "",
    enableTue: "",
    enableWed: "",
    enableThu: "",
    enableFri: "",
    enableSat: "",
    enableSun: "",
    enableData: "",
  };

  componentDidMount() {
    //console.log('this.props add new meeting', this.props);
    let { categories } = this.props;
    this.props._fetchHosts();
    this.props._fetchCategories(true);
  }

  static getDerivedStateFromProps(props, state) {
    //console.log('meeting props', props.meeting);
    if (props.hosts && props.hosts.hosts && props.hosts.users !== state.hosts) {
      return {
        hosts: props.hosts.hosts,
      };
    }
    if (
      props.meeting &&
      props.meeting.isFetching &&
      !props.meeting.err &&
      props.meeting.isFetching !== state.isFetching
    ) {
      //console.log('meeting props.isFetching', props.meeting.isFetching);
      return {
        isFetching: true,
      };
    }
    if (
      props.meeting &&
      !props.meeting.isFetching &&
      props.meeting.isFetching !== state.isFetching
    ) {
      return {
        isFetching: false,
      };
    }
    return null;
  }

  componentDidUpdate(prevProps, prevStates) {
    // console.clear();
    //console.log('props', this.props);
    let { meeting } = this.props;
    if (meeting !== prevProps.meeting) {
      if (meeting.isCreated && !meeting.err && !meeting.isFetching) {
        notificaiton("success", meeting.message);
        return this.props.history.push("/meetings");
      }
      if (!meeting.isCreated && meeting.err && !meeting.isFetching) {
        notificaiton("error", meeting.message);
        // return this.props.history.push('/meetings');
      }
    }
  }

  onChangeHandler = (e) => {
    //console.log('name', e.target.name)
    //console.log('value', e.target.value);
    let { date, time } = this.state;
    let { name, value } = e.target;
    let data = {};
    if (name === "test_meet") {
      data = {
        [name]: e.target.checked,
      };
    } else {
      data = {
        [name]: value,
      };
    }
    //console.log('data', data);
    if (name === "time") {
      // var today = momentT.tz(value, 'h:mm', 'EST').format();
      // today = momentT.tz(today,'EST').utc().format('HH:mm')
      // console.log('today', today)
      let utctime = momentT.tz(value, "hh:mm", "UTC").format("HH:mm");
      const utcOffsetToEST = momentT.tz("America/New_York").utcOffset();
      utctime = moment(utctime, "hh:mm")
        .add(utcOffsetToEST, "minutes")
        .format("HH:mm");
      data["est_time"] = utctime;
    }

    if (name === "type" && value === "daily") {
      this.setState({
        days: DAYS_ARRAY,
      });
    }
    return this.setState(data);
  };

  filterCatTitle = (person, value) => {
    let lastname = person.english_title.toLowerCase();
    let search = value.toLowerCase();
    return lastname.indexOf(search) === 0;
  };

  // filterDays = (person, value) => {
  //     let lastname = person.english_title.toLowerCase()
  //     return lastname.indexOf(search) === 0
  // }

  onSubmitHandler = (e) => {
    //console.log("submitted");
    e.preventDefault();
    let {
      title,
      description,
      type,
      category,
      days,
      time,
      duration,
      host,
      wso_id,
      spoken_language,
      meet_type,
      display_date,
      meet_date,
      test_meet,
      monDuration,
      tueDuration,
      wedDuration,
      thuDuration,
      friDuration,
      satDuration,
      sunDuration,
    } = this.state;
    if (type === "weekly" && days.length < 1)
      return notificaiton(
        "error",
        "Please select atleast one day for a weekly meeting!"
      );
    if (!meet_type || meet_type === "")
      return notificaiton("error", "Please Select the Meeting Type");
    let timeT = moment(time, "HH:mm a").format("HH:mm");
    // console.log('display_date', display_date);
    // console.log('meet_date', meet_date);
    // debugger;
    // }
    let weeklyTimeDurationsCopy = days.map((item) => {
      switch (item) {
        case "monday":
          return { day: item, duration: monDuration ? monDuration : duration };
          break;
        case "tuesday":
          return { day: item, duration: tueDuration ? tueDuration : duration };
          break;
        case "wednesday":
          return { day: item, duration: wedDuration ? wedDuration : duration };
          break;
        case "thursday":
          return { day: item, duration: thuDuration ? thuDuration : duration };
          break;
        case "friday":
          return { day: item, duration: friDuration ? friDuration : duration };
          break;
        case "saturday":
          return { day: item, duration: satDuration ? satDuration : duration };
          break;
        case "sunday":
          return { day: item, duration: sunDuration ? sunDuration : duration };
          break;
      }
    });
    let data = {
      title,
      description,
      type,
      days,
      categories: category,
      time: timeT,
      duration,
      host,
      wso_id,
      spoken_language,
      meet_type,
      display_date,
      test_meet,
    };
    let data1 = {
      title,
      description,
      type,
      days,
      categories: category,
      time: timeT,
      duration,
      host,
      wso_id,
      spoken_language,
      meet_type,
      display_date,
      test_meet,
      weeklyTimeDurations: weeklyTimeDurationsCopy,
    };

    //if(type ==='daily') data.days = DAYS_ARRAY
    if (type === MEETING_TYPE_ONE_TIME && meet_date === "")
      return notificaiton(
        "error",
        "Meeeting Date is required if the meet type is One time !"
      );
    if (type === MEETING_TYPE_ONE_TIME) {
      data.meet_date = meet_date;
      data.days = [moment(meet_date).format("dddd")];
    }
    //console.log('collected data', data);
    if (type === "weekly" || type === "daily") {
      return this.props._createMeeting(data1);
    } else {
      return this.props._createMeeting(data);
    }
  };

  onChangeCategory = (value, name) => {
    let { multiLang } = this.state;
    this.setState({ category: value });
  };

  onDaysChange = (e) => {
    let { days } = this.state;
    let {
      enableMon,
      enableTue,
      enableWed,
      enableThu,
      enableFri,
      enableSat,
      enableSun,
      enableData,
    } = this.state;
    let { value, checked } = e.target;
    //let index = days.indexOf(value);
    let newDays = JSON.parse(JSON.stringify(days));
    let index = newDays.indexOf(value);
    //console.log("index", index)
    if (index > -1) {
      newDays.splice(index, 1);
      if (value === "monday") this.setState({ enableMon: " " });
      if (value === "tuesday") this.setState({ enableTue: " " });
      if (value === "wednesday") this.setState({ enableWed: " " });
      if (value === "thursday") this.setState({ enableThu: " " });
      if (value === "friday") this.setState({ enableFri: " " });
      if (value === "saturday") this.setState({ enableSat: " " });
      if (value === "sunday") this.setState({ enableSun: " " });
    } else {
      newDays.push(value);
      if (value === "monday") this.setState({ enableMon: "monday" });
      if (value === "tuesday") this.setState({ enableTue: "tuesday" });
      if (value === "wednesday") this.setState({ enableWed: "wednesday" });
      if (value === "thursday") this.setState({ enableThu: "thursday" });
      if (value === "friday") this.setState({ enableFri: "friday" });
      if (value === "saturday") this.setState({ enableSat: "saturday" });
      if (value === "sunday") this.setState({ enableSun: "sunday" });
    }
    this.setState({
      days: newDays,
    });
    return;
  };

  renderDays = (type) => {
    let days = DAYS_ARRAY.map((day, i) => {
      return (
        <FormGroup check inline key={i}>
          <Label check>
            <Input
              type="checkbox"
              value={day}
              onChange={this.onDaysChange}
              disabled={type !== "weekly"}
            />{" "}
            {`${day.charAt(0).toUpperCase()}${day.slice(1)}`}
          </Label>
        </FormGroup>
      );
    });
    return days;
  };
  onChangeLanguage = (value, name) => {
    let { spoken_language } = this.state;
    let myLanguage = value.map((val) => val.code);
    this.setState({
      spoken_language: myLanguage,
    });
    // console.log("MyLangValueMulti........",multiLang);
    // console.log("MyLangValue........",value);
    // console.log("MyLangValueCode........",myLanguage);
  };
  renderDuration = (type) => {
    let daysstate = this.state.days;
    //console.log("daysstate", daysstate)
    let days = DAYS_ARRAY.map((day, i) => {
      return (
        <FormGroup check key={i}>
          <Label check className="margin-Top-Check">
            <Input
              type="checkbox"
              value={day}
              checked={
                type === MEETING_TYPE_DAILY ||
                (type === MEETING_TYPE_WEEKLY && daysstate.includes(day))
              }
              onChange={this.onDaysChange}
              disabled={type !== MEETING_TYPE_WEEKLY}
            />{" "}
            {`${day.charAt(0).toUpperCase()}${day.slice(1)}`}
          </Label>
        </FormGroup>
      );
    });
    return days;
  };
  render() {
    let {
      title,
      type,
      description,
      day,
      time,
      category,
      duration,
      wso_id,
      hosts,
      isFetching,
      est_time,
      meet_type,
      display_date,
      meet_date,
      test_meet,
      multiLang,
      enableMon,
      enableTue,
      enableWed,
      enableThu,
      enableFri,
      enableSat,
      enableSun,
      monDuration,
      tueDuration,
      wedDuration,
      thuDuration,
      friDuration,
      satDuration,
      sunDuration,
    } = this.state;
    //console.log('hosts in render', display_date)
    let { categories } = this.props;
    categories = categories && categories.length > 0 ? categories : [];
    var multiSelect = [];
    multiSelect = SPOKEN_LANGUAG_ARRAY_MEETING.map(
      (val) => {
        return {
          code: val.code,
          spokenLang: val.name,
        };
      }
      // value:val.code,
      // label:val.name
    );
    return (
      <Container>
        <Row>
          <Col md={12}>
            <Card>
              <CardBody className="bg-white ">
                <h3 className="page-title bb-1 border-gray mb-4">
                  Create Meeting
                </h3>
                <Row className="mt-12">
                  <Col md={12} className="text-right">
                    <Button color="primary" href="/meetings">
                      List
                    </Button>
                  </Col>
                </Row>
                <span className="text-muted">
                  Note :- All the meetings will be created in EST.{" "}
                </span>
                <Row className="mt-3">
                  <Col md={12}>
                    <Form onSubmit={this.onSubmitHandler}>
                      <Row form className="mb-3">
                        <Col md={12} sm={12}>
                          <Label className="text-muted">Title:</Label>
                          <Input
                            type="text"
                            name="title"
                            value={title}
                            onChange={this.onChangeHandler}
                            required
                            placeholder="Enter Title"
                          />
                        </Col>
                      </Row>
                      <Row className="mb-2">
                        <Col md={12} sm={12} className="mb-2">
                          <h4 className="bb-1 border-gray">Schedule</h4>
                        </Col>
                        <Col md={3} sm={12}>
                          <Label htmlFor="typeselect">Occurance Type</Label>
                          <Input
                            type="select"
                            onChange={this.onChangeHandler}
                            value={type}
                            defaultValue={type}
                            name="type"
                            id="typeselect"
                          >
                            <option value="">Select Meeting Type</option>
                            {MEET_TYPES_ARRAY &&
                              MEET_TYPES_ARRAY.map((m) => {
                                return <option value={m.type}>{m.name}</option>;
                              })}
                          </Input>
                        </Col>
                        <Col md={3} sm={12}>
                          <Label htmlFor="typeselect">Start Date</Label>
                          <Input
                            type="date"
                            onChange={this.onChangeHandler}
                            value={display_date}
                            name="display_date"
                          />
                        </Col>
                        <Col md={3} sm={12}>
                          <Label className="text-muted margin-Top">
                            Default Duration
                          </Label>
                          <Input
                            type="number"
                            name="duration"
                            value={duration}
                            onChange={this.onChangeHandler}
                            required
                            placeholder="Enter Duration: (in mins)"
                          />
                        </Col>
                        {/*<Col md={6} sm={12}>
                                                    <Label htmlFor="">Days</Label>
                                                    <div>
                                                      {this.renderDays(type)}
                                                    </div>
                                                    </Col>*/}
                        <Col lg={12} md={12} sm={12}>
                          <FormGroup>
                            <Row>
                              <Col lg={6} md={6} sm={4}>
                                <Label htmlFor="">Days</Label>
                                <div>{this.renderDuration(type)}</div>
                              </Col>
                              <Col lg={6} md={6} sm={8}>
                                <Label htmlFor="">Duration: (in mins)</Label>
                                <Input
                                  type="number"
                                  name="monDuration"
                                  value={monDuration}
                                  className="margin-Top w-75 forResp text-center"
                                  placeholder="Enter Duration"
                                  bsSize="sm"
                                  //required
                                  //disabled={type!=="weekly" || enableMon!=="monday" }
                                  onChange={this.onChangeHandler}
                                />
                                <Input
                                  type="number"
                                  name="tueDuration"
                                  value={tueDuration}
                                  className="margin-Top w-75 forResp text-center"
                                  placeholder="Enter Duration"
                                  bsSize="sm"
                                  //required
                                  //disabled={type!=="weekly" || enableTue!=="tuesday"}
                                  onChange={this.onChangeHandler}
                                />
                                <Input
                                  type="number"
                                  name="wedDuration"
                                  value={wedDuration}
                                  className="margin-Top w-75 forResp text-center"
                                  placeholder="Enter Duration"
                                  bsSize="sm"
                                  //required
                                  //disabled={type!=="weekly" || enableWed!=="wednesday"}
                                  onChange={this.onChangeHandler}
                                />
                                <Input
                                  type="number"
                                  name="thuDuration"
                                  value={thuDuration}
                                  className="margin-Top w-75 forResp text-center"
                                  placeholder="Enter Duration"
                                  bsSize="sm"
                                  //required
                                  //disabled={type!=="weekly" || enableThu!=="thursday"}
                                  onChange={this.onChangeHandler}
                                />
                                <Input
                                  type="number"
                                  name="friDuration"
                                  value={friDuration}
                                  className="margin-Top w-75 forResp text-center"
                                  placeholder="Enter Duration"
                                  bsSize="sm"
                                  //required
                                  //disabled={type!=="weekly" || enableFri!=="friday"}
                                  onChange={this.onChangeHandler}
                                />
                                <Input
                                  type="number"
                                  name="satDuration"
                                  value={satDuration}
                                  className="margin-Top w-75 forResp text-center"
                                  placeholder="Enter Duration"
                                  bsSize="sm"
                                  //required
                                  //disabled={type!=="weekly" || enableSat!=="saturday"}
                                  onChange={this.onChangeHandler}
                                />
                                <Input
                                  type="number"
                                  name="sunDuration"
                                  value={sunDuration}
                                  className="margin-Top w-75 forResp text-center"
                                  placeholder="Enter Duration"
                                  bsSize="sm"
                                  //required
                                  //disabled={type!=="weekly" || enableSun!=="sunday"}
                                  onChange={this.onChangeHandler}
                                />
                              </Col>
                            </Row>
                          </FormGroup>
                        </Col>
                        <Col md={3} sm={12}>
                          <Label className="text-muted margin-Top">
                            Date:{" "}
                          </Label>
                          <Input
                            type="date"
                            disabled={type === "one_time" ? false : true}
                            name="meet_date"
                            value={meet_date}
                            onChange={this.onChangeHandler}
                          />
                        </Col>
                        <Col md={3} sm={12}>
                          <Label className="text-muted margin-Top">
                            Time:{" "}
                            <span className="text-muted">{`EST :- ${est_time}`}</span>
                          </Label>
                          <Input
                            type="time"
                            name="time"
                            value={time}
                            onChange={this.onChangeHandler}
                            required
                            placeholder="Enter Country Code"
                          />
                        </Col>
                        {/* <Col md={3} sm={12}>
                                                    <Label className="text-muted margin-Top">Duration: (in mins)</Label>
                                                    <Input type="number" name="duration" value ={duration}
                                                        onChange={ this.onChangeHandler } required
                                                        placeholder = "Enter Duration" 
                                                    />
                                                </Col>*/}
                        <Col md={3} sm={12}>
                          <Label htmlFor="typeselect" className="margin-Top">
                            Spoken Language
                          </Label>
                          <Multiselect
                            data={multiSelect}
                            onChange={this.onChangeLanguage}
                            textField="spokenLang"
                          />
                          {/* <Select isMulti options={multiSelect} ></Select> */}

                          {/* <Input type="select" name="spoken_language" id="typeselect" defaultValue="" onChange={this.onChangeHandler}>
                                                        <option value="" ></option>
                                                        {SPOKEN_LANGUAG_ARRAY_MEETING && SPOKEN_LANGUAG_ARRAY_MEETING.length > 0 && (
                                                            SPOKEN_LANGUAG_ARRAY_MEETING.map((lang) => {
                                                                return (
                                                                    <option key={lang.name} value={lang.code}>{lang.name}</option>
                                                                )
                                                            })
                                                        )}
                                                    </Input> */}
                        </Col>
                      </Row>
                      <Row form className="mb-3">
                        <Col md={4} sm={12}>
                          <Label htmlFor="typeselect">Meet Type</Label>
                          <Input
                            type="select"
                            name="meet_type"
                            id="typeselect"
                            defaultValue=""
                            onChange={this.onChangeHandler}
                          >
                            <option value={meet_type}>Select</option>
                            {MEETING_TYPES &&
                              MEETING_TYPES.length > 0 &&
                              MEETING_TYPES.map((m) => {
                                return (
                                  <option key={m.type} value={m.type}>
                                    {m.name}
                                  </option>
                                );
                              })}
                          </Input>
                        </Col>
                        <Col md={4} sm={12}>
                          <Label htmlFor="typeselect">Host</Label>
                          <Input
                            type="select"
                            name="host"
                            id="typeselect"
                            defaultValue=""
                            onChange={this.onChangeHandler}
                          >
                            <option value=""></option>
                            {hosts &&
                              hosts.length > 0 &&
                              hosts.map((host) => {
                                return (
                                  <option key={host._id} value={host._id}>
                                    {host.email.mail}
                                  </option>
                                );
                              })}
                          </Input>
                        </Col>

                        <Col md={4} sm={12}>
                          <Label className="text-muted">WSO ID:</Label>
                          <Input
                            type="wso_id"
                            name="wso_id"
                            value={wso_id}
                            onChange={this.onChangeHandler}
                            required
                            placeholder="Enter WSO ID"
                          />
                        </Col>
                      </Row>
                      <Row form className="mb-3">
                        <Col md={9} sm={12}>
                          <Label className="text-muted">
                            Select Categories:
                          </Label>
                          <Multiselect
                            data={categories}
                            onChange={this.onChangeCategory}
                            textField="english_title"
                            valueField="id"
                            filter={this.filterCatTitle}
                          />
                        </Col>
                        <Col md={3} sm={12} className={"pt-4"}>
                          <FormGroup check>
                            <Label check>
                              <Input
                                type="checkbox"
                                name="test_meet"
                                value={test_meet}
                                onChange={this.onChangeHandler}
                              />
                              Test Meeting
                            </Label>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row className="text-right mb-2">
                        <Col md={12} sm={12}>
                          <Input
                            type="textarea"
                            name="description"
                            value={description}
                            onChange={this.onChangeHandler}
                            placeholder="Enter Description"
                          />
                        </Col>
                      </Row>
                      <Row className="text-right">
                        <Col md={12} sm={12}>
                          {isFetching ? (
                            <Button color="default" disabled>
                              Creating...
                            </Button>
                          ) : (
                            <Button color="primary" type="submit">
                              Create Meeting
                            </Button>
                          )}
                        </Col>
                      </Row>
                    </Form>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}
