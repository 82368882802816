import {
  GETALLRESOURCESREQUEST, GETALLRESOURCESSUCCESS, GETALLRESOURCESERROR, 
  UPLOADRESOURCESREQUEST, UPLOADRESOURCESSUCCESS, UPLOADRESOURCESERROR, 
  UPDATERESOURCESREQUEST, UPDATERESOURCESSUCCESS, UPDATERESOURCESERROR ,
  DELETERESOURCESREQUEST , DELETERESOURCESSUCCESS, DELETERESOURCESERROR
}  from './actionTypes';
import * as resources from './../../api/resourcesApi'

export function  fetchResourcesRequest() {
    return {
      type: GETALLRESOURCESREQUEST,
      payload:{}
    };
}
    
export function fetchResourcesSuccess(data) {
    return {
        type: GETALLRESOURCESSUCCESS,
        payload : data,
    };
}
    
export function  fetchResourcesError(message) {
    return {
        type: GETALLRESOURCESERROR,
        payload : { message },
    };
}

export function  uploadResourceRequest() {
    return {
      type: UPLOADRESOURCESREQUEST,
      payload:{}
    };
  }

export function uploadResourceSuccess(message) {
    return {
      type: UPLOADRESOURCESSUCCESS,
      payload : { message },
    };
}
   
export function  uploadResourceError(message) {
      return {
        type: UPLOADRESOURCESERROR,
        payload : { message },
      };
}

export function  updateResourceRequest() {
    return {
      type: UPDATERESOURCESREQUEST,
      payload:{}
    };
  }

export function updateResourceSuccess(message) {
    return {
      type: UPDATERESOURCESSUCCESS,
      payload : { message },
    };
}
   
export function  updateResourceError(message) {
      return {
        type: UPDATERESOURCESERROR,
        payload : { message },
      };
}

export function  deleteResourceRequest() {
  return {
    type: DELETERESOURCESREQUEST,
    payload:{}
  };
}

export function deleteResourceSuccess(message) {
  return {
    type: DELETERESOURCESSUCCESS,
    payload : { message },
  };
}
 
export function  deleteResourceError(message) {
    return {
      type: DELETERESOURCESERROR,
      payload : { message },
    };
}

export const fetchResources = (page, limit, type) =>{
    return (dispatch) => {
        resources.fetchResourcesList(page, limit, type).then((result)=>{
            console.log('responseData*****************************', result)
            if(result.status === 200) return dispatch(fetchResourcesSuccess(result.data));
            if(result.status === 400) return dispatch(fetchResourcesError(result.data.message));
            if(result.status === 401) return dispatch(fetchResourcesError(result.data.message));
            if(result.status === 409) return dispatch(fetchResourcesError(result.data.message));
        }).catch((err)=>{
            console.log('err', err)
            return dispatch(fetchResourcesError(err.message));
        })
    }
  }  

 export const uploadResource = (data) => {
     return (dispatch) => {
         resources.createResources(data).then((result)=>{
            if(result.status === 200) return dispatch(uploadResourceSuccess(result.data.message));
            if(result.status === 400) return dispatch(uploadResourceError(result.data.message));
            if(result.status === 401) return dispatch(uploadResourceError(result.data.message));
            if(result.status === 409) return dispatch(uploadResourceError(result.data.message));  
         }).catch((err)=>{
            console.log('err', err)
            return dispatch(uploadResourceError(err.message));
        })
     }
 } 

 export const updateResource = (data) => {
    return (dispatch) => {
        resources.updateResource(data).then((result)=>{
           if(result.status === 200) return dispatch(updateResourceSuccess(result.data.message));
           if(result.status === 400) return dispatch(updateResourceError(result.data.message));
           if(result.status === 401) return dispatch(updateResourceError(result.data.message));
           if(result.status === 409) return dispatch(updateResourceError(result.data.message));  
        }).catch((err)=>{
           console.log('err', err)
           return dispatch(updateResourceError(err.message));
       })
    }
} 

export const deleteResource = (resourceId) => {
  return (dispatch) => {
      resources.deleteResource(resourceId).then((result)=>{
         if(result.status === 200) return dispatch(deleteResourceSuccess(result.data.message));
         if(result.status === 400) return dispatch(deleteResourceError(result.data.message));
         if(result.status === 401) return dispatch(deleteResourceError(result.data.message));
         if(result.status === 409) return dispatch(deleteResourceError(result.data.message));  
      }).catch((err)=>{
         console.log('err', err)
         return dispatch(deleteResourceError(err.message));
     })
  }
} 
