import * as Actions from './actionTypes';
import * as catFuncs from './../../api/categoryApis';


export function  getAllCategoriesRequest() {
    return {
      type: Actions.GETALLCATEGORIESSREQUEST,
      payload:{}
    };
  }
   
  export function getAllCategoriesSuccess(data) {
    return {
      type: Actions.GETALLCATEGORIESSUCCESS,
      payload : data,
    };
  }
   
  export function  getAllCategoriesError(message) {
      return {
        type: Actions.GETALLCATEGORIESSERROR,
        payload : { message },
      };
    }

    export function  createCategoryRequest() {
      return {
        type: Actions.CREATECATEGORYREQUEST,
        payload:{}
      };
    }
     
    export function createCategorySuccess(data) {
      return {
        type: Actions.CREATECATEGORYSUCCESS,
        payload : data,
      };
    }
     
    export function  createCategoryError(message) {
        return {
          type: Actions.CREATECATEGORYERROR,
          payload : { message },
        };
      }
   
   


export const addTtitleCategory = (titleData) =>{
    return (dispatch) => {
        dispatch(createCategoryRequest());
        catFuncs.craeteNewCategory(titleData).then((res)=>{
            console.log('responseData*****************************', res)
        if(res.status === 200) return dispatch(createCategorySuccess(res.data));
        if(res.status === 400) return dispatch(createCategoryError(res.data.message));
        if(res.status === 401) return dispatch(createCategoryError(res.data.message));
        if(res.status === 409) return dispatch(createCategoryError(res.data.message));

        }).catch((err)=>{
            console.log('err', err)
            return dispatch(createCategoryError(err.message));
        })
    }
}

export const editCategory = (data) =>{
  return (dispatch) => {
      dispatch(createCategoryRequest());
      catFuncs.updateCategory(data).then((res)=>{
          console.log('responseData*****************************', res)
      if(res.status === 200) return dispatch(createCategorySuccess(res.data));
      if(res.status === 400) return dispatch(createCategoryError(res.data.message));
      if(res.status === 401) return dispatch(createCategoryError(res.data.message));
      if(res.status === 409) return dispatch(createCategoryError(res.data.message));

      }).catch((err)=>{
          console.log('err', err)
          return dispatch(createCategoryError(err.message));
      })
  }
}

export const listAllCategories = (page, limit, isActive) =>{
    return (dispatch) => {
        dispatch(getAllCategoriesRequest());
        catFuncs.getAllCategories(page, limit, isActive).then((res)=>{
        if(res.status === 200) return dispatch(getAllCategoriesSuccess(res.data));
        if(res.status === 400) return dispatch(getAllCategoriesError(res.data.message));
        if(res.status === 401) return dispatch(getAllCategoriesError(res.data.message));
        if(res.status === 409) return dispatch(getAllCategoriesError(res.data.message));
        }).catch((err)=>{
            console.log('err', err)
            return dispatch(getAllCategoriesError(err.message));
        })
    }
}


