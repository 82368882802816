import * as Actions from '../actions/actionTypes';

const initialState = {
    isFetching: false,
    isFetched: false,
    error:false,
    message : '',
    data:[]
  };
  
  // receiving response sent by action according to type of action
  export default function notificationsReducers(state = initialState, action) {
      //console.log(action,'action');
      let { payload } = action;
    switch (action.type) {
      case Actions.CREATENOTIFICATIONSREQUEST:
        return { 
            isFetching: true,
            isFetched: false,
            error:false,
            data:[],
            message : ''
        };
        break;
  
      case Actions.CREATENOTIFICATIONSSUCCESS:
        return { 
            isFetching: false,
            isFetched: true,
            error:false,
            message : payload.message,
            data: payload 
        };
        break;
  
      case Actions.CREATENOTIFICATIONSERROR:
        return { 
            isFetching: false,
            isFetched: false,
            error:true,
            data: payload,
            message : payload.message,

         };
        break;
        break;
      default:
        return state;
    }
  }
  