import { connect } from "react-redux";
import TermsAndConditions from '../../components/TermsAndConditions/TermsAndConditions';
import { postTCContent, fetchTCContent } from '../../redux/actions/TermsAndConditions';
 
const mapStateToProps = state => {
  console.log('state in container', state);
  return {
    premiumContent : state.premiumContent,
    listTermsConditions : state.listTermsConditions
  };
};
 
const mapDispatchToProps = (dispatch) => {
  return {
    _postPremiumContent : (data) => {
      return dispatch(postTCContent(data));
    },
    _fetchPremiumContent : () => {
      return dispatch(fetchTCContent());
    }
  };
};
 
const TermsAndConditionsContainer = connect(mapStateToProps, mapDispatchToProps)(
    TermsAndConditions
);
 
export default TermsAndConditionsContainer;