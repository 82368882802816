import React, {PureComponent} from 'react';
import { Link } from 'react-router-dom';
import AccountOutlineIcon from 'mdi-react/AccountOutlineIcon';
import { Redirect } from 'react-router-dom';
import { Input, Button } from 'reactstrap'
import notification from '../../../helpers/notificationHelper';
import {emailValidator} from '../../../helpers/validationHelpers';

class ForgotPassword extends PureComponent {

    state = {
      showPassword: false,
      email :'',
      isFetching : true
    }

    onChangeHandler = (name,value) =>{
      this.setState({ [name] : value });
    }

    handlePasswordReset= () => {
      let { email } = this.state; 
      if(!emailValidator(email)) return notification('error', 'Please Enter a valid email address!')
      return this.props.resetPasswordRequest(email);
    }

    static getDerivedStateFromProps(props, state){  
      //console.log('props', props);
      //console.log('state', state);
      if(props.forgotPassword.isFetching !== state.isFetching){
        return {
          isFetching : props.forgotPassword.isFetching
        }
      }
      return null;
    }

    componentDidUpdate(prevProps, prevStates){
      // console.log('forgot pass props', this.props)
      // console.log('forgot pass prevProps', prevProps)
      if(this.props.forgotPassword !== prevProps.forgotPassword && !this.props.forgotPassword.isFetching && this.props.forgotPassword.requestSuccess  ) {
        notification('success', 'A Verification link has been sent to your Email address');
      }
    }
  
  render() {
    //console.log('props login', this.state)
    const {handleSubmit, authDone, isLoggedIn} = this.props;
    let { email, isFetching } = this.state;
    
    return (
    <>  
          {isFetching && (
                  <div className={`load${isFetching ? '' : ' loaded'}`}>
                      <div className='load__icon-wrap'>
                          <svg className='load__icon'>
                          <path fill='#4ce1b6' d='M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z'/>
                          </svg>
                      </div>
                  </div>  
                )} 
        <div className='account'>
        <div className='account__wrapper'>
          <div className='account__card'>
            <div className='account__head'>
              <h3 className='account__title'>Welcome to <span className='account__logo'>AlAnon<span
                className='account__logo-accent'>DEV</span></span></h3>
              <h4 className='account__subhead subhead'>Start your business easily</h4>
            </div>   
                <form className='form' onSubmit={handleSubmit}>
                <div className='form__form-group'>
                    <label className='form__form-group-label'>Email</label>
                    <div className='form__form-group-field'>
                    <div className='form__form-group-icon'>
                        <AccountOutlineIcon/>
                    </div>
                        <Input
                            name='email'
                            type='text'
                            placeholder='Enter Email'
                            value={ email }
                            onChange={ (e)=>{ this.onChangeHandler(e.target.name, e.target.value) } }
                        />
                    </div>
                    <div className='account__forgot-password'>
                      <Link to='/login'>Log In</Link>
                    </div>
                </div>
                <div className='form__form-group'>
                </div>
                {isFetching ? (
                  <Button className='btn btn-primary account__btn account__btn--small' disabled>Submit</Button>
                ) : (
                  <Button className='btn btn-primary account__btn account__btn--small' onClick={ () => { this.handlePasswordReset() } }>Submit</Button>
                )}
                </form>
                {(authDone && isLoggedIn) && (<Redirect to="/" />)}
          </div>
        </div>
      </div>
  </>  
    )
  }
}


export default ForgotPassword;
