import { connect } from "react-redux";
import * as _ from "lodash";
import ListFinderMeetings from "../../components/FinderMeetings/ListFinderMeetings";
import { getMeetingInLanguage, listAllFinderMeetings, updateFinderMeet, uploadFinderMeets,translateFinderMeets, translateSingleMeeting } from "../../redux/actions/finderMeetingActions";

const mapStateToProps = state => {
  return {
    findermeetings : state.finderMeetings,
    finderMeeting : state.finderMeeting,
    updateFinderMeetReducer : state.updateFinderMeetReducer,
    uploadFinderMeetings : state.uploadFinderMeetings,
    translateFinderMeets : state.translateFinderMeets,
    translateSingleMeet : state.translateSingleMeet
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    _fetchfindermeetings: (page , limit, search) => {
      return dispatch(listAllFinderMeetings(page, limit, search));
    },
    _fetchfindermeetingDetails : (meetingId, language) => {
      return dispatch(getMeetingInLanguage(meetingId, language))
    },
    _updatefindermeeting : (data) => {
      return dispatch(updateFinderMeet(data))
    },
    _uploadfindermeetings : (data) => {
      return dispatch(uploadFinderMeets(data))
    },
    _translatefindermeetings : (data) => {
      return dispatch(translateFinderMeets(data))
    },
    _translateSingleFinderMeeting : (data) => {
      return dispatch(translateSingleMeeting(data))
    }
  };
};


const GetFinderMeetings = connect(mapStateToProps, mapDispatchToProps)(ListFinderMeetings);

export default GetFinderMeetings;
