import {GETFINDERMEETINGSCLEAR, GETFINDERMEETINGSSUCCESS, GETFINDERMEETINGSERROR, GETFINDERMEETINGSREQUEST,
    GETFINDERMEETINGSUCCESS, GETFINDERMEETINGERROR, GETFINDERMEETINGREQUEST,
    UPDATEFINDERMEETINGREQUEST, UPDATEFINDERMEETINGSUCCESS, UPDATEFINDERMEETINGERROR,
    UPLOADFINDERMEETINGCLEAR, UPLOADFINDERMEETINGERROR, UPLOADFINDERMEETINGREQUEST, UPLOADFINDERMEETINGSUCCESS,
    TRANSLATEFINDERMEETINGCLEAR, TRANSLATEFINDERMEETINGERROR, TRANSLATEFINDERMEETINGREQUEST, TRANSLATEFINDERMEETINGSUCCESS, 
    TRANSLATESINGLEMEETREQUEST, TRANSLATESINGLEMEETSUCCESS, TRANSLATESINGLEMEETERROR
} from './actionTypes';
import { listFinderMeetings, getMeetingInAnyLanguage, updateFinderMeeting,uploadFinderMeetings, translateFinderMeetings, translateSingleMeet } from '../../api/finderMeetings';

export function  getAllFinderMeetingsRequest() {
    return {
        type: GETFINDERMEETINGSREQUEST,
        payload:{}
    };
}

export function getAllFinderMeetingsSuccess(data) {
    return {
        type: GETFINDERMEETINGSSUCCESS,
        payload : {
            finderMeetings : data.data,
            total : data.totalCount,
            configs : data.configs
        },
    };
}

export function getAllFinderMeetingsError(message) {
    return {
        type: GETFINDERMEETINGSERROR,
        payload : { message },
    };
}

export function getMeetingInLanguageRequest() {
    return {
        type: GETFINDERMEETINGREQUEST,
        payload:{}
    };
}

export function getMeetingInLanguageSuccess(data) {
    return {
        type: GETFINDERMEETINGSUCCESS,
        payload : { data },
    };
}

export function getMeetingInLanguageError(message) {
    return {
        type: GETFINDERMEETINGERROR,
        payload : { message },
    };
}

export function updateFinderMeetingRequest() {
    return {
        type: UPDATEFINDERMEETINGREQUEST,
        payload:{}
    };
}

export function updateFinderMeetingSuccess(message) {
    return {
        type: UPDATEFINDERMEETINGSUCCESS,
        payload : { message },
    };
}

export function updateFinderMeetingError(message) {
    return {
        type: UPDATEFINDERMEETINGERROR,
        payload : { message },
    };
}

export function  uploadFinderMeetingsRequest() {
    return {
        type: UPLOADFINDERMEETINGREQUEST,
        payload:{}
    };
}

export function uploadFinderMeetingsSuccess(message) {
    return {
        type: UPLOADFINDERMEETINGSUCCESS,
        payload : {
            message
        },
    };
}

export function uploadFinderMeetingsError(message) {
    return {
        type: UPLOADFINDERMEETINGERROR,
        payload : { message },
    };
}

export function translateFinderMeetingsRequest() {
    return {
        type: TRANSLATEFINDERMEETINGREQUEST,
        payload:{}
    };
}

export function translateFinderMeetingsSuccess(message) {
    return {
        type: TRANSLATEFINDERMEETINGSUCCESS,
        payload : {
            message
        },
    };
}

export function translateFinderMeetingsError(message) {
    return {
        type: TRANSLATEFINDERMEETINGERROR,
        payload : { message },
    };
}

export function translateSingleMeetRequest() {
    return {
        type: TRANSLATESINGLEMEETREQUEST,
        payload:{}
    };
}

export function translateSingleMeetSuccess(message) {
    return {
        type: TRANSLATESINGLEMEETSUCCESS,
        payload : {
            message
        },
    };
}

export function translateSingleMeetError(message) {
    return {
        type: TRANSLATESINGLEMEETERROR,
        payload : { message },
    };
}

export const listAllFinderMeetings = (page, limit, search) =>{
    return (dispatch) => {
        dispatch(getAllFinderMeetingsRequest());
        listFinderMeetings(page, limit, search).then((res)=>{
        if(res.status === 200) return dispatch(getAllFinderMeetingsSuccess(res.data));
        if(res.status === 400) return dispatch(getAllFinderMeetingsError(res.data.message));
        if(res.status === 401) return dispatch(getAllFinderMeetingsError(res.data.message));
        if(res.status === 409) return dispatch(getAllFinderMeetingsError(res.data.message));
        }).catch((err)=>{
            console.log('err', err)
            return dispatch(getAllFinderMeetingsError(err.message));
        })
    }
}

export const getMeetingInLanguage = (meetingId, language) =>{
    return (dispatch) => {
        dispatch(getMeetingInLanguageRequest());
        getMeetingInAnyLanguage(meetingId, language).then((res)=>{
        if(res.status === 200) return dispatch(getMeetingInLanguageSuccess(res.data));
        if(res.status === 400) return dispatch(getMeetingInLanguageError(res.data.message));
        if(res.status === 401) return dispatch(getMeetingInLanguageError(res.data.message));
        if(res.status === 409) return dispatch(getMeetingInLanguageError(res.data.message));
        }).catch((err)=>{
            console.log('err', err)
            return dispatch(getMeetingInLanguageError(err.message));
        })
    }
}

export const updateFinderMeet = (data) => {
    return (dispatch) => {
        dispatch(updateFinderMeetingRequest());
        updateFinderMeeting(data).then((res)=>{
            if(res.status === 200) return dispatch(updateFinderMeetingSuccess(res.data.message));
            if(res.status === 400) return dispatch(updateFinderMeetingError(res.data.message));
            if(res.status === 401) return dispatch(updateFinderMeetingError(res.data.message));
            if(res.status === 409) return dispatch(updateFinderMeetingError(res.data.message));
        }).catch((err)=>{
            console.log('err', err)
            return dispatch(updateFinderMeetingError(err.message));
        })
    }
}

export const uploadFinderMeets = (data) => {
    return (dispatch) => {
        dispatch(uploadFinderMeetingsRequest());
        uploadFinderMeetings(data).then((res)=>{
            if(res.status === 200) return dispatch(uploadFinderMeetingsSuccess(res.data.message));
            if(res.status === 400) return dispatch(uploadFinderMeetingsError(res.data.message));
            if(res.status === 401) return dispatch(uploadFinderMeetingsError(res.data.message));
            if(res.status === 409) return dispatch(uploadFinderMeetingsError(res.data.message));
        }).catch((err)=>{
            console.log('err', err)
            return dispatch(uploadFinderMeetingsError(err.message));
        })
    }
}

export const translateFinderMeets = (data) => {
    return (dispatch) => {
        dispatch(translateFinderMeetingsRequest());
        translateFinderMeetings(data).then((res)=>{
            if(res.status === 200) return dispatch(translateFinderMeetingsSuccess(res.data.message));
            if(res.status === 400) return dispatch(translateFinderMeetingsError(res.data.message));
            if(res.status === 401) return dispatch(translateFinderMeetingsError(res.data.message));
            if(res.status === 409) return dispatch(translateFinderMeetingsError(res.data.message));
        }).catch((err)=>{
            console.log('err', err)
            return dispatch(translateFinderMeetingsError(err.message));
        })
    }
}

export const translateSingleMeeting = (data) => {
    return (dispatch) => {
        dispatch(translateSingleMeetRequest());
        translateSingleMeet(data).then((res)=>{
            if(res.status === 200) return dispatch(translateSingleMeetSuccess(res.data.message));
            if(res.status === 400) return dispatch(translateSingleMeetError(res.data.message));
            if(res.status === 401) return dispatch(translateSingleMeetError(res.data.message));
            if(res.status === 409) return dispatch(translateSingleMeetError(res.data.message));
        }).catch((err)=>{
            console.log('err', err)
            return dispatch(translateSingleMeetError(err.message));
        })
    }
}
