import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

const MeetingDetailsModal = (props) => {
  const {
    buttonLabel,
    className,
    toggle,
    modal,
    func,
    confirmAction,
    modalTitle,
    modalText,
    modalFor,
  } = props;

  //console.log('data modal meeting', props);
  return (
    <div  style={{textAlign:'center'}}>
      {/* <Button color="danger" onClick={toggle}>{buttonLabel}</Button> */}
      <Modal isOpen={modal} fade={true} toggle={toggle} keyboard={false} backdrop={false}>
          <span>
            <ModalHeader toggle={toggle}>{modalTitle}</ModalHeader>
          </span>
        <ModalBody>
            <div>
                {modalText}
            </div>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" name ={modalFor} onClick={(e)=>confirmAction(e)}>Yes{' '}</Button>{' '}
          <Button color="secondary" onClick={toggle}>Close</Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default MeetingDetailsModal;