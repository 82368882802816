// import { LOGIN, ERROR, FORGETPASSWORD } from '../actions/login_action';
import {LOGINSUCCESS, LOGINCHECKFAILURE, LOGINERROR, LOGINREQUEST, LOGOUTERROR, LOGOUTREQUEST,LOGOUTSUCCESS } from './../actions/actionTypes'

const initialState = {
    authDone: false,
    isLoggedIn: false,
    loginRequest:false,
    isFetching:false,
    user:'',
    data:''
  };
  
  // receiving response sent by action according to type of action
  export default function loginReducers(state = initialState, action) {
      //console.log(action,'action');
      let { payload } = action
    switch (action.type) {
      case LOGINSUCCESS:
        return { 
          isLoggedIn: true,
          isLoggedOut: false,
          authDone: true,
          user : payload.user
        };
        break;
  
      case LOGINERROR:
        return { 
          isLoggedIn: false,
          authDone:true,
          loginRequest: false,
          err: payload.message
        };
        break;
  
      case LOGINREQUEST:
        return { 
          loginRequest: true,
          authDone:false,
          err:''
         };
        break;
      
      case LOGINCHECKFAILURE:
        return { 
          isLoggedIn: false
         };
        break;

      case 'SIGNUPSUCCESS':
        return { 
          signUp: action.payload,
          isLoggedIn : true,
          user:action.payload.user
         };
        break;
      
      case LOGOUTREQUEST:
        return { 
          ...state,
          isFetching : true,
          err:''

        };
        break;
        
        case LOGOUTSUCCESS:
        return { 
          isLoggedIn: false,
          isFetching : false,
          isLoggedOut: true,
          authDone: true,
          user : '',
          err:action.message

        };
        break;
        
        case LOGOUTERROR:
        return { 
          ...state,
          isFetching : false,
          isLoggedOut: false,
          authDone:true,
          err:action.message

        };
        break;
      default:
        return state;
    }
  }
  