import * as Actions from './actionTypes';
import * as userTracking from './../../api/userTracking'

export function getUserTrackingList() {
    return {
      type: Actions.GETUSERTRACKINGLISTREQUEST,
      payload:{}
    };
}

export function getUserTrackingListSuccess(data) {
    return {
      type: Actions.GETUSERTRACKINGLISTSUCCESS,
      payload : data,
    };
}

export function  getUserTrackingListError(message) {
    return {
      type: Actions.GETUSERTRACKINGLISTERROR,
      payload : { message },
    };
}

export const userTrackingList = (page, limit) =>{
    return (dispatch) => {
        userTracking.fetchUserTrackingList(page, limit).then((result)=>{
            console.log('responseData*****************************', result)
            if(result.status === 200) return dispatch(getUserTrackingListSuccess(result.data));
            if(result.status === 400) return dispatch(getUserTrackingListError(result.data.message));
            if(result.status === 401) return dispatch(getUserTrackingListError(result.data.message));
            if(result.status === 409) return dispatch(getUserTrackingListError(result.data.message));
        }).catch((err)=>{
            console.log('err', err)
            return dispatch(getUserTrackingListError(err.message));
        })
    }
  }  