import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Nav, NavItem, NavLink, TabContent, TabPane 
} from "reactstrap";
import TableComponent from "../Shared/Table/Table";
import TablePagination from './../Shared/Table/TablePagination';
import EyeIcon from 'mdi-react/EyeIcon';
import PenIcon from 'mdi-react/PencilIcon';
import DeleteIcon from 'mdi-react/CancelIcon';
import notify from "./../../helpers/notificationHelper";
import moment from 'moment';

const AddResources = React.lazy(() => import('./AddResources'));
const EditResources = React.lazy(() => import('./EditResources'));
const DeleteResource = React.lazy(() => import('./DeleteResource'));


const Resources = (props) => {
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(100);
    const [total, setTotal] = useState(10);
    const [content, setContent] = useState([]);
    const [type, setType] = useState('');
    const [link, setLink] = useState('');
    const [name, setName] = useState('');
    const [title, setTitle] = useState('');
    const [resourceId, setResourceId] = useState('');
    const [isFetched, setFetched] = useState(false);
    const [modalAdd, setModalAdd] = useState(false);
    const [modalEdit, setModalEdit] = useState(false);
    const [modalDelete, setModalDelete] = useState(false);
    const [file, setFile] = useState(null)
    const [fileType, setFileType] = useState(null)
    const [thumbnail, setThumbnail] = useState(null)
    const [thumbnailTemp, setThumbnailTemp] = useState(null)
    const [activeTab, setActiveTab] = useState('media');
    const [language, setLanguage] = useState('')

    const editRecord = (record) => {
        let id = record.id;
        console.log('record', record)
    }

    let renderIndex=(record) => {
        let index = record.rowIndex + 1 ;
        console.log(page);
        if(page > 1){
            index  = (page - 1)*limit + record.rowIndex + 1
        }
        return index;
    }

    const onFileChange = (event) => {
        setFile(event.target.files[0])
    }

    const setThumbnailFile = (event) => {
        setThumbnail(event.target.files[0])
    }

    const setThumbnailFileTemp = (event) => {
        setThumbnailTemp(event.target.files[0])
    }

    const onSubmithandler = (e) => {
        e.preventDefault();
        if(!type) return notify('error','Type is Mandatory')
        if(!language && language == '') return notify('error','Language is Mandatory')

        console.log('type, link, name, file', type, link, name, file)

        const formData = new FormData(); 
        if(type === 'media' || type === 'docs'){
            if(!file) return notify('error','File is Mandatory')
            if(!title) return notify('error','Title is Mandatory')
            formData.append( 
                "file", 
                file, 
                file.name 
            ); 
            if(thumbnail){
                formData.append( 
                    "thumbnail", 
                    thumbnail, 
                    thumbnail.name 
                ); 
            }

            formData.append( 
                "type", 
                type 
              );
            formData.append( 
                "language", 
                language 
            );
            formData.append( 
                "title", 
                title 
            );   
            props._uploadResource(formData);
        }else{
            if(!link) return notify('error','Link is Mandatory')
            if(!name) return notify('error','Name is Mandatory')
            if(!link.includes('http',0)) return notify('error','Please add hyperlink in link')

            formData.append( 
                "type", 
                type 
            ); 
            formData.append( 
                "link", 
                link 
            ); 
            formData.append( 
                "name", 
                name 
            ); 
            formData.append( 
                "language", 
                language 
            ); 
            props._uploadResource(formData);
        }
    }

    const onChangeHandler = (name, value) => {
        if(name === 'type'){
            setType(value)
            if(value === 'media' || value === 'docs'){
                document.getElementById('fileSection').style.display = 'block'
                document.getElementById('linkSection').style.display = 'none'
                document.getElementById('file').style.visibility = 'visible'
                if(value === "media"){
                    document.getElementById('thumbnail').style.visibility = 'visible'
                }
                document.getElementById('title').style.visibility = 'visible'
                document.getElementById('name').style.visibility = 'hidden'
                document.getElementById('link').style.visibility = 'hidden'
            }else{
                document.getElementById('fileSection').style.display = 'none'
                document.getElementById('linkSection').style.display = 'block'
                document.getElementById('file').style.visibility = 'hidden'
                document.getElementById('title').style.visibility = 'hidden'
                document.getElementById('name').style.visibility = 'visible'
                document.getElementById('link').style.visibility = 'visible'
            }
        }

        console.log('name, value', name , value)
        if(name === 'name') setName(value)
        if(name === 'title') setTitle(value)
        if(name === 'link') setLink(value)
        if(name === 'language') setLanguage(value)
    }

    const onUpdateHandler = (e) => {
        e.preventDefault();
        const formData = new FormData(); 
        formData.append("resourceId", resourceId)
        formData.append('name', name)
        formData.append('type', type)
        formData.append('link', link)
        formData.append("language", language)
        formData.append("title", title)
        if(thumbnailTemp){
            formData.append( 
                "thumbnail", 
                thumbnailTemp, 
                thumbnailTemp.name 
            ); 
        }
        // let data = {
        //     resourceId,
        //     name,
        //     type,
        //     link,
        //     language,
        //     title
        // }
        props._updateResource(formData);
    }

    const confirmAction = (e) => {
        e.preventDefault();
        props._deleteResource(resourceId);
    }

    let  onPageChange = (pagenum) => {
        console.log('page num', page)
        setPage(pagenum);
        // props.fetchUsers(page, limit);
    }

    useEffect(()=>{
        if(props.uploadResources.isFetched && !props.uploadResources.isFetching && !props.uploadResources.err)
        {
            toggleAddModal();
            props._fetchResources(page, limit, activeTab);
            setName('')
            setLink('')
            setLanguage('')
            setFile(null)
            notify('success',props.uploadResources.message)
        }

        if(!props.uploadResources.isFetched && !props.uploadResources.isFetching && props.uploadResources.err)
        {
            notify('error',props.uploadResources.message)
        }


    },[props.uploadResources])

    useEffect(()=>{
        if(props.updateResource.isFetched && !props.updateResource.isFetching && !props.updateResource.err)
        {
            toggleEditModal();
            props._fetchResources(page, limit, activeTab);
            notify('success',props.updateResource.message)
        }

        if(!props.updateResource.isFetched && !props.updateResource.isFetching && props.updateResource.err)
        {
            notify('error',props.updateResource.message)
        }


    },[props.updateResource])

    useEffect(()=>{
        if(props.deleteResource.isFetched && !props.deleteResource.isFetching && !props.deleteResource.err)
        {
            toggleDeleteModal();
            props._fetchResources(page, limit, activeTab);
            notify('success',props.deleteResource.message)
        }

        if(!props.deleteResource.isFetched && props.deleteResource.isFetching && props.deleteResource.err)
        {
            notify('error',props.deleteResource.message)
        }


    },[props.deleteResource])

    useEffect(() => {
        props._fetchResources(page, limit, activeTab);
    }, [page, activeTab])

    useEffect(()=>{
        if(!props.fetchResources.isFetching && props.fetchResources.isFetched && props.fetchResources.data !== content ){
            setContent(props.fetchResources.data);
            setFetched(props.fetchResources.isFetched);
          //   setTotal(props.premiumContentList.count);
        }

    },[props.fetchResources])  

    const viewFile = (data) => {
        let { link } = data;
        window.open(link)
    }

    const viewThumbnail = (data) => {
        let { thumbnail } = data;
        window.open(thumbnail)
    }

    const editData = (data) => {
        let { _id, name, type, link, language, title, thumbnail, filetype } = data;

        setResourceId(_id)
        setName(name)
        setLink(link)
        setType(type)
        setTitle(title)
        setLanguage(language)
        setThumbnail(thumbnail)
        setFileType(filetype)
        return setModalEdit(true);
    }

    const deleteData = (data) => {
        let { _id } = data;
        setResourceId(_id)
        return setModalDelete(true)
    }

    const renderAction = (record) => {
        let buttons = <span>
                    <>
                    <PenIcon className="cursor-pointer ml-2" size={18} onClick={()=>{ editData(record.data) } } />
                    <EyeIcon size={'18px'} onClick={()=>{ viewFile(record.data) } } className="cursor-pointer m1-2"/>
                    <DeleteIcon className="cursor-pointer ml-2" size={18} onClick={()=>{ deleteData(record.data) }} />
                    </>
                </span>;   
        return buttons;
    }

    const renderThumbnail = (record) => {
        return <>
        <EyeIcon size={'18px'} onClick={()=>{ viewThumbnail(record.data) } } className="cursor-pointer m1-2"/>
        </>
    }

    const renderLanguage = (record) => {
        if(record.data.language === 'en'){
            return 'English'
        }else if(record.data.language === 'fr'){
            return 'French'
        }else{
            return 'Spanish'
        }
    }  

    const toggleAddModal = () => {
        return setModalAdd(!modalAdd)
    }

    const toggleEditModal = () => {
        return setModalEdit(!modalEdit)
    }

    const toggleDeleteModal = () => {
        return setModalDelete(!modalDelete)
    }

    const toggle = () => {
        return setModalAdd(true)
    }

    const toggleTab = (type) => {
        setActiveTab(type)
    }

    const renderDate = (record) => {
        return moment(record.data.createdAt).format('MM-DD-YYYY')
    }

    const headsAg = [
        {headerName: "Id", field: "_id", width:"80", sortable:true, filter:'text', 
            cellRendererFramework : renderIndex ,
            // checkboxSelection : true
        },
        {headerName: "Date", field: "createdAt", sortable:true, filter:'text', 
            cellRendererFramework : renderDate , width:"100"
        },
        {headerName: "Name", field: "name",  sortable:true, filter:'text'},
        {headerName: "Language", cellRendererFramework : renderLanguage,  sortable:true, filter:'text'},
        {headerName: "Actions", cellRendererFramework : renderAction, width:"100",  cellStyle : (params) =>{ return {textAlign :  'center'} } },
    ]

    const headAgAnother = [
        {headerName: "Id", field: "_id", width:"80", sortable:true, filter:'text', 
            cellRendererFramework : renderIndex ,
        // checkboxSelection : true
        },
        {headerName: "Date", field: "createdAt", sortable:true, filter:'text', 
            cellRendererFramework : renderDate , width:"100"
        },
        {headerName: "Name", field: "name",  sortable:true, filter:'text'},
        {headerName: "Title", field: "title",  sortable:true, filter:'text'},
        {headerName: "Language", cellRendererFramework : renderLanguage,  sortable:true, filter:'text'},
        {headerName: "Actions", cellRendererFramework : renderAction, width:"100", cellStyle : (params) =>{ return {textAlign :  'center'} } }
    ]

    const headAgMedia = [
        {headerName: "Id", field: "_id", width:"80", sortable:true, filter:'text', 
            cellRendererFramework : renderIndex ,
        // checkboxSelection : true
        },
        {headerName: "Date", field: "createdAt", sortable:true, filter:'text', 
            cellRendererFramework : renderDate , width:"100"
        },
        {headerName: "Name", field: "name",  sortable:true, filter:'text'},
        {headerName: "Title", field: "title",  sortable:true, filter:'text'},
        {headerName: "Language", cellRendererFramework : renderLanguage, width:"120",  sortable:true, filter:'text'},
        {headerName: "Actions", cellRendererFramework : renderAction, width:"100", cellStyle : (params) =>{ return {textAlign :  'center'} } },
        {headerName: "Thumbnail", cellRendererFramework : renderThumbnail },
    ]

    useEffect(()=>{
        console.log('props in resources', props)
    })

    return(
    < Container>
      <Row>
        <Col md={12}>
            <Card>
                <CardBody className="bg-white ">
                <h3 className='page-title bb-1 border-gray mb-4'>Resources</h3>
                <Row className="mt-12">
                    <Col md={12} className="text-right">
                            <Button color="primary" onClick={toggle} >Add +</Button>
                    </Col>
                </Row>
                <Nav tabs>
                    <NavItem>
                        <NavLink className={activeTab == 'media' ? 'active' : ''} onClick={() => toggleTab('media') }>
                            Media
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink className={activeTab == 'docs' ? 'active' : ''} onClick={() => toggleTab('docs')}>
                            Docs
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink className={activeTab == 'link' ? 'active' : ''} onClick={() => toggleTab('link') }>
                            Link
                        </NavLink>
                    </NavItem>
                </Nav>
                <TabContent activeTab={activeTab}>
                    <TabPane tabId="media">
                    <Col md={12} style={{ height: '90vh', width: '1000px' }} className="ag-theme-material">
                    <div style={{height : '100%', width : '100%'}}>
                    <TableComponent 
                                rowsData={ content } 
                                // onCellClicked={ onCellClicked }
                                headsAg={headAgMedia}
                                editRecord={editRecord} 
                                isFetched ={isFetched}
                                rowSelectable={'multiple'} 
                                rowsPerPage={10}
                                checkboxSelection={true}
                                // noRecordsText = {norecordstext}
                                fetchingRecordsText = {'Fetching List...'}
                                noRecordsText = {'No Records Found!'}
                                {...props} 

                            />
                    </div>
                    </Col>  
                    <Col md={12} className="mt-2">
                        <TablePagination onPageChange={onPageChange} page={page} limit={limit} total={total}/>
                    </Col> 
                    </TabPane>
                    <TabPane tabId="docs">
                    <Col md={12} style={{ height: '90vh', width: '1000px' }} className="ag-theme-material">
                    <div style={{height : '100%', width : '100%'}}>
                    <TableComponent 
                                rowsData={ content } 
                                // onCellClicked={ onCellClicked }
                                headsAg={headAgAnother}
                                editRecord={editRecord} 
                                isFetched ={isFetched}
                                rowSelectable={'multiple'} 
                                rowsPerPage={10}
                                checkboxSelection={true}
                                // noRecordsText = {norecordstext}
                                fetchingRecordsText = {'Fetching List...'}
                                noRecordsText = {'No Records Found!'}
                                {...props} 

                            />
                    </div>
                    </Col>  
                    <Col md={12} className="mt-2">
                        <TablePagination onPageChange={onPageChange} page={page} limit={limit} total={total}/>
                    </Col> 
                    </TabPane>
                    <TabPane tabId="link">
                    <Col md={12} style={{ height: '90vh', width: '1000px' }} className="ag-theme-material">
                    <div style={{height : '100%', width : '100%'}}>
                    <TableComponent 
                                rowsData={ content } 
                                // onCellClicked={ onCellClicked }
                                headsAg={headsAg}
                                editRecord={editRecord} 
                                isFetched ={isFetched}
                                rowSelectable={'multiple'} 
                                rowsPerPage={10}
                                checkboxSelection={true}
                                // noRecordsText = {norecordstext}
                                fetchingRecordsText = {'Fetching List...'}
                                noRecordsText = {'No Records Found!'}
                                {...props} 

                            />
                    </div>
                    </Col>  
                    <Col md={12} className="mt-2">
                        <TablePagination onPageChange={onPageChange} page={page} limit={limit} total={total}/>
                    </Col> 
                    </TabPane>
                </TabContent>
                <React.Suspense fallback={''}>
                                <AddResources 
                                    modal={modalAdd} 
                                    toggle={ toggleAddModal }
                                    modalTitle={ 'Details' }
                                    onSubmithandler = {onSubmithandler}
                                    onFileChange = {onFileChange}
                                    setThumbnailFile = {setThumbnailFile}
                                    onChangeHandler = {onChangeHandler}
                                />
                                <EditResources 
                                    modal={modalEdit} 
                                    toggle={ toggleEditModal }
                                    modalTitle={ 'Details' }
                                    onUpdateHandler = {onUpdateHandler}
                                    onChangeHandler = {onChangeHandler}
                                    setThumbnailFileTemp = {setThumbnailFileTemp}
                                    name={ name }
                                    link = {link}
                                    type = {type}
                                    language = {language}
                                    title={title}
                                    thumbnail = {thumbnail}
                                    fileType = {fileType}
                                />
                                <DeleteResource 
                                    modal={modalDelete} 
                                    toggle={ toggleDeleteModal }
                                    modalTitle={ 'Details' }
                                    onChangeHandler = {onChangeHandler}
                                    comment = {'comment'}
                                    confirmAction = {confirmAction}
                                    CustomClass = { 'test_class'}
                                    modalText = {'Are you sure , you want to delete this resource?'}
                                />
                                {/* <OtherComponent /> */}
                            </React.Suspense>
                </CardBody>
            </Card>
        </Col>
      </Row>
     </Container>
    ) 
}

export default Resources