import Login from './../../containers/account/log_in/LogIn';
import DefaultDashboard from '../../containers/dashboards/default/DefaultDashboard';
import AllAdminUsersContainer from '../../containers/Admin/AllAdminUsersContainer';
import AddeNewAdminContainer from '../../containers/Admin/AddeNewAdminContainer';
import MyProfileContainer from '../../containers/account/Profile/MyProfileContainer';
import ForgotPasswordContainer from '../../containers/account/ForgotPassword/ForgotPasswordContainer';
import AllUsersContainer from '../../containers/Users/AllUsersContainer';
// import Landing from '../../containers/landing/Landing';
import AllCategoryContainer from '../../containers/Category/AllCategoryContainer';
import AddNewMeetingContainer from '../../containers/Meetings/AddNewMeetingContainer';
import MeetingListingsContainer from '../../containers/Meetings/MeetingListingsContainer';
import VirtualTourContainer from '../../containers/virtual_tour/VIrtualTourContainer';
import NotificationsContainer from '../../containers/Notifications/NotificationsContainer';
import SubscribedContentContainer from '../../containers/SubscribedContent/SubscribedContentContainer';
import ListSubscribedContentContainer from '../../containers/SubscribedContent/ListSubscribedContentContainer';
import ListFeelingsContainer from '../../containers/Feelings/ListFeelingsContainer';
import ListReactionsContainer from '../../containers/Reactions/ListReactionsContainer';
import AddFAQContainer from '../../containers/FAQ/AddFAQContainer';
import ListFAQContainer from '../../containers/FAQ/ListFAQContainer';
import EditFAQContainer from '../../containers/FAQ/EditFAQContainer';
import DonationsContainer from '../../containers/Donations/DonationsContainer';
import TermsAndConditionsContainer from '../../containers/TermsAndConditions/TermsAndConditionContainer';
import ListNotificationsContainer from '../../containers/Notifications/ListNotificationsContainer';
import ConfigurationsContainer from '../../containers/Configurations/Configurations';
import ReportsContainer from '../../containers/Reports/ReportsContainer';
import ObjectionaleContentContainer from '../../containers/ObjectionableContent/ObjectionaleContentContainer';
import ReportedContent from '../../containers/ReportedContentContainer/ReportedContent';
import ListPostCategoriesContainer from '../../containers/PostCategories/ListPostCategoriesContainer';
import PostCategoriesContainer from '../../containers/PostCategories/PostCategoryContainer'
import UserTrackingContainer from '../../containers/UserTracking/UserTrackingContainer'
import ResourcesContainer from '../../containers/Resources/ResourcesContainer'
import AllBannedEmailContainer from '../../containers/BannedEmails/AllBannedEmailContainer';
import AddeNewBannedEmailContainer from "../../containers/BannedEmails/AddBannedEmailContainer"
import GetFinderMeetings from '../../containers/FinderMeetings/GetFinderMeetings';
import ListSubscribedContentIssueLogContainer from '../../containers/SubscribedContent/ListSubscribedContentIssueLogContainer';
// import AddCategoryContainer from '../../containers/Category/AddCategoryContainer';


export const routes = [
  {
    path: '/dashboard',
    exact: true,
    component: DefaultDashboard,
    authRoute: true,
    permissions: ['ALL']
  },
  {
    path: '/admin/users',
    exact: true,
    component: AllAdminUsersContainer,
    authRoute: true,
    permissions: ['LIST_ADMIN']
  },
  {
    path: '/admin/users/add',
    exact: true,
    component: AddeNewAdminContainer,
    authRoute: true,
    permissions: ['CREATE_ADMIN']
  },
  {
    path: '/users',
    exact: true,
    component: AllUsersContainer,
    authRoute: true,
    permissions: ['APP_USERS']
  },
  {
    path: '/banned-emails',
    exact: true,
    component: AllBannedEmailContainer,
    authRoute: true,
    permissions: ['APP_USERS']
  },
  {
    path: '/add-banned-emails',
    exact: true,
    component: AddeNewBannedEmailContainer,
    authRoute: true,
    permissions: ['APP_USERS']
  },
  // {
  //   path: '/users/add',
  //   exact: true,
  //   component: DefaultDashboard,
  //   authRoute: true,
  // },
  {
    path: '/account/my-profile',
    exact: true,
    component: MyProfileContainer,
    authRoute: true,
    permissions: ['ALL']
  },
  {
    path: '/forgot-password',
    exact: true,
    component: ForgotPasswordContainer,
    authRoute: false,
    permissions: ['ALL']
  },
  // {
    //   path: '/category/add',
    //   exact: true,
    //   component: AddCategoryContainer,
    //   authRoute: true,
    // },
    {
      path: '/category/all',
      exact: true,
      component: AllCategoryContainer,
      authRoute: true,
      permissions : [ 'CATEGORY' ]
    },
    {
      path: '/meetings/add',
      exact: true,
      component: AddNewMeetingContainer,
      authRoute: true,
      permissions : [ 'CREATE_MEETINGS' ]
    },
    {
      path: '/meetings/',
      exact: true,
      component: MeetingListingsContainer,
      authRoute: true,
      permissions : [ 'LIST_MEETINGS' ]
    },
    {
      path: '/login',
      exact: true,
      component: Login,
      authRoute: false,
    },
    {
      path: '/virtual-tour',
      exact: true,
      component: VirtualTourContainer,
      authRoute: true,
      permissions : [ 'VIRTUAL_TOUR' ]
    },
    {
      path: '/feelings',
      exact: true,
      component: ListFeelingsContainer,
      authRoute: true,
      permissions : [ 'FEELINGS' ]
    },
    {
      path: '/notifications/create',
      exact: true,
      component: NotificationsContainer,
      authRoute: true,
      permissions : [ 'NOTIFICATIONS', 'ALL' ]
    },
    {
      path: '/notifications',
      exact: true,
      component: ListNotificationsContainer,
      authRoute: true,
      permissions : [ 'NOTIFICATIONS', 'ALL' ]
    },
    {
      path: '/subscribed-content',
      exact: true,
      component: SubscribedContentContainer,
      authRoute: true,
      permissions : [ 'PREMIUM_CONTENT' ]
    },
    {
      path: '/subscribed-content/list',
      exact: true,
      component: ListSubscribedContentContainer,
      authRoute: true,
      permissions : [ 'PREMIUM_CONTENT' ]
    },
    {
      path: '/subscribed-content/issue-log',
      exact: true,
      component: ListSubscribedContentIssueLogContainer,
      authRoute: true,
      permissions : [ 'PREMIUM_CONTENT' ]
    },
    {
      path: '/faq/add',
      exact: true,
      component: AddFAQContainer,
      authRoute: true,
      permissions : [ 'FAQ','ALL' ]
    },
    {
      path: '/faq/',
      exact: true,
      component: ListFAQContainer,
      authRoute: true,
      permissions : [ 'FAQ', 'FAQ_LIST', 'ALL' ]
    },
    {
      path: '/faq/edit',
      exact: true,
      component: EditFAQContainer,
      authRoute: true,
      permissions : [ 'FAQ', 'ALL' ]
    },
    {
      path: '/donations',
      exact: true,
      component: DonationsContainer,
      authRoute: true,
      permissions : [ 'DONATIONS', 'ALL' ]
    },
    {
      path: '/terms-conditions',
      exact: true,
      component: TermsAndConditionsContainer,
      authRoute: true,
      permissions : [ 'TERMS_AND_CONDITIONS', 'ALL' ]
    },
    {
      path: '/terms-conditions/list',
      exact: true,
      component: TermsAndConditionsContainer,
      authRoute: true,
      permissions : [ 'TERMS_AND_CONDITIONS', 'ALL' ]
    },
    {
      path: '/configurations',
      exact: true,
      component: ConfigurationsContainer,
      authRoute: true,
      permissions : [ 'TERMS_AND_CONDITIONS', 'ALL' ]
    },
    {
      path: '/reactions',
      exact: true,
      component: ListReactionsContainer,
      authRoute: true,
      permissions: ['REACTIONS']
    },
    {
      path: '/reports-exports',
      exact: true,
      component: ReportsContainer,
      authRoute: true,
      permissions: ['REPORTS']
    },
    {
      path: '/objectionable-content',
      exact: true,
      component: ObjectionaleContentContainer,
      authRoute: true,
      permissions: ['OBJECTIONABLE_CONTENT']
    },
    {
      path: '/reported-content',
      exact: true,
      component: ReportedContent,
      authRoute: true,
      permissions: ['REPORTED_CONTENT']
    },
    {
      path : '/al-anon-post-categories',
      exact : true,
      component : ListPostCategoriesContainer,
      authRoute : true,
      permissions : ['ALANON_POST_CATEGORIES']
    },
    {
      path: '/al-anon-post-categories/create',
      exact: true,
      component: PostCategoriesContainer,
      authRoute: true,
      permissions : [ 'ALANON_POST_CATEGORIES', 'ALL' ]
    },
    {
      path: '/user-tracking-list',
      exact: true,
      component: UserTrackingContainer,
      authRoute: true,
      permissions : [ 'ALANON_POST_CATEGORIES', 'ALL' ]
    },
    {
      path: '/resources',
      exact: true,
      component: ResourcesContainer,
      authRoute: true,
      permissions : [ 'RESOURCES', 'ALL' ]
    },
    {
      path: '/admin-meeting',
      exact: true,
      component: GetFinderMeetings,
      authRoute: true,
      permissions : [ 'ALL' ]
    },
]

