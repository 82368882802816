import React, { useState, useEffect } from 'react';
import { Container, Card, CardBody, CustomInput, Row, Col, Button, Input } from 'reactstrap';
import TableComponent from './../Shared/Table/Table';
import TablePagination from './../Shared/Table/TablePagination';
import PenIcon from 'mdi-react/PencilIcon';
import notification from '../../helpers/notificationHelper';

const EditModal = React.lazy(() => import('./EditModal'));

const ListPostCategories = (props) => {
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(100);
    const [selectedRecord, selectRecord] = useState('');
    const [total, setTotal] = useState(10);
    const [contentId, setContentId] = useState('');
    const [name, setName] = useState('');
    const [visible, setVisible] = useState('');
    const [language, setLanguage] = useState('');
    const [content, setContent] = useState([]);
    const [isFetched, setFetched] = useState(false);
    const [modalView, setModalView] = useState(false);
    const [modalEdit, setModalEdit] = useState(false);
    
    const {
      buttonLabel,
      className,
      toggle,
      modal,
      func,
      confirmAction,
      modalTitle,
      modalText,
      modalFor,
    } = props;

    let renderIndex=(record) => {
        let index = record.rowIndex + 1 ;
        //console.log(page);
        if(page > 1){
            index  = (page - 1)*limit + record.rowIndex + 1
        }
        return index;
    }

    
    let  onPageChange = (pagenum) => {
        //console.log('page num', page)
        setPage(pagenum);
        // props.fetchUsers(page, limit);
    }

    let makeAllVisible = () => {
        props._makeAllCategoryVisible()
    }

    const editRecord = (record) => {
        let id = record.id;
        //console.log('record', record)
    }

    const toggleEditModal = () => {
        setModalEdit(!modalEdit)
    }

    let setData =(data, type) => {
        let { _id, name, isVisible, language } = data;
        setContentId(_id)
        setName(name)
        setVisible(isVisible)
        setLanguage(language)
        if(type==='editModal') return setModalEdit(true);
    }

    const onChangeHandler = (name, value) => {
        // console.log('name', name);
        // console.log('value', value);
        if(name==='name') return setName(value)
        if(name==='visible') {
            var check = document.getElementById('visible').checked
            return setVisible(check)
        }
        if(name==='language') return setLanguage(value)
    }
    
    const onUpdatehandler = (e) => {
        e.preventDefault();
        let data = {
            name,
            isVisible : visible,
            category_id : contentId,
            language : language
        }
        props._updateCategory(data);
    }

    const changeVisible = (data) => {
        //console.log('data here', data)
        let category = {
            isVisible : !data.isVisible,
            category_id : String(data._id)
        }
        props._updateCategoryVisibilty(category);
    }
    const renderIsVisibleColumn = (data) => {     
        return (<CustomInput type="switch" id={Math.random()*10000} onChange={()=>{ changeVisible(data.data) }} checked={data.data.isVisible} />)
    }

    const renderLanguage = (record) => {
        if(record.data.language === 'en'){
            return 'English'
        }else if(record.data.language === 'fr'){
            return 'French'
        }else{
            return 'Spanish'
        }
    }

    const renderAction = (record) => {
        //console.log(record.data)
        let buttons = <span>
                    <PenIcon className="cursor-pointer ml-2" size={18} onClick={()=>{ setData(record.data, 'editModal') } } />                 
                </span>;
        return buttons;
    }

    const headsAg = [
        {headerName: "Id", field: "_id", width:"80", sortable:true, filter:'text', 
            cellRendererFramework : renderIndex ,
            // checkboxSelection : true
        },
        {headerName: "Name", field: "name",  sortable:true, filter:'text'},
        {headerName: "Language", cellRendererFramework : renderLanguage,  sortable:true, filter:'text'},
        { headerName: "Visible", field: "isVisible", 
            sortable:true, filter:'text', cellRendererFramework : renderIsVisibleColumn }, 
        {
            headerName: "Actions", 
            cellRendererFramework : renderAction, 
            sortable:true, 
            filter:'text'
        },
    ]
    
    useEffect(() => {
        props._fetchPostCategories(page, limit);
      }, [page])

    useEffect(()=>{
        //console.log('props in list category', props)
    })

    useEffect(() => {
        if(!props.updatePostCategory.isFetching && props.updatePostCategory.isFetched && !props.updatePostCategory.err){
            toggleEditModal();
            //console.log("is here")
            props._fetchPostCategories(page, limit);
            notification('success', props.updatePostCategory.message)
        }
        if(!props.updatePostCategory.isFetching && !props.updatePostCategory.isFetched && props.updatePostCategory.err ){
            notification('error', props.updatePostCategory.message);
        }
      }, [props.updatePostCategory])  

      useEffect(() => {
          //console.log('props.makeAllPostCategoryVisible', props.makeAllPostCategoryVisible)
        if(!props.makeAllPostCategoryVisible.isFetching && props.makeAllPostCategoryVisible.isFetched && !props.makeAllPostCategoryVisible.err ){
            props._fetchPostCategories(page, limit);
            notification('success', props.makeAllPostCategoryVisible.message)
        }
        if(!props.makeAllPostCategoryVisible.isFetching && !props.makeAllPostCategoryVisible.isFetched && props.makeAllPostCategoryVisible.err ){
            notification('error', props.makeAllPostCategoryVisible.message);
        }
      }, [props.makeAllPostCategoryVisible]) 
      

    useEffect(()=>{
        // console.log("props", props)
        // console.log('props in use effect', props.categories.categories)
        if(!props.categories.isFetching && props.categories.isFetched && props.categories.data !== content ){
            setContent(props.categories.categories);
            setFetched(props.categories.isFetched);
          //   setTotal(props.premiumContentList.count);
        }

    },[props.categories])  
  

     return (
         < Container>
            <Row>
                <Col md={12}>
                    <Card>
                        <CardBody className="bg-white ">
                        <h3 className='page-title bb-1 border-gray mb-4'>WSO Categories</h3>
                        <Row className="mt-12">
                            <Col md={12} className="text-right">
                                <Button color="primary" href="/al-anon-post-categories/create">Add +</Button>
                                <Button color="primary" onClick={makeAllVisible}>All</Button>
                            </Col>
                            <Col md={12} style={{ height: '90vh', width: '600px' }} className="ag-theme-material">
                            <div style={{height : '100%', width : '100%'}}>
                            <TableComponent 
                                    rowsData={ content } 
                                    // onCellClicked={ onCellClicked }
                                    headsAg={headsAg}
                                    editRecord={editRecord} 
                                    isFetched ={isFetched}
                                    rowSelectable={'multiple'} 
                                    rowsPerPage={10}
                                    checkboxSelection={true}
                                    // noRecordsText = {norecordstext}
                                    fetchingRecordsText = {'Fetching List...'}
                                    noRecordsText = {'No Records Found!'}
                                    {...props} 

                                />
                            </div>
                            </Col>   
                            <Col md={12} className="mt-2">
                                <TablePagination onPageChange={onPageChange} page={page} limit={limit} total={total}/>
                            </Col>
                            <React.Suspense fallback={''}>
                                <EditModal 
                                    modal={modalEdit} 
                                    toggle={ toggleEditModal }
                                    modalTitle={ 'Details' }
                                    name={ name }
                                    isVisible = {visible}
                                    language = {language}
                                    onChangeHandler= { onChangeHandler }
                                    onUpdatehandler ={ onUpdatehandler }
                                />
                                {/* <OtherComponent /> */}
                            </React.Suspense>
                        </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
         </Container>
     ) 
}

export default ListPostCategories;