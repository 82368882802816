import { updateProfile } from './../../api/profileApis';
import {UPDATEPROFILECLEAR, UPDATEPROFILEERROR, UPDATEPROFILEREQUEST, UPDATEPROFILESUCCESS, LOGINSUCCESS } from './actionTypes';
import { AUTHTOKEN } from './../../inc/config';
// sending received response form fetchSignup to reducer
let handleUpdateProfileSuccess = (res) => {
  //console.log('res from loginactions', res)
  return {
    type: UPDATEPROFILESUCCESS,
    payload: {
        data : res,
        message : 'Success'
    },
    // message: res.message,
  };
}

let handleUpdateProfileRequest = () => {
  return {
    type: UPDATEPROFILEREQUEST,
    payload: {
        message: 'loading',
    }
  };
}

// to handle error
let handleUpdateProfileError = (err) => {
  return {
    type: UPDATEPROFILEERROR,
    payload: {
        message: err,
    }
  };
}

export let handleUpdateProfileClear = (err) => {
    return {
      type: UPDATEPROFILECLEAR,
      payload: {}
    };
}

export function updateProfileAction(data) {
  console.log('test')
  return dispatch => {
    dispatch(handleUpdateProfileRequest(data));
    updateProfile(data).then((res)=>{
      //console.log('res in login', res);
      if(res.status === 200){
        let { data } = res;
        if(data.status){
          // localStorage.setItem('authtoken', data.token)
          return dispatch(handleUpdateProfileSuccess(data));
        }else{        
          return dispatch(handleUpdateProfileError(data.message));
        }
      }else if(res.status ===  401){
          localStorage.removeItem(AUTHTOKEN)
        return dispatch(handleUpdateProfileError(res.data.message));
      }
    }).catch((err)=>{
      console.log('err',err)
      if(err.message){
        return dispatch(handleUpdateProfileError(err.message));
      }else{        
        return dispatch(handleUpdateProfileError(err));
      }
    })
      
    }
}






