import React from 'react';
import {Container, Row, Col, Card, CardBody, Form, Button, Label, Input, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

const AddCategory = (props) => {

    // render(){
        let { modal, toggle , modalTitle, CustomClass, buttonLabel, showComment, comment, confirmAction, onChangeHandler } = props;
        return(
            <Form>
                <Modal isOpen={modal} toggle={toggle} className={CustomClass}>
                    <ModalHeader toggle={toggle}>{modalTitle}</ModalHeader>
                    <Form name = "add" onSubmit={confirmAction}>
                    <ModalBody>

                                {
                                    showComment && (
                                        <Input type="textarea" value={ comment } name="comment" onChange={ (e) => { onChangeHandler(e.target.value) } } />
                                    )
                                }                            
                    </ModalBody>
                    <ModalFooter>
                        <div style={{textAlign:'end'}}>
                            <Button color="primary" type="submit" name = 'update'  >Yes</Button>{' '}
                            <Button color="secondary"  name = 'cancel' onClick={(e) => toggle() }> Cancel</Button>
                        </div>
                    </ModalFooter>
                    </Form>
                </Modal>
            </Form>
        )
}

export default AddCategory