import React from 'react';
import { Card, CardBody, Container, Row, Col, Label, Input, Form, Button, CustomInput } from 'reactstrap';
import fetchConfigActions from '../redux/reducers/fetchConfigrationsReducers';
import notify from './../helpers/notificationHelper';

export default class Configurations extends React.Component {

    state = {
        filterValue : '',
        filter_value : '',
        isFetching : false,
        notificationStatus : false
    }

    componentDidMount(){
        this.props._fetchConfig();
    }

    static getDerivedStateFromProps(props,state){
        //console.log('latest vt props', props);
        let { fetchConfig, createConfig } = props;
        let { filterValue, filter_value, isFetching } = state;
        //console.log('fetchConfig', fetchConfig);
        //console.log('createConfig', createConfig);
        if(createConfig && createConfig.isFetching && createConfig.isFetching !== state.isFetching){
            return {
                isFetching : true
            }
        }
        if(createConfig && !createConfig.isFetching && createConfig.isFetching !== isFetching && createConfig.contentCreated){
            return {
                isFetching : false,
                filter_value : ''
            }
        }
        if(createConfig && !createConfig.isFetching && createConfig.isFetching !== isFetching && createConfig.error){
            return {
                isFetching : false,
                // filter_value : ''
            }
        }

        //console.log('fetchConfig.data.data', fetchConfig)
        if(!fetchConfig.isFetching && !fetchConfig.error && fetchConfig.data && fetchConfig.data.length > 0 ){
            //console.log('fetchTour.data.virtual_tour.configs.VirtualTour', fetchConfig.data)
            const meetConfigs = fetchConfig.data.filter(d => d.type === 'meetings')
            const notificationsConfigs = fetchConfig.data.filter(d => d.type === 'notifications')
            //console.log('meetConfigs', meetConfigs)
            let data = {
                filterValue : meetConfigs.length > 0 ? meetConfigs[0].configs.defaultFilter : '',
                notificationStatus : notificationsConfigs.length > 0 ? notificationsConfigs[0].configs.status : false 
            }
            return data
        }
        return null
    }

    onSubmitHandler = (e) => {
        e.preventDefault();
        let { filter_value } = this.state;
        if(filter_value < 2 || filter_value > 6) return notify('error', 'The value should be greater than 1 and less than 7')
        let data = {
            defaultFilter : filter_value 
        }
        this.props._createConfig('meetings', data);
    }

    onChangeHandler = (value) => {
        //console.log('vt value', value)
        this.setState({
            filter_value : value
        })
    }

    onClickHandler = (e) => {
        //console.log('e.target.checked', e.target.checked);
        const data = {
            status : e.target.checked
        }
        this.props._createConfig('notifications', data);

    }

    maintanenceHandler = (e) => {
        //console.log('e.target.checked', e.target.checked);
        const data = {
            status : e.target.checked
        }
        this.props._createConfig('notifications', data);

    }

    componentDidUpdate(prevProps, prevStates){
        let { createConfig } = this.props;
        //console.log('createConfig',createConfig)
        if( prevProps.createConfig !== createConfig && !createConfig.isFetching && createConfig.contentCreated){
            this.setState({
                filterValue : createConfig.data.data.configs.defaultFilter
            },()=>{
                notify('success', 'Meeting Configurations Updated!');
                this.props._fetchConfig();
            })
        }
        if( prevProps.createConfig !== createConfig && !createConfig.isFetching && createConfig.error){
            notify('error', createConfig.message);
        }
    }

    render(){
        let { filterValue, filter_value, isFetching, notificationStatus } = this.state;
        //console.log('filterValue', filterValue);
        return (
            <Container  >
                <Row>
                    <Col md={12}>
                        <Card>
                            <CardBody className="bg-white ">
                                <h3 className='page-title bb-1 border-gray mb-4'>Global Settings</h3>
                                <Row className="mt-3">
                                    <Col md={8}>
                                        <Form onSubmit ={this.onSubmitHandler}>
                                                <Row form className="mb-3">
                                                    <Col md={ { size : '12' }  }>
                                                        <h4>Meetings</h4>
                                                    </Col>
                                                    <Col md={6} sm={12}>
                                                        <Label className="text-muted">Current Filter Value :-    <strong>{ `${filterValue} days`  }</strong> </Label>
                                                        <br/>
                                                        <Label className="text-muted">Enter Filter Value:</Label>
                                                        <Input type="text" name="filter_value" value={filter_value}  required onChange= { (e) => { this.onChangeHandler(e.target.value) } } 
                                                            placeholder = "Enter New Value" 
                                                        />
                                                        {isFetching ? (
                                                            <Button color="primary" type="submit" className="mt-3 text-right" disabled = {true}>Submittng...</Button>                                                        
                                                        ) : (
                                                            <Button color="primary" type="submit" className="mt-3 text-right">Submit</Button>                                                        
                                                        )}
                                                    </Col>
                                                </Row>
                                            </Form>
                                    </Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col md={8}>
                                        <Form onSubmit ={this.onSubmitHandler}>
                                                <Row form className="mb-3">
                                                    <Col md={ { size : '12' }  }>
                                                            <h4>Global Notifications</h4>
                                                    </Col>
                                                    <Col md={6} sm={12}>
                                                        <Label className="text-muted">Current Status :-    <strong>{ `${notificationStatus ? 'On' : 'Off'}`  }</strong> </Label>
                                                        <br/>
                                                        <Label className="text-muted" htmlFor={'notificationStatus'}>Enter Filter Value:</Label>
                                                        <CustomInput type="switch" id={Math.random()*10000} name={'notificationStatus'} checked={notificationStatus} onChange={ this.onClickHandler } />
                                                    </Col>
                                                </Row>
                                            </Form>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>            
        )
    }
}