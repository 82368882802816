import { wrappedFetch, wrappedGet } from "./urlFuncs";
import {
  LIST_CATEGORIES_URL,
  ADD_CATEGORIES_URL,
  FEELINGS_URL,
  GET_REACTIONS_URL,
  CREATE_REACTIONS_URL,
  UPDATE_REACTIONS_URL,
  REMOVE_REACTIONS_URL
} from "./../inc/constants";
import { API_URL } from "./../inc/config";

export const getAllCategories = (page, limit, isActive) => {
  let url = `${API_URL}${LIST_CATEGORIES_URL}?page=${page}&limit=${limit}`;
  if (isActive) url = `${url}&active=true`;
  return wrappedGet(`${url}`, "GET");
};

export const craeteNewCategory = (data) => {
  let titleData = {
    english_title: data.enTitle,
    spanish_title: data.esTitle,
    french_title: data.frTitle,
  };
  return wrappedFetch(`${API_URL}${ADD_CATEGORIES_URL}`, "POST", titleData);
};

export const updateCategory = (data) => {
  return wrappedFetch(`${API_URL}${ADD_CATEGORIES_URL}`, "PUT", data);
};

//get allfeelings list
export const getAllFeelings = (page, limit) => {
  return wrappedGet(
    `${API_URL}${FEELINGS_URL}?page=${page}&limit=${limit}`,
    "GET"
  );
};
export const getAllReactions = (page, limit) => {
  return wrappedGet(
    `${API_URL}${GET_REACTIONS_URL}?page=${page}&limit=${limit}`,
    "GET"
  );
};

//create new feeling for posts
export const craeteNewFeeling = (data) => {
  return wrappedFetch(`${API_URL}${FEELINGS_URL}`, "POST", data);
};

export const craeteNewReaction = (data) => {
  //console.log('form data in create reactions url', data)
  return wrappedFetch(`${API_URL}${CREATE_REACTIONS_URL}`, "POST", data);
};

//update feeling for posts
export const updateFeelingData = (id, data) => {
  return wrappedFetch(`${API_URL}${FEELINGS_URL}/${id}`, "PUT", data);
};
export const updateReactionData = (id, data) => {
  return wrappedFetch(`${API_URL}${UPDATE_REACTIONS_URL}/${id}`, "PUT", data);
};

//delete feeling for posts
export const removeFeeling = (id) => {
  return wrappedFetch(`${API_URL}${FEELINGS_URL}/${id}`, "DELETE");
};

export const removeReaction = (id) => {
  return wrappedFetch(`${API_URL}${REMOVE_REACTIONS_URL}/${id}`, "DELETE");
};
