import React from 'react';
import { Container, Row, Col, Card, CardBody, Form, Button, Label, Input } from 'reactstrap';
import notification from '../../../helpers/notificationHelper';

class MyProfile extends React.Component{
    
    state = {
        firstname : '',
        lastname :'',
        username : '',
        email:'',
        password :'',
        phone : '',
        country_code : '',
        avatar : '',
        isFetching : false
    }
    
    componentDidMount(){
        //console.log('props in my profile', this.props)
        let { firstname, lastname, username, email, phone, password, country_code, avatar } = this.props.user.user;
        this.setState({
            firstname,
            lastname,
            username,
            password,
            country_code,
            email : email.mail,
            phone : phone.number,
            avatar : avatar
        })
    }

    onChangeHandler = (name, value) => {
        //console.log('here');
        // console.log('.echeckValidity()', e.target.checkValidity())
        this.setState({
            [name] : value
        })
    }

    changeImage = (e) => {
        //console.log('e.target.files', e.target.files);
        let avatar = e.target.files[0];
        this.setState({ avatar });
        //console.log('avatar', avatar);
    }
    
    onSubmitHandler = (e) => {
        //console.log('e', e.target.checkValidity());
        e.preventDefault();
        let user_details = {...this.state};
        delete user_details.isFetching;
        const formData = new FormData();
        formData.append('avatar',user_details.avatar);
        //console.log('formdata', user_details.avatar);
        this.props.onUpdate(user_details);
        // console.log('user_details.avatar', user_details.avatar)
        // console.log('data', formData)
    }

    static getDerivedStateFromProps(props, state){
        if(props.updateProfile.isFetching !== state.isFetching){
            return {
                isFetching : props.updateProfile.isFetching
            }
        }
        return null
    }

    componentDidUpdate(prevProps, prevStates){
        if(!this.props.updateProfile.isFetching && !this.props.updateProfile.error && this.props.updateProfile.isupdated){
            return notification('Success', 'Profile Updated!')
        }
        
    }
    
    componentWillUnmount(){
        this.props.clearStates();
    }

    render(){
        let { avatar, username, firstname, lastname, email, password, phone, country_code } = this.state;
        // console.log('this.props my profile', this.props);
        //console.log('input')
        return (
            <Container>
                <Row>
                    <Col md={12} >
                        <Card>
                            <CardBody>
                                <h3 className='page-title bb-1 border-gray pb-2'>Profile</h3>
                                <Row>
                                    <Col md={12} className="pt-3">
                                    <Form onSubmit ={this.onSubmitHandler}>
                                            <Row form className="mb-3">
                                                <Col md={6} sm={12}>
                                                    <Label className="text-muted">Firstname:</Label>
                                                    <Input type="text" name="firstname" value={firstname} 
                                                        onChange={ (e) => {this.onChangeHandler(e.target.name, e.target.value)} } 
                                                        placeholder = "Enter Firstname" required
                                                    />
                                                </Col>
                                                <Col md={6} sm={12}>
                                                    <Label className="text-muted">Lastname:</Label>
                                                    <Input type="text" name="lastname" value ={lastname}
                                                        onChange={ (e) => {this.onChangeHandler(e.target.name, e.target.value)} }
                                                        placeholder = "Enter Lastname" required
                                                    />
                                                </Col>
                                                
                                            </Row>
                                            <Row form  className="mb-3">
                                                <Col md={6} sm={12}>
                                                    <Label className="text-muted">Username:</Label>
                                                    <Input type="text" name="username" value ={username}
                                                        onChange={ (e) => {this.onChangeHandler(e.target.name, e.target.value)} }
                                                        placeholder = "Enter Username" required
                                                    />
                                                </Col>
                                                <Col md={2} sm={12}>
                                                    <Label className="text-muted">Country Code:</Label>
                                                    <Input type="text" name="country_code" value ={country_code}
                                                        onChange={ (e) => {this.onChangeHandler(e.target.name, e.target.value)} }
                                                        placeholder = "Enter Country Code" required
                                                    />
                                                </Col>
                                                <Col md={4} sm={12}>
                                                    <Label className="text-muted">Phone:</Label>
                                                    <Input type="text" name="phone" value ={phone}
                                                        onChange={ (e) => {this.onChangeHandler(e.target.name, e.target.value)} }
                                                        placeholder = "Enter Phone" required
                                                    />
                                                </Col>
                                            </Row>
                                            <Row form className="mb-3">
                                                <Col md={6} sm={12}>
                                                    <Label className="text-muted">Email:</Label>
                                                    <Input type="email" name="email" readOnly defaultValue ={email}
                                                        placeholder = "Enter Email" 
                                                    />
                                                </Col>
                                                <Col md={6} sm={12}>
                                                    <Label className="text-muted">Password:</Label>
                                                    <Input type="password" name="password" readOnly defaultValue ={password}
                                                        // onChange={ this.onChangeHandler }   
                                                        placeholder = "Enter Password" 
                                                    />
                                                </Col>
                                                
                                            </Row>
                                            <Row className="text-right">
                                                <Col md={12}  sm={12}>
                                                    <Button color="primary" type="submit">Update</Button>
                                                </Col>
                                                
                                            </Row>
                                        </Form>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        )
    }
}

export default MyProfile