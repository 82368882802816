import React from 'react';
import { Card, CardBody, Container, Row, Col, Label, Input, Form, Button, Table } from 'reactstrap';
import { SPOKEN_LANGUAGE_OBJECT, SPOKEN_LANGUAG_ARRAY } from '../inc/constants';
import notify from './../helpers/notificationHelper';
export default class VirtualTour extends React.Component {

    state = {
        tourLink : '',
        tour_link : '',
        language : '',
        isFetching : false
    }

    componentDidMount(){
        this.props._fetchVirtualTour();
    }

    static getDerivedStateFromProps(props,state){
        //console.log('latest vt props', props);
        let { fetchTour, virtualTour } = props;
        let { tourLink } = state;
        //console.log('fetchTour', fetchTour);
        if(virtualTour && virtualTour.isFetching && virtualTour.isFetching !== state.isFetching){
            return {
                isFetching : true
            }
        }
        if(virtualTour && !virtualTour.isFetching && virtualTour.isFetching !== state.isFetching && virtualTour.contentCreated){
            return {
                isFetching : false,
                tour_link : ''

            }
        }
        if(!fetchTour.isFetching && !fetchTour.error && Object.keys(fetchTour.data).length > 0 && fetchTour.data.virtual_tour !== tourLink){
            //console.log('fetchTour.data.virtual_tour.configs.VirtualTour', fetchTour.data)
            return {
                tourLink : fetchTour.data.virtual_tour
            }
        }
    }

    onSubmitHandler = (e) => {
        e.preventDefault();
        let { tour_link, language } = this.state;
        this.props._createVirtualTour(tour_link, language);
    }

    onChangeHandler = (name, value) => {
        //console.log('vt value', value)
        this.setState({
            [name] : value
        })
    }

    componentDidUpdate(prevProps, prevStates){
        let { virtualTour } = this.props;
        //console.log('virtualTour',virtualTour);
        if( prevProps.virtualTour !== virtualTour && !virtualTour.isFetching && virtualTour.contentCreated){
            this.props._fetchVirtualTour();
            return notify('success', 'Virtual Tour Link Created!');
        }
    }

    render(){
        let { tourLink, tour_link, language, isFetching } = this.state;
        //console.log('tourlink', tourLink);
        return (
            <Container  >
                <Row>
                    <Col md={12}>
                        <Card>
                            <CardBody className="bg-white ">
                                <h3 className='page-title bb-1 border-gray mb-4'>Virtual Tour</h3>
                                <Row className="mt-3">
                                    <Col md={8}>
                                        <Form onSubmit ={this.onSubmitHandler}>
                                                <Row form className="mb-3">
                                                    <Col md={12} sm={12}>
                                                        {/* <Label className="text-muted">Current Virtual Tour :-    <strong>{ tourLink }</strong> </Label> */}
                                                        <br/>
                                                        <Row>
                                                            <Col md={4}>
                                                                <Label className="text-muted">Enter new link:</Label>
                                                                <Input type="text" name="tour_link" value={tour_link}  required onChange= { (e) => { this.onChangeHandler(e.target.name, e.target.value) } } 
                                                                    placeholder = "Enter New Link" 
                                                                />
                                                            </Col>
                                                            <Col md={4}>
                                                                <Label className="text-muted">language:</Label>
                                                                <Input type="select" name="language" id="typeselect" value={ language } onChange={ (e) => {this.onChangeHandler(e.target.name, e.target.value)} }>
                                                                    <option value="" >Select</option>                                            
                                                                    {SPOKEN_LANGUAG_ARRAY.map((l)=> {
                                                                        return <option value={l.value} >{l.name}</option>                                            
                                                                    })}
                                                                    </Input>
                                                            </Col>
                                                        </Row>
                                                        {isFetching ? (
                                                            <Button color="primary" type="submit" className="mt-3 text-right" disabled = {true}>Submittng...</Button>                                                        
                                                        ) : (
                                                            <Button color="primary" type="submit" className="mt-3 text-right">Submit</Button>                                                        
                                                        )}
                                                    </Col>
                                                </Row>
                                            </Form>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={4}>
                                        <Table>
                                            <thead>
                                                <tr>
                                                <th>Language</th>
                                                <th>Virtual Tour Link</th>
                                                </tr>
                                            </thead>
                                                {tourLink && tourLink.length > 0 && tourLink.map((l)=>{
                                                    let lang = l.configs.language || 'en';
                                                    return (
                                                        <tbody>

                                                            <tr>
                                                                <td>{SPOKEN_LANGUAGE_OBJECT[lang]}</td>
                                                                <td>{l.configs.virtualTour}</td>
                                                            </tr>
                                                        </tbody>
                                                    )
                                                })}
                                        </Table>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>            
        )
    }
}