import { BANNEDEMAILLISTSUCCESS, BANNEDEMAILLISTREQUEST, BANNEDEMAILLISTERROR, 
    ADDBANNEDERROR, ADDBANNEDSUCCESS, ADDBANNEDREQUEST,
    REMOVEBANNEDSUCCESS, REMOVEBANNEDERROR, REMOVEBANNEDREQUEST
} from "./actionTypes";
import {listBannedEmail, addNewBannedEmail, removeBannedEmail} from "./../../api/BannedEmailsApi"

export function getAllBannedEmailsRequest() {
    return {
        type : BANNEDEMAILLISTREQUEST,
        payload : {}
    }
}

export function getAllBannedEmailsSuccess(data) {
    return {
        type :BANNEDEMAILLISTSUCCESS,
        payload : data
    }
}

export function getAllBannedEmailsError(message) {
    return {
        type : BANNEDEMAILLISTERROR,
        payload : { message}
    }
}

export function addBannedEmailRequest() {
    return {
        type : ADDBANNEDREQUEST,
        payload : {}
    }
}

export function addBannedEmailSuccess(message) {
    return {
        type : ADDBANNEDSUCCESS,
        payload : { message}
    }
}

export function addBannedEmailError(message) {
    return {
        type : ADDBANNEDERROR,
        payload : { message}
    }
}
export function removeBannedEmailRequest() {
    return {
        type : REMOVEBANNEDREQUEST,
        payload : {}
    }
}

export function removeBannedEmailSuccess(message) {
    return {
        type : REMOVEBANNEDSUCCESS,
        payload : { message}
    }
}

export function removeBannedEmailError(message) {
    return {
        type : REMOVEBANNEDERROR,
        payload : { message}
    }
}

export const getAllBannedEmails = () =>{
    return (dispatch) => {
        dispatch(getAllBannedEmailsRequest());
        listBannedEmail().then((res)=>{
            console.log('responseData', res.status)
            if(res.status === 200 )return dispatch(getAllBannedEmailsSuccess(res.data));
            if(res.status === 401 )return dispatch(getAllBannedEmailsError(res.data.message));
            if(res.status === 400 )return dispatch(getAllBannedEmailsError(res.data.message));
        }).catch((err)=>{
          console.error('err', err)
          return dispatch(getAllBannedEmailsError(err.msg))
        })
    }
}

export const addBannedEmail = (data) => {
    return (dispatch) => {
        dispatch(addBannedEmailRequest());
        addNewBannedEmail(data).then((res)=>{
            if(res.status === 200 )return dispatch(addBannedEmailSuccess(res.data));
            if(res.status === 401 )return dispatch(addBannedEmailError(res.data.message));
            if(res.status === 400 )return dispatch(addBannedEmailError(res.data.message));
        }).catch((err)=>{
          console.error('err', err)
          return dispatch(addBannedEmailError(err.msg))
        })
    }
}

export const removeBanndEmail = (data) => {
    return (dispatch) => {
        dispatch(removeBannedEmailRequest());
        removeBannedEmail(data).then((res)=>{
            if(res.status === 200 )return dispatch(removeBannedEmailSuccess(res.data));
            if(res.status === 401 )return dispatch(removeBannedEmailError(res.data.message));
            if(res.status === 400 )return dispatch(removeBannedEmailError(res.data.message));
        }).catch((err)=>{
          console.error('err', err)
          return dispatch(removeBannedEmailError(err.msg))
        })
    }
}