import { connect } from "react-redux";
import * as _ from "lodash";
import * as actions from "../../redux/actions/actionTypes";
import { listAllFAQ, deleteFaq, listLangFAQ, updateFAQSequence } from '../../redux/actions/faqActions';
import ListFAQ from '../../components/FAQ/ListFAQ'

const mapStateToProps = state => {
  // console.clear()
  //console.log('state in container', state);
  return {
    fetchFAQ: state.fetchFAQ, 
    deleteFAQ: state.deleteFAQ,
    getLangFAQ: state.getLangFAQ,
    updateFAQSequnce : state.updateFAQSequnce
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    _fetchFAQ: ( page, lmit ) => {
      return dispatch(listAllFAQ(page, lmit));
    },
    _deleteFAQ: ( id ) => {
      return dispatch(deleteFaq( id ));
    },
    _fetchLangFAQ: ( lang ) => {
      return dispatch(listLangFAQ(lang));
    },
    _updateFAQSequnce: ( lang ) => {
      return dispatch(updateFAQSequence(lang));
    },
  };
};


const ListFAQContainer = connect(mapStateToProps, mapDispatchToProps)(ListFAQ);

export default ListFAQContainer;
