import { BANNEDEMAILLISTERROR, BANNEDEMAILLISTREQUEST, BANNEDEMAILLISTSUCCESS } from './../actions/actionTypes'
 
const initialState = {
    requestDone: false,
    isFetched: false,
    isFetching : false,
    err : false,
    message:'',
    bannedEmails: [],
    total : 0,
  };
  
  // receiving response sent by action according to type of action
  export default function listBannedEmail(state = initialState, action) {
    let { payload } = action
    switch (action.type) {
        case BANNEDEMAILLISTREQUEST:
          return { 
            requestDone: false,
            isFetched: false,
            isFetching : true,
            err : false,
            message:'',
            bannedEmails: [],
            total : 0
           };
          break;
        case BANNEDEMAILLISTSUCCESS:
        return { 
            requestDone: true,
            isFetched: true,
            isFetching : false,
            err : false,
            message:'',
            bannedEmails: payload.data,
        };
        break;
  
        case BANNEDEMAILLISTERROR:
        return { 
            requestDone: true,
            isFetched: false,
            isFetching : false,
            err : true,
            message: payload.message,
            bannedEmails : []
        };
        break;
        
      default:
        return state;
    }
  }
