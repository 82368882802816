import * as Actions from './actionTypes';
import * as catFuncs from './../../api/objectionableContentApi';


export function  getAllObjectionableContentRequest() {
    return {
      type: Actions.GETALLCATEGORIESSREQUEST,
      payload:{}
    };
  }
   
  export function getAllObjectionableContentSuccess(data) {
    return {
      type: Actions.GETALLCATEGORIESSUCCESS,
      payload : data,
    };
  }
   
  export function  getAllObjectionableContentError(message) {
      return {
        type: Actions.GETALLCATEGORIESSERROR,
        payload : { message },
      };
    }

    export function  createObjectionableContentRequest() {
      return {
        type: Actions.CREATECATEGORYREQUEST,
        payload:{}
      };
    }
     
    export function createObjectionableContentSuccess(data) {
      return {
        type: Actions.CREATECATEGORYSUCCESS,
        payload : data,
      };
    }
     
    export function  createObjectionableContentError(message) {
        return {
          type: Actions.CREATECATEGORYERROR,
          payload : { message },
        };
    }
   
    export function  updateObjectionableContentRequest() {
      return {
        type: Actions.UPDATECONTENTREQUEST,
        payload:{}
      };
    }
     
    export function updateObjectionableContentSuccess(message) {
      return {
        type: Actions.UPDATECONTENTSUCCESS,
        payload : message,
      };
    }
     
    export function  updateObjectionableContentError(message) {
        return {
          type: Actions.UPDATECONTENTERROR,
          payload : { message },
        };
    }

    export function  deleteObjectionableContentRequest() {
      return {
        type: Actions.DELETECONTENTREQUEST,
        payload:{}
      };
    }
     
    export function deleteObjectionableContentSuccess(message) {
      return {
        type: Actions.DELETECONTENTSUCCESS,
        payload : message,
      };
    }
     
    export function  deleteObjectionableContentError(message) {
        return {
          type: Actions.DELETECONTENTERROR,
          payload : { message },
        };
    }


export const addContent = (data) =>{
  console.log("data contianer", data)
    return (dispatch) => {
        dispatch(createObjectionableContentRequest());
        catFuncs.craeteNewContent(data).then((res)=>{
            console.log('responseData*****************************', res)
        if(res.status === 200) return dispatch(createObjectionableContentSuccess(res.data));
        if(res.status === 400) return dispatch(createObjectionableContentError(res.data.message));
        if(res.status === 401) return dispatch(createObjectionableContentError(res.data.message));
        if(res.status === 409) return dispatch(createObjectionableContentError(res.data.message));

        }).catch((err)=>{
            console.log('err', err)
            return dispatch(createObjectionableContentError(err.message));
        })
    }
}

export const editContent = (data,id) =>{
  return (dispatch) => {
      dispatch(updateObjectionableContentRequest());
      catFuncs.updateContent(data,id).then((res)=>{
      if(res.status === 200) return dispatch(updateObjectionableContentSuccess(res.data.message));
      if(res.status === 400) return dispatch(updateObjectionableContentError(res.data.message));
      if(res.status === 401) return dispatch(updateObjectionableContentError(res.data.message));
      if(res.status === 409) return dispatch(updateObjectionableContentError(res.data.message));

      }).catch((err)=>{
          console.log('err', err)
          return dispatch(updateObjectionableContentError(err.message));
      })
  }
}

export const deleteContent = (id) =>{
  return (dispatch) => {
      dispatch(deleteObjectionableContentRequest());
      catFuncs.deleteContent(id).then((res)=>{
      if(res.status === 200) return dispatch(deleteObjectionableContentSuccess(res.data));
      if(res.status === 400) return dispatch(deleteObjectionableContentError(res.data.message));
      if(res.status === 401) return dispatch(deleteObjectionableContentError(res.data.message));
      if(res.status === 409) return dispatch(deleteObjectionableContentError(res.data.message));

      }).catch((err)=>{
          console.log('err', err)
          return dispatch(deleteObjectionableContentError(err.message));
      })
  }
}

export const listAllContent = (page, limit, lang, searchkey) =>{
    return (dispatch) => {
        dispatch(getAllObjectionableContentRequest());
        catFuncs.listAllContent(page, limit, lang, searchkey).then((res)=>{
        if(res.status === 200) return dispatch(getAllObjectionableContentSuccess(res.data));
        if(res.status === 400) return dispatch(getAllObjectionableContentError(res.data.message));
        if(res.status === 401) return dispatch(getAllObjectionableContentError(res.data.message));
        if(res.status === 409) return dispatch(getAllObjectionableContentError(res.data.message));
        }).catch((err)=>{
            console.log('err', err)
            return dispatch(getAllObjectionableContentError(err.message));
        })
    }
}


