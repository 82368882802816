import React, { useState, useEffect } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, CustomInput, Row, Col, Form, FormGroup, Input } from 'reactstrap';
import moment from 'moment';

const MeetingDetailsModal = (props) => {
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [selectedRecord, selectRecord] = useState('');
//   const [total, setTotal] = useState(20);
  const [search, setSearch] = useState('');
  const {
    toggle,
    modal,
    confirmAction,
    modalTitle,
    modalFor,
    handleClose,
    data, 
    handleRowSelect,
    isFetching,
    total
  } = props;


  let renderIndex=(record) => {
    let index = record.rowIndex + 1 ;
    if(page > 1){
        index  = (page - 1)*limit + record.rowIndex + 1
    }
    return index;
}

let editRecord = (record) => {
    let id = record.id;
    //console.log('record', record)
}


let  renderDate = (data) => {
    return moment(data.data.createdAt).format('DD-MM-YYYY')
}

let renderIsHostColumn = (data) => {
  return <CustomInput type="switch" id={Math.random()*10000} name={'isHost'} readOnly defaultChecked={data.data.isHost} />
}

let  renderPhoneNumber =(data) => {
    let phone = '';
    if(data.data.country_code) {
        phone += data.data.country_code.toString()+'-';
    }
    phone += data.data.phone.number.toString()
    return phone
    // return data.phone.number
}

let renderIsSearchableColumn = (data) => {
    return <span>{data.data.isSearchable ? 'Yes' : 'No'}</span>
}

let  getSelectedRows = () => {
    const selectedNodes = this.gridApi.getSelectedNodes()
    const selectedData = selectedNodes.map( node => node.data )
    const selectedDataStringPresentation = selectedData.map( node => node.make + ' ' + node.model).join(', ')
    // alert(`Selected nodes: ${selectedDataStringPresentation}`)
}

let  onCellClicked = (event) => {
    //console.log('onCellClicked: ' + event + ', col ' + event.colIndex);
};

let  onPageChange = (page) => {
    setPage(page);
    props.fetchUsers(page, limit);
}



  const headsAg = [
    {headerName: "Sr. No", field: "i", width:80, sortable:true, filter:'text', 
        cellRendererFramework : renderIndex ,
        checkboxSelection : true
    },
    {headerName: "Email", field: "email.mail", sortable:true, filter:'text'},
    {headerName: "Displayname", field: "displayname", sortable:true, filter:'text'},
    {headerName: "Phone", sortable:true, filter:'text',
     cellRendererFramework : renderPhoneNumber 
    },
    {
        headerName: "is Host", 
        field: "isHost", 
        filter:'text', 
        width : 120,
        cellRendererFramework : renderIsHostColumn ,
        cellStyle : (params) =>{
            return {textAlign :  'center'}
          }
    },
    {
        headerName: "is Searchable", field: "isSearchable", sortable:true, filter:'text', 
        cellRendererFramework : renderIsSearchableColumn,
        width : 120,
        cellStyle : (params) =>{
            return {textAlign :  'center'}
          }
    },
    {
        headerName: "Join Date", field: "createdAt", sortable:true, 
        cellRendererFramework : renderDate, 
        filter:'text'
    },
]

 const searchHandler = (e) =>{
    let { value } = e.target
    setSearch(value)
}

useEffect(()=>{
    let searchInfo = {
        searchKey : search,
        page,
        limit,
    }
    search =="" ? fetchAllUsers() : searchAllUsers(searchInfo)
},[search])

const searchAllUsers = () => {
    // let { search, page, limit, active, subscribed } = this.state
    let searchInfo = {
        searchKey : search,
        page,
        limit,
        active : true
    }
    props._searchUser(searchInfo)
}

const fetchAllUsers = () => {
    // let { page, limit, active, subscribed, sortBy, join_date_sort } = this.state;
   props._fetchReporteeUsers();
}

  //console.log('data modal meeting', props);
  let { } = props;
  return (
    <div  style={{textAlign:'center'}}>
      {/* <Button color="danger" onClick={toggle}>{buttonLabel}</Button> */}
      <Modal isOpen={modal} fade={true} toggle={toggle} keyboard={false} backdrop={false} size={'lg'}>
          <span>
            <ModalHeader toggle={toggle}>{modalTitle}</ModalHeader>
          </span>
        <ModalBody>
          <Row>
              
            <Col md={{'size' : 12}} className="mb-3">
                <h2>Selected Users </h2>
            </Col>
            <Col style={{height: '60vh'}} className="ag-theme-material">
              <div style={{height : '100%', width : '100%'}}>
                      <Row style={{ 'listStyle' : 'none' }}>
                      
                          {data && data.length > 0 ? ( data.map((d, i) => {
                              return(
                                  <Col key={i} md={4} lg={4} sm={12}>
                                    <h4>
                                      {`${i+1}. ${d.displayname}-${d.email.mail}`}
                                    </h4>
                                  </Col>
                              )
                          })) : (
                              <Col className={'text-center'}><p>No Users Selected</p></Col>
                          )}
                      </Row>
                </div>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          {/* <Button color="primary" name ={modalFor} onClick={confirmAction} >Yes{' '}</Button>{' '} */}
          <Button color="secondary" onClick={handleClose}>Close</Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default MeetingDetailsModal;