import { connect } from "react-redux";
import * as _ from "lodash";
import * as actions from "../../redux/actions/actionTypes";
import {
  listAllReactions,
  addReactions,
  updateReaction,
  removeReaction,
} from "../../redux/actions/reactionsActions";
import Reactions from "../../components/Reactions/ListReactions";

const mapStateToProps = (state) => {
  // console.clear()
  //console.log("state in container", state);
  return {
    reactions: state.reactions,
    addReactions: state.category,
    updateReaction: state.updateReaction,
    removeReaction: state.removeReaction,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    _fetchReactions: () => {
      return dispatch(listAllReactions());
    },
    _addReactions: (titleData) => {
      return dispatch(addReactions(titleData));
    },
    _updateReaction: (id, data) => {
      return dispatch(updateReaction(id, data));
    },
    _removeReaction: (id) => {
      return dispatch(removeReaction(id));
    },
  };
};

const ReactionsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Reactions);

export default ReactionsContainer;
