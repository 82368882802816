import React, {useState} from 'react';
import { Modal, ModalHeader, ModalBody, Form, FormGroup, Label, Col, Input, Button } from 'reactstrap';

const UploadFinderMeeting = (props) => {
    const {
        toggle,
        modal,
        uploadMeetings,
        handleChange
    } = props;

    //console.log('data modal meeting', name); 
    return(
        <div  style={{textAlign:'center'}}>
            <Modal isOpen={modal} fade={true} toggle={toggle} keyboard={false} backdrop={false}>
                <span>
                    <ModalHeader toggle={toggle}>{'Upload Meetings'}</ModalHeader>
                </span>
                <ModalBody>
                  <Form onSubmit={uploadMeetings}>
                    <FormGroup row>
                    <Label
                      for="exampleFile"
                      sm={2}
                    >
                      File
                    </Label>
                    <Col sm={10}>
                      <Input
                        id="exampleFile"
                        name="file"
                        type="file"
                        onChange={handleChange}
                        required
                      />
                    </Col>
                    </FormGroup>
                    <FormGroup
                      check
                      row
                    >
                      <Col
                        sm={{
                          offset: 2,
                          size: 10
                        }}
                      >
                        <Button color='primary'>
                          Submit
                        </Button>
                      </Col>
                    </FormGroup>
                  </Form>
                </ModalBody>
            </Modal>
           
        </div>
    )
}

export default UploadFinderMeeting