import React from 'react';
import { Container, Row, Col, Card, CardBody, Button, Form, FormGroup, Label, Input,CustomInput } from 'reactstrap';
import TableComponent from './../Shared/Table/Table';
import PenIcon from 'mdi-react/PencilOutlineIcon';
import DeleteIcon from 'mdi-react/DeleteIcon';
import notification from '../../helpers/notificationHelper';
import CommonModal from './../Shared/CommonModal';
import FAQSeqModal from './FAQSeqModal';
// import TablePagination from './../Shared/Table/TablePagination';

class listFAQ extends React.Component{

    state={
        headsAg : [],
        data : [],
        isActive : false,
        page : 1, 
        limit : 100,
        modal : false,
        faq_id : '',
        modalEdit : false,
        dataLang : []
    }

    componentDidMount(){
        //console.log('here in all users',this.props);
        let { page, limit } = this.state;
        this.props._fetchFAQ(page, limit);
        // let total = categories.length
        //console.log("=*******************",this.props.categories)
        let headsAg = [
            {headerName: "Sr. No", field: "i", width:"100", sortable:true, filter:'text', 
                cellRendererFramework : this.renderIndex 
            },
            {headerName: "Title", field: "title", suppressSizeToFit: true, sortable:true, filter:'text' },
            {headerName: "Description", field: "description", suppressSizeToFit: true, sortable:true, filter:'text',  },
            {headerName: "language", field: "language", suppressSizeToFit: true, sortable:true, filter:'text' },
            {headerName: "Sequence", field: "i", width:"100", sortable:true, 
                cellRendererFramework : this.renderSeqNum 
            },
            {headerName: "Is Active", 
            field: "isActive", 
            suppressSizeToFit: true, 
            sortable:true, 
            filter:'text' ,
            cellRendererFramework : this.renderIsActiveColumn,
            cellStyle : (params) =>{
              return {textAlign :  'center'}
            }
          },
            {headerName: "Actions", 
            cellRendererFramework : this.renderAction,
            cellStyle : (params) =>{
              return {textAlign :  'center'}
            }
          },
          ]
        this.setState({  headsAg })
    }

    renderAction = (record) => {
      let buttons = <span>
                  <PenIcon className="cursor-pointer ml-2" size={18} onClick={()=>{ this.setData(record.data._id) } }/>
                  <DeleteIcon className="cursor-pointer ml-2" size={18} onClick={()=>{ this.setData(record.data._id, 'deleteModal') } }/>
              </span>;
      return buttons;
    }

    setData = (faqid, type) => {
        if(type==='deleteModal'){
            this.setState({
                faq_id:faqid
            },() => {
                this.toggleModal()
            })
        }
        if(!type){
            this.props.history.push(`/faq/edit?faqid=${faqid}`)
        }

    }
    
    renderIndex=(record) => {
      return record.rowIndex + 1;
    }

    renderSeqNum=(record) => {
      return record.data.seqNum ? record.data.seqNum : '';
    }

    renderLanguage=(record) => {
      return record.rowIndex + 1;
    }

    editRecord = (record) => {
        let id = record.id;
        //console.log('record', record)
    }

    getSelectedRows = () => {
        const selectedNodes = this.gridApi.getSelectedNodes()
        const selectedData = selectedNodes.map( node => node.data )
        const selectedDataStringPresentation = selectedData.map( node => node.make + ' ' + node.model).join(', ')
        alert(`Selected nodes: ${selectedDataStringPresentation}`)
    }

    renderGetDeleteColumn = () =>{
      return <div>Delete</div> 
    }

    static getDerivedStateFromProps(props, state){
      //console.log("inside the getDerivedStateFromProps condition======", props);
      if(props.fetchFAQ.data && props.fetchFAQ.data !== state.data && props.fetchFAQ.isFetched){
          return {
            data : props.fetchFAQ.data,
            total : props.fetchFAQ.total
          }
        }
        return null
  }

  componentDidUpdate(prevProps, prevStates){
    let { deleteFAQ, getLangFAQ, updateFAQSequnce }= this.props;
    let { page, limit } = this.state;
    // console.log('this.props.getLangFAQ',getLangFAQ);
    // console.log('this.props.updateFAQSequnce',updateFAQSequnce);
    if(deleteFAQ !== prevProps.deleteFAQ){

        if(deleteFAQ.isFetched && !deleteFAQ.isFetching && !deleteFAQ.err ){
            notification('success', deleteFAQ.message);
            return this.setState({ modal : false },()=>{
                let { page, limit } =this.state;
                return this.props._fetchFAQ(page, limit)
            });
        }else if(!deleteFAQ.isFetched && !deleteFAQ.isFetching && deleteFAQ.err ){
            notification('error', deleteFAQ.message);
            return this.setState({ modal : false },()=>{
                let { page, limit } =this.state;
                return this.props._fetchFAQ(page, limit)
            });
      }
    }

    if(getLangFAQ !== prevProps.getLangFAQ){
      //console.log('getLangFAQ',getLangFAQ);
      if(getLangFAQ.isFetched && !getLangFAQ.isFetching && !getLangFAQ.err ){
            this.setState({
              modalEdit : true,
              dataLang : getLangFAQ.data 
            })
        }
    }
    if(updateFAQSequnce !== prevProps.updateFAQSequnce){
      //console.log('getLangFAQ',updateFAQSequnce);
      if(updateFAQSequnce.isFetched && !updateFAQSequnce.isFetching && !updateFAQSequnce.err ){
        this.props._fetchFAQ(page, limit);
        notification('success', updateFAQSequnce.message);    
        this.setState({
              modalEdit : false,
              // data : getLangFAQ.data 
            })
        }
      }
  //     }else if(!deleteFAQ.getLangFAQ && !deleteFAQ.getLangFAQ && getLangFAQ.err ){
  //         notification('error', deleteFAQ.message);
  //         return 
  }

    onChangeHandler= (name, value) => {
      //console.log('name, value', name, value);
      let { isActive } = this.state;
      if(name === 'isActive') return this.setState({
        isActive : !isActive
      })
      this.setState({ [name] : value })
    }

    onSeqChangeHandler= (id, value) => {
      //console.log('name, value', id, value);
      let { dataLang } = this.state;
      if(value <= 0) return notification('error', 'Negative values are not allowed.')
      if(value > (dataLang.length + 1) ) return notification('error', 'Value cannot be greater than the total records.')
      const ind = dataLang.findIndex((d) => d._id.toString() === id.toString() )
      //console.log('data FAQ',dataLang);
      dataLang[ind]['seqNum'] = value
      //console.log('ind',ind)
      this.setState({ dataLang : dataLang })
    }

  //   onPageChange = (page) => {
  //     let { limit } = this.state;
  //     // console.clear()
  //     console.log("check for the page change=============",page,limit)
  //     this.props._fetchCategories();
  // }


    renderIsActiveColumn = (data) =>{
      //console.log("check for the data======================",data)
      return <CustomInput type="switch" id={Math.random()*10000} defaultChecked={data.data.isActive} />
    }

    toggleModal = () => {
        this.setState((prevStates)=>{
            return {
                modal : !prevStates.modal
            }
        })
    }

    toggleEditModal = () => {
      this.setState({ modalEdit : !this.state.modalEdit });
    }
    
    fetchLangaugeFAQ = (lang) => {
      this.props._fetchLangFAQ(lang)
    }

    onDeleteHandler = () => {
        let { faq_id } = this.state;
        this.props._deleteFAQ(faq_id);
    }

    onUpdatehandler = (e) => {
      e.preventDefault();
      const { dataLang } = this.state;
      const dupli = {};
      let isDuplicate = false
      dataLang.map((d) => {
        if(dupli[d.seqNum]) isDuplicate = true;
        dupli[d.seqNum] = 1;
      })
      if(isDuplicate) return notification('error','One Sequence number cannot be assigned to more than one record.')
      this.props._updateFAQSequnce(dataLang);
    }

    render(){
        //console.log('this.props allusers', this.props);
        let { headsAg, data, modal, modalEdit, dataLang } = this.state;
        let { fetchFAQ : { isFetched } } = this.props;
        return (
            <Container>
                <Row>
                    <Col md={12} >
                        <Card>
                            <CardBody>
                                <Row className="page-title bb-1 border-gray pb-2">
                                  <Col md={6}>
                                  <h3 className=''>FAQ</h3>  
                                  </Col>
                                  <Col md={6} className="text-right">
                                    <Button color="primary" onClick={()=>{ this.props.history.push('/faq/add') } }>Add +</Button>
                                  </Col>
                                </Row>
                                <Row className="mt-2">
                                    <Col md={12} className="text-right">
                                      <Button color="primary" onClick={()=>{ this.fetchLangaugeFAQ('en') }}>English Seq</Button>
                                      <Button color="primary" onClick={()=> { this.fetchLangaugeFAQ('fr') } }>French Seq</Button>
                                      <Button color="primary" onClick={()=>{ this.fetchLangaugeFAQ('es') } }>Spanish Seq</Button>
                                    </Col>
                                    <Col md={12} style={{ height: '90vh', width: '600px' }} className="ag-theme-material">
                                        <TableComponent 
                                            rowsData={ data } 
                                            headsAg={headsAg}
                                            editRecord={this.editRecord} 
                                            rowSelectable={'multiple'} 
                                            rowsPerPage={20}
                                            isFetched = {isFetched} 
                                            {...this.props} 
                                            noRecordsText = {'No FAQ Found!'}
                                            fetchingRecordsText = {'Fetching...'}
                                        />
                                        <Col md={12} className="text-right mt-2">
                                        {/* <TablePagination onPageChange={this.onPageChange} {...this.state}/> */}
                                    </Col>
                                    </Col>
                                    <Col md={12}>
                                      <CommonModal
                                        toggle={this.toggleModal}
                                          customClass = {'test'}
                                          cancelButtonText = {'Cancel'}
                                          cancelAction = {this.toggleModal}
                                          confirmAction={this.onDeleteHandler}
                                          confirmButtonText={'Delete'}
                                          modal={modal}
                                          modalText={'Are you Sure you want to delete this FAQ ? '}
                                          modalTitle={'Delete FAQ'}
                                      />
                                      <FAQSeqModal
                                        modal={modalEdit} 
                                        toggle={ this.toggleEditModal }
                                        modalTitle={ 'Set Sequence' }
                                        data={dataLang}
                                        confirmAction = { this.onUpdatehandler }
                                        onChangeHandler= { this.onSeqChangeHandler }
                                        onUpdatehandler ={ this.onUpdatehandler }
                                      />
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        )
    }
}

export default listFAQ