import { connect } from "react-redux";
import * as _ from "lodash";
import EditFAQ from './../../components/FAQ/EditFAQ'
import { editFAQ, fetchFaqDetails } from './../../redux/actions/faqActions'

export const mapStateToProps = state => {
  return {
    updateFAQ: state.updateFAQ, 
    FAQDetails: state.FAQDetails, 
  };
};

export const mapDispatchToProps = (dispatch) => {
  return {
    _onEdit: (data) => {
      return dispatch(editFAQ(data));
    },
    _fetchDetails: (id) => {
        return dispatch(fetchFaqDetails(id));
      },
  };
};

const EditFAQContainer = connect(mapStateToProps, mapDispatchToProps)(EditFAQ);

export default EditFAQContainer;
