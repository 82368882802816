console.log("process.env", process.env);

let { REACT_APP_API_URL, REACT_APP_NOTIFICATION_URL, REACT_APP_FINDER_API_URL } = process.env;
let API_URL = REACT_APP_API_URL;
let NOTIFICATION_URL = REACT_APP_NOTIFICATION_URL;
let FINDER_URL = REACT_APP_FINDER_API_URL
console.log("api url", REACT_APP_API_URL);
// let apiURL = "https://tbo-api.herokuapp.com/"
let AUTHORIZATION_HEADER = "authorization";
let AUTHTOKEN = "UserAuthToken";
export { API_URL, AUTHORIZATION_HEADER, AUTHTOKEN, NOTIFICATION_URL, FINDER_URL };
