export const LOGINSUCCESS = "LOGINSUCCESS";
export const LOGINREQUEST = "LOGINREQUEST";
export const LOGINERROR = "LOGINERROR";
export const LOGINCHECKFAILURE = "LOGINCHECKFAILURE";
export const LOGOUTSUCCESS = "LOGOUTSUCCESS";
export const LOGOUTREQUEST = "LOGOUTREQUEST";
export const LOGOUTERROR = "LOGOUTERROR";

//create cat action types
export const CREATECATEGORYREQUEST = "CREATECATEGORYREQUEST";
export const CREATECATEGORYSUCCESS = "CREATECATEGORYSUCCESS";
export const CREATECATEGORYERROR = "CREATECATEGORYERROR";
export const CREATECATEGORYCLEAR = "CREATECATEGORYCLEAR";

//get all categories action types
export const GETALLCATEGORIESSREQUEST = "GETALLCATEGORIESSREQUEST";
export const GETALLCATEGORIESSUCCESS = "GETALLCATEGORIESSSUCCESS";
export const GETALLCATEGORIESSERROR = "CREATECATEGORYERROR";
export const GETALLCATEGORIESSCLEAR = "GETALLCATEGORIESSCLEAR";

//get all users action types
export const GETALLUSERSREQUEST = "GETALLUSERSREQUEST";
export const GETALLUSERSUCCESS = "GETALLUSERSSUCCESS";
export const GETALLUSERSERROR = "CREATECATEGORYERROR";
export const GETALLUSERSCLEAR = "GETALLUSERSCLEAR";

//add new admin action types
export const ADDADMINREQUEST = "ADDADMINREQUEST";
export const ADDADMINSUCCESS = "ADDADMINSUCCESS";
export const ADDADMINERROR = "ADDADMINERROR";
export const ADDADMINCLEAR = "ADDADMINCLEAR";

//get all admins
export const ADMINLISTREQUEST = "ADMINLISTREQUEST";
export const ADMINLISTSUCCESS = "ADMINLISTSUCCESS";
export const ADMINLISTERROR = "ADMINLISTERROR";
export const ADMINLISTCLEAR = "ADMINLISTCLEAR";

//get all banned email
export const BANNEDEMAILLISTREQUEST = "BANNEDEMAILLISTREQUEST";
export const BANNEDEMAILLISTSUCCESS = "BANNEDEMAILLISTSUCCESS";
export const BANNEDEMAILLISTERROR = "BANNEDEMAILLISTERROR";
export const BANNEDEMAILLISTCLEAR = "BANNEDEMAILLISTCLEAR";

//add banned email
export const ADDBANNEDREQUEST = "ADDBANNEDREQUEST";
export const ADDBANNEDSUCCESS = "ADDBANNEDSUCCESS";
export const ADDBANNEDERROR = "ADDBANNEDERROR";
export const ADDBANNEDCLEAR = "ADDBANNEDCLEAR";

//remove banned email
export const REMOVEBANNEDREQUEST = "REMOVEBANNEDREQUEST";
export const REMOVEBANNEDSUCCESS = "REMOVEBANNEDSUCCESS";
export const REMOVEBANNEDERROR = "REMOVEBANNEDERROR";
export const REMOVEBANNEDCLEAR = "REMOVEBANNEDCLEAR";

//reset password
//get all admins
export const RESETPASSWORDREQUEST = "RESETPASSWORDREQUEST";
export const RESETPASSWORDSUCCESS = "RESETPASSWORDSUCCESS";
export const RESETPASSWORDERROR = "RESETPASSWORDERROR";
export const RESETPASSWORDCLEAR = "RESETPASSWORDCLEAR";

// update profile action types
export const UPDATEPROFILEREQUEST = "UPDATEPROFILEREQUEST";
export const UPDATEPROFILESUCCESS = "UPDATEPROFILESUCCESS";
export const UPDATEPROFILEERROR = "UPDATEPROFILEERROR";
export const UPDATEPROFILECLEAR = "UPDATEPROFILECLEAR";

//get all app users
export const APPUSERSLISTREQUEST = "APPUSERSLISTREQUEST";
export const APPUSERSLISTSUCCESS = "APPUSERSLISTSUCCESS";
export const APPUSERSLISTERROR = "APPUSERSLISTERROR";
export const APPUSERSLISTCLEAR = "APPUSERSLISTCLEAR";

//add categories
export const ADDCATEGORYTITLE = "ADDCATEGORYTITLE";

export const APPUSERBLOCKREQUEST = "APPUSERBLOCKREQUEST";
export const APPUSERBLOCKSUCCESS = "APPUSERBLOCKSUCCESS";
export const APPUSERBLOCKERROR = "APPUSERBLOCKERROR";

//searchAdmin
export const SEARCHREQUEST = "SEARCHREQUEST";
export const SEARCHSUCCESS = "SEARCHSUCCESS";
export const SEARCHERROR = "SEARCHERROR";

//searchUser
export const APPUSERSEARCHREQUEST = "APPUSERSEARCHREQUEST";
export const APPUSERSEARCHSUCCESS = "APPUSERSEARCHSUCCESS";
export const APPUSERSEARCHERROR = "APPUSERSEARCHERROR";

//meeting hosts
export const LISTHOSTSREQUEST = "LISTHOSTSREQUEST";
export const LISTHOSTSSUCCESS = "LISTHOSTSSUCCESS";
export const LISTHOSTSERROR = "LISTHOSTSERROR";

//create meetings
export const CREATEMEETINGREQUEST = "CREATEMEETINGREQUEST";
export const CREATEMEETINGSUCCESS = "CREATEMEETINGSUCCESS";
export const CREATEMEETINGERROR = "CREATEMEETINGERROR";

//FETCH meetings
export const FETCHMEETINGREQUEST = "FETCHMEETINGREQUEST";
export const FETCHMEETINGSUCCESS = "FETCHMEETINGSUCCESS";
export const FETCHMEETINGERROR = "FETCHMEETINGERROR";

//DELETE meetings
export const DELETEMEETINGREQUEST = "DELETEMEETINGREQUEST";
export const DELETEMEETINGSUCCESS = "DELETEMEETINGSUCCESS";
export const DELETEMEETINGERROR = "DELETEMEETINGERROR";

//post premium content
//create CONTENTs
export const CREATECONTENTREQUEST = "CREATECONTENTREQUEST";
export const CREATECONTENTSUCCESS = "CREATECONTENTSUCCESS";
export const CREATECONTENTERROR = "CREATECONTENTERROR";

//FETCH PREMIUM CONTENT LIST
export const FETCHCONTENTREQUEST = "FETCHCONTENTREQUEST";
export const FETCHCONTENTSUCCESS = "FETCHCONTENTSUCCESS";
export const FETCHCONTENTERROR = "FETCHCONTENTERROR";

//create VIRTUALTOUR
export const CREATEVIRTUALTOURREQUEST = "CREATEVIRTUALTOURREQUEST";
export const CREATEVIRTUALTOURSUCCESS = "CREATEVIRTUALTOURSUCCESS";
export const CREATEVIRTUALTOURERROR = "CREATEVIRTUALTOURERROR";

//FETCH VIRTUALTOUR LIST
export const FETCHVIRTUALTOURREQUEST = "FETCHVIRTUALTOURREQUEST";
export const FETCHVIRTUALTOURSUCCESS = "FETCHVIRTUALTOURSUCCESS";
export const FETCHVIRTUALTOURERROR = "FETCHVIRTUALTOURERROR";

//create NOTIFICATIONS
export const CREATENOTIFICATIONSREQUEST = "CREATENOTIFICATIONSREQUEST";
export const CREATENOTIFICATIONSSUCCESS = "CREATENOTIFICATIONSSUCCESS";
export const CREATENOTIFICATIONSERROR = "CREATENOTIFICATIONSERROR";

//FETCH NOTIFICATIONS LIST
export const FETCHNOTIFICATIONSREQUEST = "FETCHNOTIFICATIONSREQUEST";
export const FETCHNOTIFICATIONSSUCCESS = "FETCHNOTIFICATIONSSUCCESS";
export const FETCHNOTIFICATIONSERROR = "FETCHNOTIFICATIONSERROR";

//FETCH NOTIFICATIONS LIST
export const FETCHDASHBOARDSTATSREQUEST = "FETCHDASHBOARDREQUEST";
export const FETCHDASHBOARDSTATSSUCCESS = "FETCHDASHBOARDSUCCESS";
export const FETCHDASHBOARDSTATSERROR = "FETCHDASHBOARDERROR";

//DELETE ADMINs
export const DELETEADMINREQUEST = "DELETEADMINREQUEST";
export const DELETEADMINSUCCESS = "DELETEADMINSUCCESS";
export const DELETEADMINERROR = "DELETEADMINERROR";

//DELETE ADMINs
export const UPDATEADMINREQUEST = "UPDATEADMINREQUEST";
export const UPDATEADMINSUCCESS = "UPDATEADMINSUCCESS";
export const UPDATEADMINERROR = "UPDATEADMINERROR";

//create faq action types
export const CREATEFAQREQUEST = "CREATEFAQREQUEST";
export const CREATEFAQSUCCESS = "CREATEFAQSUCCESS";
export const CREATEFAQERROR = "CREATEFAQERROR";
export const CREATEFAQCLEAR = "CREATEFAQCLEAR";

//get all faq action types
export const GETALLFAQREQUEST = "GETALLFAQREQUEST";
export const GETALLFAQSUCCESS = "GETALLFAQSUCCESS";
export const GETALLFAQERROR = "CREATEFAQERROR";
export const GETALLFAQCLEAR = "GETALLFAQCLEAR";

//get faq details action types
export const GETFAQDETAILSREQUEST = "GETFAQDETAILSREQUEST";
export const GETFAQDETAILSSUCCESS = "GETFAQDETAILSSUCCESS";
export const GETFAQDETAILSERROR = "CREATEFAQERROR";
export const GETFAQDETAILSCLEAR = "GETFAQDETAILSCLEAR";

//UPDATE faq action types
export const UPDATEFAQREQUEST = "UPDATEFAQREQUEST";
export const UPDATEFAQSUCCESS = "UPDATEFAQSUCCESS";
export const UPDATEFAQERROR = "UPDATEFAQERROR";
export const UPDATEFAQCLEAR = "UPDATEFAQCLEAR";

//DELETE faq action types
export const DELETEFAQREQUEST = "DELETEFAQREQUEST";
export const DELETEFAQSUCCESS = "DELETEFAQSUCCESS";
export const DELETEFAQERROR = "DELETEFAQERROR";
export const DELETEFAQCLEAR = "DELETEFAQCLEAR";

export const GETALLFEELINGSREQUEST = "GETALLFEELINGSREQUEST";
export const GETALLFEELINGSUCCESS = "GETALLFEELINGSSUCCESS";
export const GETALLFEELINGSERROR = "CREATEFEELINGERROR";
export const GETALLFEELINGSCLEAR = "GETALLFEELINGSCLEAR";

export const GETALLREACTIONSREQUEST = "GETALLREACTIONSREQUEST";
export const GETALLREACTIONSUCCESS = "GETALLREACTIONSSUCCESS";
export const GETALLREACTIONSERROR = "CREATEFEELINGERROR";
export const GETALLREACTIONSCLEAR = "GETALLREACTIONSCLEAR";

//update a Feeling
export const UPDATEFEELINGREQUEST = "UPDATEFEELINGREQUEST";
export const UPDATEFEELINGSUCCESS = "UPDATEFEELINGSUCCESS";
export const UPDATEFEELINGERROR = "UPDATEFEELINGERROR";
export const UPDATEFEELINGCLEAR = "UPDATEFEELINGCLEAR";

//update a Reaction
export const UPDATEREACTIONREQUEST = "UPDATEREACTIONREQUEST";
export const UPDATEREACTIONSUCCESS = "UPDATEREACTIONSUCCESS";
export const UPDATEREACTIONERROR = "UPDATEREACTIONERROR";
export const UPDATEREACTIONCLEAR = "UPDATEREACTIONCLEAR";

//DELETE a Feeling
export const DELETEFEELINGREQUEST = "DELETEFEELINGREQUEST";
export const DELETEFEELINGSUCCESS = "DELETEFEELINGSUCCESS";
export const DELETEFEELINGERROR = "DELETEFEELINGERROR";
export const DELETEFEELINGCLEAR = "DELETEFEELINGCLEAR";

//DELETE a Reaction
export const DELETEREACTIONREQUEST = "DELETEREACTIONREQUEST";
export const DELETEREACTIONSUCCESS = "DELETEREACTIONSUCCESS";
export const DELETEREACTIONERROR = "DELETEREACTIONERROR";
export const DELETEREACTIONCLEAR = "DELETEREACTIONCLEAR";

//DELETE PREMIUM CONTENT LIST
export const DELETECONTENTREQUEST = "DELETECONTENTREQUEST";
export const DELETECONTENTSUCCESS = "DELETECONTENTSUCCESS";
export const DELETECONTENTERROR = "DELETECONTENTERROR";

//UPDATE PREMIUM CONTENT LIST
export const UPDATECONTENTREQUEST = "UPDATECONTENTREQUEST";
export const UPDATECONTENTSUCCESS = "UPDATECONTENTSUCCESS";
export const UPDATECONTENTERROR = "UPDATECONTENTERROR";

//FETCH DONATIONS LIST
export const FETCHDONATIONSTATSREQUEST = "FETCHDONATIONSREQUEST";
export const FETCHDONATIONSSTATSSUCCESS = "FETCHDONATIONSSUCCESS";
export const FETCHDONATIONSSTATSERROR = "FETCHDONATIONSERROR";

//FETCH TCs
export const FETCHTCREQUEST = "FETCHTCREQUEST";
export const FETCHTCSUCCESS = "FETCHTCSUCCESS";
export const FETCHTCERROR = "FETCHTCERROR";

// //FETCH NOTIFICATIONS LIST
// export const FETCHNOTIFICATIONSREQUEST = 'FETCHNOTIFICATIONSREQUEST'
// export const FETCHNOTIFICATIONSSUCCESS = 'FETCHNOTIFICATIONSSUCCESS'
// export const FETCHNOTIFICATIONSERROR = 'FETCHNOTIFICATIONSERROR'



//delete user actions 
export const APPUSERDELETEREQUEST = 'APPUSERDELETEREQUEST'
export const APPUSERDELETESUCCESS = 'APPUSERDELETESUCCESS'
export const APPUSERDELETEERROR = 'APPUSERDELETEERROR'
// //UPDATE REACTION LIST
// export const UPDATEREACTIONREQUEST = "UPDATEREACTIONREQUEST";
// export const UPDATEREACTIONSUCCESS = "UPDATEREACTIONSUCCESS";
// export const UPDATEREACTIONERROR = "UPDATEREACTIONERROR";

//meeting LANUGAGES
export const LISTLANUGAGESREQUEST = 'LISTLANUGAGESREQUEST'
export const LISTLANUGAGESSUCCESS = 'LISTLANUGAGESSUCCESS'
export const LISTLANUGAGESERROR = 'LISTLANUGAGESERROR'



//create CONFIG
export const CREATECONFIGREQUEST = 'CREATECONFIGREQUEST'
export const CREATECONFIGSUCCESS = 'CREATECONFIGSUCCESS'
export const CREATECONFIGERROR = 'CREATECONFIGERROR'


//FETCH CONFIG LIST
export const FETCHCONFIGREQUEST = 'FETCHCONFIGREQUEST'
export const FETCHCONFIGSUCCESS = 'FETCHCONFIGSUCCESS'
export const FETCHCONFIGERROR = 'FETCHCONFIGERROR'


//Create app user reports
export const CREATEAPPUSERSREPORTREQUEST = "CREATEAPPUSERSREPORTREQUEST";
export const CREATEAPPUSERSREPORTSUCCESS = "CREATEAPPUSERSREPORTSUCCESS";
export const CREATEAPPUSERSREPORTERROR = "CREATEAPPUSERSREPORTERROR";
export const CREATEAPPUSERSREPORTCLEAR = "CREATEAPPUSERSREPORTCLEAR";


//create MEETINGS Reports action types
export const CREATEMEETINGSREPORTREQUEST = "CREATEMEETINGSREPORTREQUEST";
export const CREATEMEETINGSREPORTSUCCESS = "CREATEMEETINGSREPORTSUCCESS";
export const CREATEMEETINGSREPORTERROR = "CREATEMEETINGSREPORTERROR";
export const CREATEMEETINGSREPORTCLEAR = "CREATEMEETINGSREPORTCLEAR";


//create CONTRIBUTION REPORTS action types
export const CREATECONTRIBUTIONSREPORTREQUEST = "CREATECONTRIBUTIONSREPORTREQUEST";
export const CREATECONTRIBUTIONSREPORTSUCCESS = "CREATECONTRIBUTIONSREPORTSUCCESS";
export const CREATECONTRIBUTIONSREPORTERROR = "CREATECONTRIBUTIONSREPORTERROR";
export const CREATECONTRIBUTIONSREPORTCLEAR = "CREATECONTRIBUTIONSREPORTCLEAR";


//DELETE A NOTIFICATIONS
export const DELETENOTIFICATIONSREQUEST = "DELETENOTIFICATIONSREQUEST";
export const DELETENOTIFICATIONSSUCCESS = "DELETENOTIFICATIONSSUCCESS";
export const DELETENOTIFICATIONSERROR = "DELETENOTIFICATIONSERROR";


//UPDATE A NOTIFICATIONS
export const UPDATENOTIFICATIONSREQUEST = "UPDATENOTIFICATIONSREQUEST";
export const UPDATENOTIFICATIONSSUCCESS = "UPDATENOTIFICATIONSSUCCESS";
export const UPDATENOTIFICATIONSERROR = "UPDATENOTIFICATIONSERROR";

//get LANG faq action types
export const GETLANGFAQREQUEST = "GETLANGFAQREQUEST";
export const GETLANGFAQSUCCESS = "GETLANGFAQSUCCESS";
export const GETLANGFAQERROR = "CREATEFAQERROR";
export const GETLANGFAQCLEAR = "GETLANGFAQCLEAR";


//UPDATE FAQSEQUENCE action types
export const UPDATEFAQSEQUENCEREQUEST = "UPDATEFAQSEQUENCEREQUEST";
export const UPDATEFAQSEQUENCESUCCESS = "UPDATEFAQSEQUENCESUCCESS";
export const UPDATEFAQSEQUENCEERROR = "UPDATEFAQSEQUENCEERROR";
export const UPDATEFAQSEQUENCECLEAR = "UPDATEFAQSEQUENCECLEAR";


//create REPORTEDCONTENT
export const CREATEREPORTEDCONTENTREQUEST = "CREATEREPORTEDCONTENTREQUEST";
export const CREATEREPORTEDCONTENTSUCCESS = "CREATEREPORTEDCONTENTSUCCESS";
export const CREATEREPORTEDCONTENTERROR = "CREATEREPORTEDCONTENTERROR";


//FETCH REPORTEDCONTENT LIST
export const FETCHREPORTEDCONTENTREQUEST = "FETCHREPORTEDCONTENTREQUEST";
export const FETCHREPORTEDCONTENTSUCCESS = "FETCHREPORTEDCONTENTSUCCESS";
export const FETCHREPORTEDCONTENTERROR = "FETCHREPORTEDCONTENTERROR";

//FETCH REPORTEEUSERS LIST
export const FETCHREPORTEEUSERSREQUEST = "FETCHREPORTEEUSERSREQUEST";
export const FETCHREPORTEEUSERSSUCCESS = "FETCHREPORTEEUSERSSUCCESS";
export const FETCHREPORTEEUSERSERROR = "FETCHREPORTEEUSERSERROR";


//UPDATE A REPORTEDCONTENT
export const UPDATEREPORTEDCONTENTREQUEST = "UPDATEREPORTEDCONTENTREQUEST";
export const UPDATEREPORTEDCONTENTSUCCESS = "UPDATEREPORTEDCONTENTSUCCESS";
export const UPDATEREPORTEDCONTENTERROR = "UPDATEREPORTEDCONTENTERROR";

//UPDATE POST CATEGORY
export const UPDATEPOSTCATEGORYREQUEST = "UPDATEPOSTCATEGORYREQUEST";
export const UPDATEPOSTCATEGORYSUCCESS = "UPDATEPOSTCATEGORYSUCCESS";
export const UPDATEPOSTCATEGORYERROR = "UPDATEPOSTCATEGORYERROR";

//ADD POST CATEGORY
export const ADDPOSTCATEGORYREQUEST = "ADDPOSTCATEGORYREQUEST";
export const ADDPOSTCATEGORYSUCCESS = "ADDPOSTCATEGORYSUCCESS";
export const ADDPOSTCATEGORYERROR = "ADDPOSTCATEGORYERROR";

//MAKE ALL POST CATEGORY VISIBLE
export const MAKECATEGORYVISIBLEREQUEST = "MAKECATEGORYVISIBLEREQUEST";
export const MAKECATEGORYVISIBLESUCCESS = "MAKECATEGORYVISIBLESUCCESS";
export const MAKECATEGORYVISIBLEERROR = "MAKECATEGORYVISIBLEERROR";

//MAKE ALL POST CATEGORY VISIBLE
export const GETUSERTRACKINGLISTREQUEST = "GETUSERTRACKINGLISTREQUEST";
export const GETUSERTRACKINGLISTSUCCESS = "GETUSERTRACKINGLISTSUCCESS";
export const GETUSERTRACKINGLISTERROR = "GETUSERTRACKINGLISTERROR";

//GET ALL RESOURCES
export const GETALLRESOURCESREQUEST = "GETALLRESOURCESREQUEST";
export const GETALLRESOURCESSUCCESS = "GETALLRESOURCESSUCCESS";
export const GETALLRESOURCESERROR = "GETALLRESOURCESERROR";

//STORE RESOURCES
export const UPLOADRESOURCESREQUEST = "UPLOADRESOURCESREQUEST";
export const UPLOADRESOURCESSUCCESS = "UPLOADRESOURCESSUCCESS";
export const UPLOADRESOURCESERROR = "UPLOADRESOURCESERROR";

//UPDATE RESOURCES
export const UPDATERESOURCESREQUEST = "UPDATERESOURCESREQUEST";
export const UPDATERESOURCESSUCCESS = "UPDATERESOURCESSUCCESS";
export const UPDATERESOURCESERROR = "UPDATERESOURCESERROR";

//DELETE RESOURCES
export const DELETERESOURCESREQUEST = "DELETERESOURCESREQUEST";
export const DELETERESOURCESSUCCESS = "DELETERESOURCESSUCCESS";
export const DELETERESOURCESERROR = "DELETERESOURCESERROR";

//add banned email
export const GETFINDERMEETINGSREQUEST = "GETFINDERMEETINGSREQUEST";
export const GETFINDERMEETINGSSUCCESS = "GETFINDERMEETINGSSUCCESS";
export const GETFINDERMEETINGSERROR = "GETFINDERMEETINGSERROR";
export const GETFINDERMEETINGSCLEAR = "GETFINDERMEETINGSCLEAR";

export const GETFINDERMEETINGREQUEST = "GETFINDERMEETINGREQUEST";
export const GETFINDERMEETINGSUCCESS = "GETFINDERMEETINGSUCCESS";
export const GETFINDERMEETINGERROR = "GETFINDERMEETINGERROR";
export const GETFINDERMEETINGCLEAR = "GETFINDERMEETINGCLEAR";

export const UPDATEFINDERMEETINGREQUEST = "UPDATEFINDERMEETINGREQUEST";
export const UPDATEFINDERMEETINGSUCCESS = "UPDATEFINDERMEETINGSUCCESS";
export const UPDATEFINDERMEETINGERROR = "UPDATEFINDERMEETINGERROR";
export const UPDATEFINDERMEETINGCLEAR = "UPDATEFINDERMEETINGCLEAR";

export const UPLOADFINDERMEETINGREQUEST = "UPLOADFINDERMEETINGREQUEST";
export const UPLOADFINDERMEETINGSUCCESS = "UPLOADFINDERMEETINGSUCCESS";
export const UPLOADFINDERMEETINGERROR = "UPLOADFINDERMEETINGERROR";
export const UPLOADFINDERMEETINGCLEAR = "UPLOADFINDERMEETINGCLEAR";

export const TRANSLATEFINDERMEETINGREQUEST = "TRANSLATEFINDERMEETINGREQUEST";
export const TRANSLATEFINDERMEETINGSUCCESS = "TRANSLATEFINDERMEETINGSUCCESS";
export const TRANSLATEFINDERMEETINGERROR = "TRANSLATEFINDERMEETINGERROR";
export const TRANSLATEFINDERMEETINGCLEAR = "TRANSLATEFINDERMEETINGCLEAR";

export const TRANSLATESINGLEMEETREQUEST = "TRANSLATESINGLEMEETREQUEST";
export const TRANSLATESINGLEMEETSUCCESS = "TRANSLATESINGLEMEETSUCCESS";
export const TRANSLATESINGLEMEETERROR = "TRANSLATESINGLEMEETERROR";
export const TRANSLATESINGLEMEETCLEAR = "TRANSLATESINGLEMEETCLEAR";
