import React, {PureComponent} from 'react';
import {Col, Container, Row, Card, CardBody} from 'reactstrap';
import AnimatedNumber from 'react-animated-number';

export default class DefaultDashboard extends React.Component {

    state={
        data : {},
        totalMeetings : 0, 
        dailyMeetings : 0, 
        weeklyMeetings : 0, 
        newUsers : 0, 
        allUsers : 0,
        deactivatedUsers : 0,
        alanonMeetings : 0,
        alateenMeetings : 0,
        totalAlanonMeetings : 0,
        lastDaysPosts : 0,
        lastDaysPostsUsers : 0,
        lastDaysComments :0,
        lastDaysLoginUsers : 0,
        isFetching : true
    }

    componentDidMount(){
        //console.log('this.props', this.props)
        this.props._fetchStats();
    }

    static getDerivedStateFromProps(props, state){
        //console.log('props stats', props)
        if(props.stats.data !== state.data && props.stats.isFetched && !props.stats.err){
            let { data } = props.stats
            return {
                data,
                totalMeetings : data.totalMeetings, 
                dailyMeetings : data.dailyMeetings, 
                weeklyMeetings :data.weeklyMeetings, 
                newUsers : data.newUsers,
                allUsers : data.allUsers,
                deactivatedUsers : data.deactivatedUsers,
                alanonMeetings : data.alanonMeetings,
                alateenMeetings : data.alateenMeetings,
                totalAlanonMeetings : data.totalAlanonMeetings,
                lastDaysPosts : data.lastDaysPosts,
                lastDaysPostsUsers : data.lastDaysPostsUsers,
                lastDaysComments :data.lastDaysComments,
                lastDaysLoginUsers : data.lastDaysLoginUsers,
                isFetching : false
            }
        }
        if(props.stats.data !== state.data && !props.stats.isFetched && props.stats.err){
            return {
                isFetching : false
            }
        }
        return null;
    }

  render() {
      let { totalMeetings, weeklyMeetings, dailyMeetings, newUsers, allUsers, deactivatedUsers, alanonMeetings, alateenMeetings, totalAlanonMeetings , lastDaysPosts ,lastDaysPostsUsers, lastDaysComments, lastDaysLoginUsers } = this.state;
    return (
      <Container className='dashboard'>
        <Row>
          <Col md={12}>
            <h3 className='page-title'>Dashboard</h3>
          </Col>
          <Col md={12}>
            <Card>
                <CardBody>
                    <Row>
                        <Col md={12}>
                            <h3 className='page-title text-center' style={{ color : 'rgb(62, 163, 252)' }}>Meetings</h3>
                        </Col>
                        <Col md={4}>
                            <Card>
                                <CardBody className="text-center">
                                {

                                }
                                <AnimatedNumber component="text" value={alanonMeetings} initialValue={0}
                                        style={{
                                            transition: '0.8s ease-out',
                                            fontSize: 42,
                                            color : 'rgb(62, 163, 252)',
                                            transitionProperty:
                                                'background-color, color, opacity'
                                        }}
                                        frameStyle={perc => (
                                            perc === 100 ? {} : {color: 'rgb(62, 163, 252)'}
                                        )}
                                        stepPrecision={0}
                                        duration={300}
                                        // formatValue={totalValue => Math.abs(totalvalue)}
                                    />
                                    <h3 style={{fontSize : "1.5vw"}} >Al-Anon Groups</h3>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col md={4}>
                            <Card>
                                <CardBody className="text-center">
                                {

                                }
                                <AnimatedNumber component="text" value={alateenMeetings} initialValue={0}
                                        style={{
                                            transition: '0.8s ease-out',
                                            fontSize: 42,
                                            color : 'rgb(62, 163, 252)',
                                            transitionProperty:
                                                'background-color, color, opacity'
                                        }}
                                        frameStyle={perc => (
                                            perc === 100 ? {} : {color: 'rgb(62, 163, 252)'}
                                        )}
                                        stepPrecision={0}
                                        duration={300}
                                        // formatValue={totalValue => Math.abs(totalvalue)}
                                    />
                                    <h3 style={{fontSize : "1.5vw"}}>Alateen Groups</h3>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col md={4}>
                            <Card>
                                <CardBody className="text-center">
                                {

                                }
                                <AnimatedNumber component="text" value={totalMeetings} initialValue={0}
                                        style={{
                                            transition: '0.8s ease-out',
                                            fontSize: 42,
                                            color : 'rgb(62, 163, 252)',
                                            transitionProperty:
                                                'background-color, color, opacity'
                                        }}
                                        frameStyle={perc => (
                                            perc === 100 ? {} : {color: 'rgb(62, 163, 252)'}
                                        )}
                                        stepPrecision={0}
                                        duration={300}
                                        // formatValue={totalValue => Math.abs(totalvalue)}
                                    />
                                    <h3 style={{fontSize : "1.5vw"}}>Total Groups</h3>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col md={4} sm={12}>
                            <Card>
                            <CardBody className="text-center">
                            {

                            }
                            <AnimatedNumber component="text" value={weeklyMeetings} initialValue={0}
                                    style={{
                                        transition: '0.8s ease-out',
                                        fontSize: 42,
                                        color : 'rgb(62, 163, 252)',
                                        transitionProperty:
                                            'background-color, color, opacity'
                                    }}
                                    frameStyle={perc => (
                                        perc === 100 ? {} : {color: 'rgb(62, 163, 252)'}
                                    )}
                                    stepPrecision={0}
                                    duration={300}
                                    // formatValue={totalValue => Math.abs(totalvalue)}
                                />
                                <h3 style={{fontSize : "1.5vw"}}>Weekly Meetings</h3>
                            </CardBody>
                            </Card>
                        </Col>
                        <Col md={4} sm={12}>
                            <Card>
                            <CardBody className="text-center">
                            {

                            }
                            <AnimatedNumber component="text" value={dailyMeetings} initialValue={0}
                                    style={{
                                        transition: '0.8s ease-out',
                                        fontSize: 42,
                                        color : 'rgb(62, 163, 252)',
                                        transitionProperty:
                                            'background-color, color, opacity'
                                    }}
                                    frameStyle={perc => (
                                        perc === 100 ? {} : {color: 'rgb(62, 163, 252)'}
                                    )}
                                    stepPrecision={0}
                                    duration={300}
                                    // formatValue={totalValue => Math.abs(totalvalue)}
                                />
                                <h3 style={{fontSize : "1.5vw"}}>Daily Meetings</h3>
                            </CardBody>
                            </Card>
                        </Col>
                        <Col md={4} sm={12}>
                            <Card>
                            <CardBody className="text-center">
                            {

                            }
                            <AnimatedNumber component="text" value={totalAlanonMeetings} initialValue={0}
                                    style={{
                                        transition: '0.8s ease-out',
                                        fontSize: 42,
                                        color : 'rgb(62, 163, 252)',
                                        transitionProperty:
                                            'background-color, color, opacity'
                                    }}
                                    frameStyle={perc => (
                                        perc === 100 ? {} : {color: 'rgb(62, 163, 252)'}
                                    )}
                                    stepPrecision={0}
                                    duration={300}
                                    // formatValue={totalValue => Math.abs(totalvalue)}
                                />
                                <h3>Total Meetings</h3>
                            </CardBody>
                            </Card>
                        </Col>
                        <Col className='text-center' md={12}><p onClick={ () => { this.props.history.push('/meetings') } } className="cursor-pointer">View all</p></Col>
                    </Row>
                </CardBody>
            </Card>
        </Col>      
        <Col md={12}>
            <Card>
                <CardBody>
                    <Row>
                        <Col md={12}>
                            <h3 className='page-title text-center' style={{ color : 'rgb(62, 163, 252)' }}>Users</h3>
                        </Col>
                        <Col md={4}>
                            <Card>
                                <CardBody className="text-center">
                                {

                                }
                                <AnimatedNumber component="text" value={newUsers} initialValue={0}
                                        style={{
                                            transition: '0.8s ease-out',
                                            fontSize: 42,
                                            color : 'rgb(62, 163, 252)',
                                            transitionProperty:
                                                'background-color, color, opacity'
                                        }}
                                        frameStyle={perc => (
                                            perc === 100 ? {} : {color: 'rgb(62, 163, 252)'}
                                        )}
                                        stepPrecision={0}
                                        duration={300}
                                        // formatValue={totalValue => Math.abs(totalvalue)}
                                    />
                                    <h3 style={{fontSize : "1.5vw", width : "100%"}}>New Users ( 7days )</h3>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col md={4}>
                            <Card>
                                <CardBody className="text-center">
                                {

                                }
                                <AnimatedNumber component="text" value={allUsers} initialValue={0}
                                        style={{
                                            transition: '0.8s ease-out',
                                            fontSize: 42,
                                            color : 'rgb(62, 163, 252)',
                                            transitionProperty:
                                                'background-color, color, opacity'
                                        }}
                                        frameStyle={perc => (
                                            perc === 100 ? {} : {color: 'rgb(62, 163, 252)'}
                                        )}
                                        stepPrecision={0}
                                        duration={300}
                                        // formatValue={totalValue => Math.abs(totalvalue)}
                                    />
                                    <h3 style={{fontSize : "1.5vw", width : "100%"}}>Total Users ( Active )</h3>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col md={4}>
                            <Card>
                                <CardBody className="text-center">
                                {

                                }
                                <AnimatedNumber component="text" value={deactivatedUsers} initialValue={0}
                                        style={{
                                            transition: '0.8s ease-out',
                                            fontSize: 42,
                                            color : 'rgb(62, 163, 252)',
                                            transitionProperty:
                                                'background-color, color, opacity'
                                        }}
                                        frameStyle={perc => (
                                            perc === 100 ? {} : {color: 'rgb(62, 163, 252)'}
                                        )}
                                        stepPrecision={0}
                                        duration={300}
                                        // formatValue={totalValue => Math.abs(totalvalue)}
                                    />
                                    <h3 style={{fontSize : "1.5vw", width : "100%"}}>Inactive Users ( 30 days )</h3>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col className='text-center' md={12}><p onClick={ () => { this.props.history.push('/users') } } className="cursor-pointer">View all</p></Col>
                        <Col md={4}>
                            <Card>
                                <CardBody className="text-center">
                                {

                                }
                                <AnimatedNumber component="text" value={lastDaysLoginUsers} initialValue={0}
                                        style={{
                                            transition: '0.8s ease-out',
                                            fontSize: 42,
                                            color : 'rgb(62, 163, 252)',
                                            transitionProperty:
                                                'background-color, color, opacity'
                                        }}
                                        frameStyle={perc => (
                                            perc === 100 ? {} : {color: 'rgb(62, 163, 252)'}
                                        )}
                                        stepPrecision={0}
                                        duration={300}
                                        // formatValue={totalValue => Math.abs(totalvalue)}
                                    />
                                    <h3 style={{fontSize : "1.5vw", width : "100%"}}>Users Logged-in ( 30 days )</h3>
                                </CardBody>
                            </Card>
                        </Col>                  
                        <Col md={4}>
                            <Card>
                                <CardBody className="text-center">
                                {

                                }
                                <AnimatedNumber component="text" value={lastDaysPostsUsers} initialValue={0}
                                        style={{
                                            transition: '0.8s ease-out',
                                            fontSize: 42,
                                            color : 'rgb(62, 163, 252)',
                                            transitionProperty:
                                                'background-color, color, opacity'
                                        }}
                                        frameStyle={perc => (
                                            perc === 100 ? {} : {color: 'rgb(62, 163, 252)'}
                                        )}
                                        stepPrecision={0}
                                        duration={300}
                                        // formatValue={totalValue => Math.abs(totalvalue)}
                                    />
                                    <h3 style={{fontSize : "1.5vw", width : "120%"}}>Users created Posts ( 30 days )</h3>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col md={4}>
                            <Card>
                                <CardBody className="text-center">
                                {

                                }
                                <AnimatedNumber component="text" value={lastDaysPosts} initialValue={0}
                                        style={{
                                            transition: '0.8s ease-out',
                                            fontSize: 42,
                                            color : 'rgb(62, 163, 252)',
                                            transitionProperty:
                                                'background-color, color, opacity'
                                        }}
                                        frameStyle={perc => (
                                            perc === 100 ? {} : {color: 'rgb(62, 163, 252)'}
                                        )}
                                        stepPrecision={0}
                                        duration={300}
                                        // formatValue={totalValue => Math.abs(totalvalue)}
                                    />
                                    <h3 style={{fontSize : "1.5vw", width : "100%"}}>Posts ( 30 days )</h3>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col md={12}>
                        <Card>
                            <CardBody className="text-center">
                            {

                            }
                            <AnimatedNumber component="text" value={lastDaysComments} initialValue={0}
                                    style={{
                                        transition: '0.8s ease-out',
                                        fontSize: 42,
                                        color : 'rgb(62, 163, 252)',
                                        transitionProperty:
                                            'background-color, color, opacity'
                                    }}
                                    frameStyle={perc => (
                                        perc === 100 ? {} : {color: 'rgb(62, 163, 252)'}
                                    )}
                                    stepPrecision={0}
                                    duration={300}
                                    // formatValue={totalValue => Math.abs(totalvalue)}
                                />
                                <h3 style={{fontSize : "1.5vw", width : "100%"}}>Comments ( 30 days )</h3>
                            </CardBody>
                        </Card>
                        </Col>
                        </Row>
                    </CardBody>
                </Card>
          </Col>
          
        </Row>
      </Container>
    )
  }
}

