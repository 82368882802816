import React from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CustomInput,
  Button,
  FormGroup,
  Form,
  Input,
  Label,
} from "reactstrap";
import TableComponent from "./../Shared/Table/Table";
import TablePagination from "./../Shared/Table/TablePagination";
import moment from "moment";
import notify from "./../../helpers/notificationHelper";
import ModalAlert from "./../../helpers/modalAlert";
import { Info, ArrowUpward, ArrowDownward } from "@material-ui/icons";
import PenIcon from "mdi-react/PencilOutlineIcon";
import DeleteIcon from "mdi-react/DeleteIcon";
import UserFilterModal from "./../../components/Shared/UsersFilterModal";
import UserInfoAlert from "./UserInfoAlert";

class AllUsers extends React.Component {
  state = {
    headsAg: [],
    isFetching: false,
    page: 1,
    limit: 100,
    users: [],
    selectedRecord: "",
    isBlocked: false,
    total: 0,
    search: "",
    modal: false,
    modalInfo: false,
    isHost: false,
    modalText: "",
    modalTitle: "",
    modalFor: "",
    active: false,
    subscribed: false,
    selectedEmail: "",
    sortBy: "",
    join_date_sort: "desc",
    selectedUser: {},
    gridOptions: undefined,
    isTester: false,
    searchBy: "",
    id: "",
    email: "",
    isActive: false,
    isBlocked: false,
    isSubscribed: false,
    // isTester: false,
    isSearchable: false,
    isSort: false,
    sortOrder: "",
  };
  componentDidMount() {
    let { page, limit } = this.state;
    let {
      users: { users, total },
    } = this.props;
    this.fetchAllUsers(page, limit);
    let headsAg = [
      // {headerName: "Sr. No", field: "i", width:"80", sortable:true, filter:'text',
      //     cellRendererFramework : this.renderIndex
      // },
      { headerName: "Name", field: "firstname", width: 150, height: 50 },
      { headerName: "Lastname", field: "lastname", width: 150 },
      { headerName: "Displayname", field: "displayname" },
      {
        headerName: "Email",
        field: "email",
        width: 300,
        cellStyle: { "word-wrap": "break-word", textAlign: "left" },
        autoHeight: true,
        resizable: true,
        filter: "text",
      },
      {
        headerName: "Join Date",
        field: "createdAt",
        cellRendererFramework: this.renderDate,
      },
    ];

    if (
      localStorage.getItem("UserRole") === "superadmin" ||
      localStorage.getItem("UserRole") === "admin"
    ) {
      headsAg.push({
        headerName: "Actions",
        cellRendererFramework: this.renderAction,
      });
    }

    this.setState({ headsAg, users, page, total });
  }

  static getDerivedStateFromProps(props, state) {
    console.log("***check fpr the props and state", props, state);
    if (
      props.users.users &&
      props.users.users !== state.users &&
      props.users.isFetching &&
      !props.users.isFetched
    ) {
      return {
        isFetching: true,
      };
    }
    if (
      props.users.users &&
      props.users.users !== state.users &&
      !props.users.isFetching &&
      props.users.isFetched
    ) {
      return {
        users: props.users.users,
        total: props.users.total,
        page: state.page,
        isFetching: false,
      };
    } else {
      return null;
    }
    return null;
  }

  componentDidUpdate(prevProps, prevState) {
    // console.log('updated props', prevProps);
    // console.log('updated props', this.props);
    let {
      userBlocked,
      users,
      deleteUser,
      filter_active,
      filter_subscribed,
      filter_tester,
      filter_blocked,
      sort_other,
      sort_join_date,
    } = this.props;

    if (
      prevProps.filter_active !== filter_active ||
      prevProps.filter_subscribed !== filter_subscribed ||
      prevProps.filter_tester !== filter_tester ||
      prevProps.filter_blocked !== filter_blocked ||
      prevProps.sort_other !== sort_other ||
      prevProps.sort_join_date !== sort_join_date
    ) {
      return this.fetchAllUsers();
    }
    if (userBlocked && userBlocked !== prevProps.userBlocked) {
      if (
        userBlocked &&
        userBlocked.updated &&
        userBlocked.requestDone &&
        !userBlocked.err
      ) {
        this.fetchAllUsers();
        this.setState(
          (prevState) => {
            return {
              modal: !prevState.modal,
            };
          },
          () => {
            return notify("success", userBlocked.message);
          }
        );
      }
      if (
        userBlocked &&
        !userBlocked.updated &&
        userBlocked.requestDone &&
        userBlocked.err
      ) {
        // this.fetchAllUsers();
        this.setState(
          {
            modal: false,
          },
          () => {
            return notify("error", userBlocked.message);
          }
        );
      }
      console.log("deleteUser", deleteUser);
    }
    if (deleteUser && deleteUser !== prevProps.deleteUser) {
      if (
        deleteUser &&
        !deleteUser.updated &&
        deleteUser.requestDone &&
        deleteUser.err
      ) {
        // this.fetchAllUsers();
        this.setState(
          {
            modal: false,
          },
          () => {
            return notify("error", deleteUser.message);
          }
        );
      }
      if (
        deleteUser &&
        deleteUser.updated &&
        deleteUser.requestDone &&
        !deleteUser.err
      ) {
        this.fetchAllUsers();
        this.setState(
          (prevState) => {
            return {
              modal: !prevState.modal,
            };
          },
          () => {
            return notify("success", deleteUser.message);
          }
        );
      }
    }
  }

  toggleModal = () => {
    this.setState((prevState) => {
      return {
        modal: !prevState.modal,
      };
    });
  };
  toggleModalInfo = () => {
    this.setState((prevState) => {
      return {
        modalInfo: !prevState.modalInfo,
      };
    });
  };

  renderIndex = (record) => {
    let { page, limit } = this.state;
    let index = record.rowIndex + 1;
    if (page > 1) {
      index = (page - 1) * limit + record.rowIndex + 1;
    }
    return index;
  };

  renderIcon = (record) => {
    return this.state.isSort ? (
      <ArrowDownward></ArrowDownward>
    ) : (
      <ArrowUpward></ArrowUpward>
    );
  };

  editRecord = (record) => {
    let id = record.id;
    console.log("record", record);
  };

  updateUser = () => {
    {
      let userInfo = {
        user_id: this.state.selectedRecord,
        isBlocked: this.state.isBlocked,
      };
      console.log("this is user info=================", userInfo);
      this.props._blockUser(userInfo);
    }
  };

  hostUser = () => {
    let { selectedRecord, isHost, selectedEmail } = this.state;
    let userInfo = {
      user_id: selectedRecord,
      email: selectedEmail,
      isHost: isHost,
    };
    console.log(
      "this is user info in the hostUser function=================",
      userInfo
    );
    this.props._hostUser(userInfo);
  };

  deleteUser = () => {
    let { selectedRecord } = this.state;
    console.log("this is user info=================", selectedRecord);
    this.props._deleteUser(selectedRecord);
  };

  renderDate = (data) => {
    return moment(data.data.createdAt).format("MM-DD-YYYY");
  };

  renderAction = (data) => {
    let buttons = (
      <span>
        {/* <EyeIcon onClick={()=>{ this.setData(record.data, 'modal') } } size={'18px'} className="cursor-pointer"/> */}
        {/* <PenIcon className="cursor-pointer ml-2" size={18} onClick={()=>{ this.setData(record.data, 'editModal') } }/> */}
        {(localStorage.getItem("UserRole") === "superadmin" ||
          localStorage.getItem("UserRole") === "admin") && (
          <>
            <Info
              fontSize="small"
              onClick={() => {
                this.showUserInfo(data.data);
              }}
            ></Info>
            <DeleteIcon
              className="cursor-pointer ml-2"
              size={18}
              name={"isHost"}
              data-id={data.data._id}
              data-email={data.data.email.mail}
              onClick={() => {
                this.setData(data.data, "delete");
              }}
            />
          </>
        )}
      </span>
    );
    return buttons;
  };

  renderPhoneNumber = (data) => {
    let phone = "";
    if (data.data.country_code) {
      phone += data.data.country_code.toString() + "-";
    }
    phone += data.data.phone.number.toString();
    return phone;
    // return data.phone.number
  };
  //not in use
  // renderIsVisibleColumn = (data) => {
  //     return <CustomInput type="switch" id={Math.random()*10000} readOnly defaultChecked={data.data.isVerified} />
  // }

  // renderIsVisibleColumn = (data) => {
  //     return <span>{data.data.isActive ? 'No' : 'Yes' }</span>
  // }

  // renderIsBlockedColumn = (data) => {
  //     return <CustomInput type="switch" id={Math.random()*10000} name={'isBlocked'} data-id={data.data._id} data-email={data.data.email.mail} onClick={this.toggle} checked={data.data.isBlocked} />
  // }

  // renderIsHostColumn = (data) => {
  //     return <CustomInput type="switch" id={Math.random()*10000} name={'isHost'} data-id={data.data._id} data-email={data.data.email.mail}  onClick={this.toggle} checked={data.data.isHost} />
  // }

  // renderIsTesterColumn = (data) => {
  //     return <CustomInput type="switch" id={Math.random()*10000} name={'isTester'} data-id={data.data._id} data-email={data.data.email.mail}  onClick={this.toggle} checked={data.data.isTester} />
  // }

  // renderIsSubscribedColumn = (data) => {
  //     if(data.data.isSubscribed) return 'Yes';
  //     return 'No'
  // }
  // renderIsActiveColumn = (data) => {
  //     if(data.data.isActive) return 'Yes';
  //     return 'No'
  // }

  // renderIsSearchableColumn = (data) => {
  //     return <span>{data.data.isSearchable ? 'Yes' : 'No'}</span>
  // }

  getSelectedRows = () => {
    const selectedNodes = this.gridApi.getSelectedNodes();
    const selectedData = selectedNodes.map((node) => node.data);
    const selectedDataStringPresentation = selectedData
      .map((node) => node.make + " " + node.model)
      .join(", ");
    alert(`Selected nodes: ${selectedDataStringPresentation}`);
  };
  onCellClicked = (event) => {
    console.log(
      "onCellClicked: " + event.data.name + ", col " + event.colIndex,
      event
    );
  };

  onPageChange = (page) => {
    console.clear();
    console.log("page", page);
    let { search, limit, active, subscribed, searchBy } = this.state;
    this.setState({ page, limit }, () => {
      if (search !== "") {
        this.searchAllUsers();
      } else {
        this.fetchAllUsers(page, limit);
      }
    });
  };

  searchHandler = (e) => {
    let { value } = e.target;
    this.setState({
      page: 1
    });
    this.setState({ search: value, onSearch: true }, () => {
      let { search, page, limit, active, subscribed, searchBy } = this.state;
      let searchInfo = {
        searchKey: search,
        page,
        limit,
        active,
        subscribed,
        searchBy,
      };
      search == "" ? this.fetchAllUsers() : this.searchAllUsers(searchInfo);
    });
  };

  searchAllUsers = () => {
    let { search, page, limit, active, subscribed, searchBy } = this.state;
    if (!searchBy) {
      return notify("error", "Select a search by first");
    }
    let searchInfo = {
      searchKey: search,
      page,
      limit,
      active,
      subscribed,
      searchBy,
    };
    this.props._searchUser(searchInfo);
  };

  setData = (record, name) => {
    console.log("record", record);
    console.log("name", name);
    this.setState(
      {
        selectedRecord: record._id,
        modalFor: name,
        modalTitle: "Are You Sure ?",
        modalText: "All data of user would be deleted!",
      },
      () => {
        this.toggleModal();
      }
    );
  };

  showUserInfo = (record) => {
    this.setState(
      {
        id: record._id,
        email: record.email,
        isHost: record.isHost,
        isSubscribed: record.isSubscribed,
        isActive: record.isActive,
        isSearchable: record.isSearchable,
        isTester: record.isTester,
      },
      () => {
        this.toggleModalInfo();
      }
    );
  };

  toggle = (e) => {
    if (e.target) {
      var { name } = e.target;
      // console.log("name-----", name, e.target);
      var selected = e.target.getAttribute("data-id");
      var email = e.target.getAttribute("data-email"); //need to check this, email variable is receiving null here!
      var checked = e.target.checked;
    } else {
      var { name } = e;
      console.log("not target E: ", e);
    }
    console.log("name-----------", name);
    let modalText = "";
    let modalTitle = "";
    if (name == "isBlocked") {
      // email = e.target.getAttribute('data-email')
      modalText = `Are you sure you want to ${
        checked ? "block" : "unblock"
      } ${email}`;
      modalTitle = `${checked ? "Block" : "Unblock"} user`;
      let userInfo = {
        selectedRecord: selected,
        isBlocked: checked,
        modalTitle,
        modalText,
        modalFor: "block",
      };
      console.log("this is user info=================", userInfo);
      this.setState(userInfo, () => {
        this.toggleModal();
      });
    }
    if (name == "isHost") {
      // email = e.target.getAttribute('data-email')
      if (checked) {
        modalText = `Are you sure you want to make ${email} a host`;
      } else {
        modalText = `Are you sure you want to remove ${email} as a host`;
      }
      modalTitle = `${checked ? "Host" : "UnHost"} user`;
      let userInfo = {
        selectedRecord: selected,
        selectedEmail: email,
        isHost: checked,
        modalTitle,
        modalText,
        modalFor: "host",
      };
      console.log("hostt this is user info=================", userInfo);
      this.setState(userInfo, () => {
        this.toggleModal();
      });
    }
    if (name == "block") {
      this.updateUser();
    }
    if (name == "host") {
      this.hostUser();
    }
    if (name == "delete") {
      this.deleteUser();
    }
    if (name == "tester") {
      console.log("tester condition entered!");
      this.testerUser();
    }
    if (name == "isTester") {
      // email = e.target.getAttribute('data-email')
      if (checked) {
        modalText = `Are you sure you want to make ${email} a tester.`;
      } else {
        modalText = `Are you sure you want to remove ${email} as a tester.`;
      }
      modalTitle = `${checked ? "Test" : "Delete Test"} user`;
      let userInfo = {
        selectedRecord: selected,
        selectedEmail: email,
        isTester: checked,
        modalTitle,
        modalText,
        modalFor: "tester",
      };
      console.log("hostt this is user info=================", userInfo);
      this.setState(userInfo, () => {
        // console.log(
        //   "ENTERED isTester and userinfo should be updated",
        //   userInfo
        // );
        console.log("E: ", e.target);
        // this.toggle({ ...e, name: "tester" });
        this.toggleModal();
      });
    }
    // this.setState((prevStates) => {
    //     return({
    //         modal : !prevStates.modal
    //     })
    // })
  };

  onChangeHandler = (name, value) => {
    let { search } = this.state;
    console.log("name, value", name, value);
    this.setState(
      {
        [name]: value,
      },
      () => {
        if (search !== "") {
          this.searchAllUsers();
        } else {
          this.fetchAllUsers();
        }
      }
    );
  };

  fetchAllUsers = () => {
    let { page, limit, active, subscribed, sortBy, join_date_sort, sortOrder } =
      this.state;
    let {
      filter_active,
      filter_subscribed,
      sort_join_date,
      sort_other,
      filter_tester,
      filter_blocked,
    } = this.props;
    //console.log('page, limit, active, subscribed', page, limit, active, subscribed)
    this.props.fetchUsers(
      page,
      limit,
      filter_active,
      filter_subscribed,
      filter_tester,
      filter_blocked,
      sort_other,
      sort_join_date,
      sortBy,
      sortOrder
    );
  };

  setGridOptions = (gridOpp) => {
    console.log("gridOpp", gridOpp);
    this.setState({ gridOptions: gridOpp });
  };

  exportCsv = () => {
    this.state.gridOptions.exportDataAsCsv();
  };

  testerUser = () => {
    let { selectedRecord, isTester, selectedEmail } = this.state;
    let userInfo = {
      user_id: selectedRecord,
      email: selectedEmail,
      isTester: isTester,
    };
    console.log("this is user info in testerUser=================", userInfo);
    this.props._makeUserATester(userInfo);
  };

  checkCondition = () => {
    console.log(this.modalTitle);
    if (this.state.isTester) {
      this.testerUser();
    } else {
      // this.toggleModal();
      this.deleteUser();
    }
  };
  render() {
    console.log("this.props allusers", this.props.users.users);
    console.log("props======================================", this.props);
    let {
      headsAg,
      users,
      isFetching,
      isBlocked,
      modal,
      modalText,
      modalTitle,
      modalFor,
      searchBy,
      sortOrder,
      active,
      subscribed,
      sortBy,
      join_date_sort,
      limit,
      gridOptions,
      id,
      email,
      isActive,
      isHost,
      isSearchable,
      isSubscribed,
      isTester,
      modalInfo,
    } = this.state;
    let { showFilters } = this.props;
    let norecordstext;
    isFetching
      ? (norecordstext = "fetching users...")
      : (norecordstext = "No Users Found!");
    // console.clear()
    console.log("state at render: ", this.state);

    return (
      <Container>
        <Row>
          <Col md={12}>
            <Card>
              <CardBody>
                <Row className="page-title bb-1 border-gray pb-2">
                  <Col md={{ offset: 0, size: 3 }} sm={{ offset: 0, size: 12 }}>
                    <h3 className="page-title text-left">Users</h3>
                  </Col>
                </Row>
                <Row
                  className="page-title bb-1 border-gray pb-2"
                  style={{ marginTop: "2%" }}
                >
                  <Col
                    md={{ offset: 0, size: 2 }}
                    sm={{ offset: 0, size: 6 }}
                    className="text-left"
                  >
                    {/* <Form className="text-left">
                                            <FormGroup> */}
                    <Button
                      type="button"
                      name="export"
                      onClick={(e) => {
                        showFilters();
                      }}
                      color={"primary"}
                    >
                      Filters
                    </Button>
                    {/* </FormGroup>
                                        </Form> */}
                  </Col>
                  <Col
                    md={{ offset: 0, size: 3 }}
                    sm={{ offset: 0, size: 12 }}
                    className="text-left"
                  >
                    <Form className="text-left">
                      <FormGroup>
                        <Input
                          type="select"
                          name="limit"
                          value={limit}
                          onChange={(e) => {
                            this.onChangeHandler(e.target.name, e.target.value);
                          }}
                        >
                          <option value="100">100</option>
                          <option value="250c">250</option>
                          <option value="500">500</option>
                          <option value="1000">1000</option>
                        </Input>
                      </FormGroup>
                    </Form>
                  </Col>
                  <Col
                    md={{ offset: 0, size: 3 }}
                    sm={{ offset: 0, size: 12 }}
                    className="text-left"
                  >
                    <Form className="text-left">
                      <FormGroup>
                        <Input
                          type="select"
                          name="sortBy"
                          value={sortBy}
                          onChange={(e) => {
                            this.onChangeHandler(e.target.name, e.target.value);
                          }}
                        >
                          <option value="">--sort by--</option>
                          <option value="email">Email</option>
                          <option value="displayname">DisplayName</option>
                          <option value="name">Name</option>
                          <option value="lastname">Lastname</option>
                        </Input>
                      </FormGroup>
                    </Form>
                  </Col>
                  <Col
                    md={{ offset: 0, size: 3 }}
                    sm={{ offset: 0, size: 12 }}
                    className="text-left"
                  >
                    <Form className="text-left">
                      <FormGroup>
                        <Input
                          type="select"
                          name="sortOrder"
                          value={sortOrder}
                          onChange={(e) => {
                            this.onChangeHandler(e.target.name, e.target.value);
                          }}
                        >
                          <option value="">--sort order--</option>
                          <option value="asc">Ascending</option>
                          <option value="desc">Descending</option>
                        </Input>
                      </FormGroup>
                    </Form>
                  </Col>
                  <Col
                    md={{ offset: 0, size: 3 }}
                    sm={{ offset: 0, size: 12 }}
                    className="text-left"
                  >
                    <Form className="text-left">
                      <FormGroup>
                        <Input
                          type="select"
                          name="searchBy"
                          value={searchBy}
                          onChange={(e) => {
                            this.onChangeHandler(e.target.name, e.target.value);
                          }}
                        >
                          <option value="">--search by--</option>
                          <option value="email">Email</option>
                          <option value="displayname">DisplayName</option>
                        </Input>
                      </FormGroup>
                    </Form>
                  </Col>
                  <Col
                    md={{ offset: 0, size: 3 }}
                    sm={{ offset: 0, size: 12 }}
                    className="text-left"
                  >
                    <Form className="text-left">
                      <FormGroup>
                        <Input
                          type="search"
                          name="search"
                          id="exampleEmail"
                          placeholder="Search user"
                          onChange={(e) => this.searchHandler(e)}
                        />
                      </FormGroup>
                    </Form>
                  </Col>
                </Row>
                <Row className={"mt-1"}>
                  <Col
                    md={12}
                    style={{ height: "90vh", width: "700px" }}
                    className="ag-theme-material"
                  >
                    <div style={{ height: "100%", width: "100%" }}>
                      <TableComponent
                        rowsData={users}
                        onCellClicked={this.onCellClicked}
                        headsAg={headsAg}
                        editRecord={this.editRecord}
                        rowSelectable={"multiple"}
                        rowsPerPage={10}
                        noRecordsText={norecordstext}
                        fetchingRecordsText={"Fetching Users..."}
                        setGridOptions={this.setGridOptions}
                        {...this.props}
                      />
                    </div>
                  </Col>
                  <Col md={12}>
                    <TablePagination
                      onPageChange={this.onPageChange}
                      {...this.state}
                    />
                  </Col>
                  <ModalAlert
                    toggle={this.toggleModal}
                    customClass={"test"}
                    cancelButtonText={"Cancel"}
                    cancelAction={(e) => this.toggleModal(e)}
                    // confirmAction={() => {
                    //   this.checkCondition();
                    // }}
                    confirmAction={this.toggle}
                    confirmButtonText={"Add"}
                    modal={modal}
                    func={"block"}
                    modalhtmlFor={modalFor}
                    isBlocked={isBlocked}
                    modalTitle={modalTitle}
                    modalText={modalText}
                  />
                  <UserInfoAlert
                    toggle={this.toggleModalInfo}
                    customClass={"test"}
                    modal={modalInfo}
                    toggleData={this.toggle}
                    id={id}
                    email={email}
                    isBlocked={isBlocked}
                    isHost={isHost}
                    isSubscribed={isSubscribed}
                    isActive={isActive}
                    isSearchable={isSearchable}
                    isTester={isTester}
                  />
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default UserFilterModal(AllUsers);
